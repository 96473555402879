import plot_3_3 from "../../assets/hud/packs/plots/filled/plot-filled-3x3.svg";

import { IPlotSizeDetails, PlotSizeType } from "../PlantPlot.interfaces";

const PlotSize3x3: IPlotSizeDetails = {
  name: PlotSizeType.size3x3,
  image: plot_3_3,
};

export default PlotSize3x3;
