import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import { POOL_QUERY } from "./subgraph";
import { IAddressToCropNameMap } from "./blockchain";

const handlePoolUpdates = (
  queryData: any,
  addressToCropNameMap: IAddressToCropNameMap,
  setPoolBalances: (poolData: any) => void
) => {
  const poolBalObj: any = {};

  queryData.pools.forEach(
    ({
      tokenAddress,
      goldReserveAmount,
      goldTrackedAmount,
      tokenReserveAmount,
      tokenTrackedAmount,
    }: any) => {
      poolBalObj[addressToCropNameMap[tokenAddress.toLowerCase()]] = {
        goldReserveAmount,
        goldTrackedAmount,
        tokenAddress,
        tokenReserveAmount,
        tokenTrackedAmount,
      };
    }
  );

  setPoolBalances(poolBalObj);
};

/**
 * A hook that pulls in the balance of crops in a specific wallet
 */
export const useCropPrices = (addressToCropNameMap: IAddressToCropNameMap) => {
  const [poolBalances, setPoolBalances] = useState<any>({});
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);

  const {
    loading: isLoadingPoolQuery,
    data: poolQueryData,
    refetch,
  } = useQuery(POOL_QUERY, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!isLoadingPoolQuery && !!poolQueryData && !!poolQueryData.pools) {
      handlePoolUpdates(poolQueryData, addressToCropNameMap, setPoolBalances);
    }
  }, [isLoadingPoolQuery, poolQueryData]);

  const refreshPoolData = async () => {
    setIsLoadingRefresh(true);
    const {
      loading: isLoadingRefetch,
      error: refetchPoolQueryError,
      data: refetchPoolQueryData,
    } = await refetch();

    if (!refetchPoolQueryError && !!refetchPoolQueryData && !isLoadingRefetch) {
      handlePoolUpdates(
        refetchPoolQueryData,
        addressToCropNameMap,
        setPoolBalances
      );
      setIsLoadingRefresh(false);
    }
  };

  return {
    isLoadingPoolDetails: isLoadingPoolQuery || isLoadingRefresh,
    poolBalances,
    refreshPoolData,
  };
};
