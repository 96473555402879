import iconOat from "../../../assets/crops/grain_field/oat.png";

import tileOatsEarly from "../../../assets/tiles/plants/grain_field/tile-oat-1.png";
import tileOatsFull from "../../../assets/tiles/plants/grain_field/tile-oat-2.png";
import tileOatsDead from "../../../assets/tiles/plants/grain_field/tile-oat-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  GrainFieldPlantNameType,
  GrainFieldPlantDisplayNameType,
  GrainFieldPlantBlockchainNameType,
} from "./GrainField.naming";

const PlantOat: IPlantType = {
  name: GrainFieldPlantNameType.oat,
  blockchainName: GrainFieldPlantBlockchainNameType.oat,
  displayName: GrainFieldPlantDisplayNameType.oat,
  plotType: PlantPlotType.grainField,
  icon: iconOat,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.orange,
    [PlantGrowthStage.sprout]: SproutType.grain,
    [PlantGrowthStage.early]: tileOatsEarly,
    [PlantGrowthStage.full]: tileOatsFull,
    [PlantGrowthStage.dead]: tileOatsDead,
  },
};

export default PlantOat;
