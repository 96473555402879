import {
  DEFAULT_MULTICHAIN_MODULE,
  MULTICHAIN_VALIDATION_MODULE_ADDRESSES_BY_VERSION,
  PaymasterMode,
  createBundler,
  createECDSAOwnershipValidationModule,
  createMultiChainValidationModule,
  createSmartAccountClient,
  getCustomChain,
} from "@biconomy/account";
import { MessageTypes, TypedMessage } from "@metamask/eth-sig-util";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";

import {
  BICONOMY_GOLD_BUNDLER_URL,
  BICONOMY_GOLD_PAYMASTER_API_KEY,
  GOLD_RPC_URL,
} from "../env";
import {
  IConnectedGoldAccount,
  IContractCall,
} from "../interfaces/contracts/contracts.interfaces";

// // // // // // // // // // // // // // // // // // // //
// SIGNING
// // // // // // // // // // // // // // // // // // // //
export interface IGetSignature {
  connectedGoldAccount: IConnectedGoldAccount;
  signTypedDataV4Payload: TypedMessage<MessageTypes>;
}

export const getSignature = async ({
  connectedGoldAccount,
  signTypedDataV4Payload,
}: IGetSignature) => {
  const signature = await connectedGoldAccount.signTypedData(
    signTypedDataV4Payload
  );

  return signature;
};

// // // // // // // // // // // // // // // // // // // //
// CREATING SMART ACCOUNTS
// // // // // // // // // // // // // // // // // // // //

interface IGetGoldSmartAccount {
  connectedGoldAccount: IConnectedGoldAccount;
}
export const getGoldSmartAccount = async ({
  connectedGoldAccount,
}: IGetGoldSmartAccount) => {
  // console.log("-=-=-=- GOLD_RPC_URL");
  // console.log(GOLD_RPC_URL);
  const customChain = await getCustomChain(
    "Gold",
    4653,
    GOLD_RPC_URL,
    "https://explorer.gold.dev"
  );

  const bundler = await createBundler({
    customChain,
    bundlerUrl: BICONOMY_GOLD_BUNDLER_URL,
  });

  const provider = await connectedGoldAccount.wallet.getEthersProvider();
  const signer = provider.getSigner();

  const multiChainModule = await createMultiChainValidationModule({
    signer: signer,
    moduleAddress: DEFAULT_MULTICHAIN_MODULE,
  });

  const smartAccount = await createSmartAccountClient({
    bundler,
    // bundlerUrl: BICONOMY_GOLD_BUNDLER_URL,
    rpcUrl: GOLD_RPC_URL,
    biconomyPaymasterApiKey: BICONOMY_GOLD_PAYMASTER_API_KEY,
    defaultValidationModule: multiChainModule,
    activeValidationModule: multiChainModule,
    customChain,
  });

  const smartAccountAddress = await smartAccount.getAddress();

  return { multiChainModule, smartAccount, smartAccountAddress };
};

// // // // // // // // // // // // // // // // // // // //
// HANDLING USER OPS
// // // // // // // // // // // // // // // // // // // //

export interface IUserOpHandler {
  // The addtional nonce used for transactions that are meant to be sent in quick succession
  additionalNonce: number;
  // Account currently being used
  connectedGoldAccount: IConnectedGoldAccount;
  // The contract and function to call
  contractCallListing: IContractCall[];
}

export const sendUserOp = async ({
  additionalNonce,
  connectedGoldAccount,
  contractCallListing,
}: IUserOpHandler): Promise<string> => {
  const transactionListing = [];

  const { smartAccount, multiChainModule } = await getGoldSmartAccount({
    connectedGoldAccount,
  });

  const smartAccountNonce = await smartAccount.getNonce();
  // const nonceKey = additionalNonce;
  const nonceKey = BigNumber(additionalNonce).plus(1).toNumber();
  const nonceOverride = BigNumber(smartAccountNonce.toString())
    .plus(additionalNonce)
    .toNumber();
  // console.log("---- smartAccountNonce");
  // console.log(smartAccountNonce);
  // console.log("---- nonceOverride");
  // console.log(nonceOverride);
  // console.log("---- nonceKey");
  // console.log(nonceKey);

  for (let contractCall of contractCallListing) {
    // Set up the order of params to be injected into the function
    const functionParams = contractCall.functionDetails.paramsOrder.map(
      (paramName) => contractCall.functionDetails.dataPayload.message[paramName]
    );

    // For interacting with the safe the account needs to sign the payload
    const signature = await getSignature({
      connectedGoldAccount,
      signTypedDataV4Payload: contractCall.functionDetails.dataPayload,
    });

    // Add the user's smart account signature on this transaction
    functionParams.push(signature);

    // Inject the parameters into the transaction to make a payload
    const txPayload = await (
      contractCall.targetContract.contract.interface as any
    ).encodeFunctionData(
      contractCall.functionDetails.name as any,
      functionParams as any
    );

    transactionListing.push({
      to: contractCall.targetContract.address,
      data: txPayload,
    });
  }

  // Build partial userOp for chain1
  let partialUserOp = await smartAccount.buildUserOp(transactionListing, {
    // // Assuming Sponsorship Paymaster is to be used otherwise leave as it's optional
    paymasterServiceData: {
      mode: PaymasterMode.SPONSORED,
    },
    simulationType: "validation",
    // stateOverrideSet: {

    // }
    nonceOptions: {
      nonceKey,
      nonceOverride,
    },
  });

  const signedOps = await multiChainModule.signUserOps([
    { userOp: partialUserOp, chainId: 4653 },
  ]);

  const userOpResponse = await smartAccount.sendSignedUserOp(
    signedOps[0] as any
  );

  // You can also just wait for transaction hash by waitForTxHash()
  const transactionDetails1 = await userOpResponse.wait();

  return "";
};
/*
// // // // // // // // // // // // // // // // // // // //
// HANDLE TRANSACTIONS DIRECTLY WITH A CONTRACT
// // // // // // // // // // // // // // // // // // // //

export const getSignedDataPayloadActivate = ({
  dataPayload,
  nonceBase,
  nonceAdditional,
  toAddress,
  verifyingContract,
}: {
  dataPayload: string;
  nonceBase: string;
  nonceAdditional: string;
  toAddress: string;
  verifyingContract: string;
}): TypedMessage<MessageTypes> => ({
  domain: {
    chainId: 4653,
    verifyingContract,
  },

  // Defining the message signing data content.
  message: {
    to: toAddress,
    tokenId,
  },

  // Defining the message types
  primaryType: "SafeTx",
  types: {
    EIP712Domain: [
      // { name: "name", type: "string" },
      // { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ],
    // Refer to PrimaryType
    SafeTx: [
      // Transaction info
      { name: "to", type: "address" },
      { name: "value", type: "uint256" },
      { name: "data", type: "bytes" },
      { name: "operation", type: "uint8" },

      // Payment info
      { name: "safeTxGas", type: "uint256" },
      { name: "baseGas", type: "uint256" },
      { name: "gasPrice", type: "uint256" },
      { name: "gasToken", type: "address" },
      { name: "refundReceiver", type: "address" },

      // Signature info
      { name: "nonce", type: "uint256" },
    ],
  },
});
*/

// // // // // // // // // // // // // // // // // // // //
// HANDLE TRANSACTIONS DIRECTLY WITH A CONTRACT
// // // // // // // // // // // // // // // // // // // //

export const getSignedDataPayloadSafe = ({
  dataPayload,
  nonceBase,
  nonceAdditional,
  toAddress,
  verifyingContract,
}: {
  dataPayload: string;
  nonceBase: string;
  nonceAdditional: number;
  toAddress: string;
  verifyingContract: string;
}): TypedMessage<MessageTypes> => {
  //   console.log("*-*-*-*-* nonceBase");
  //   console.log(nonceBase);
  //   console.log("*-*-*-*-* nonceAdditional");
  //   console.log(nonceAdditional);
  //   console.log(
  //     "*-*-*-*-* BigNumber(nonceBase).plus(nonceAdditional).toString()"
  //   );
  //   console.log(BigNumber(nonceBase).plus(nonceAdditional).toString());
  return {
    domain: {
      // Defining the chain aka Rinkeby goerli or Ethereum Mainnet
      chainId: 4653,
      // chainId: 8453,
      // Give a user friendly name to the specific contract you are signing for.
      // name: "ECDSA Ownership Registry Module",
      // If name isn't enough add verifying contract to make sure you are establishing contracts with the proper entity
      // verifyingContract: DEFAULT_MULTICHAIN_MODULE,
      verifyingContract,
      // Just let's you know the latest version. Definitely make sure the field name is correct.
      // version: MULTICHAIN_VALIDATION_MODULE_ADDRESSES_BY_VERSION.V1_0_0,
    },

    // Defining the message signing data content.
    message: {
      // Target address of SAFE transaction.
      to: toAddress,
      // SAFE transaction data payload.
      data: dataPayload,

      // Transaction info
      // Ether value of SAFE transaction.
      value: "0",
      // Operation type of SAFE transaction.
      operation: "0",
      // Gas that should be used for the SAFE transaction.
      safeTxGas: "0",

      // Payment info
      // Gas costs that are independent of the transaction execution(e.g. base transaction fee, signature check, payment of the refund)
      baseGas: "0",
      // Gas price that should be used for the payment calculation.
      gasPrice: "0",
      // Token address (or 0 if ETH) that is used for the payment.
      gasToken: "0x0000000000000000000000000000000000000000",
      // Address of receiver of gas payment (or 0 if tx.origin).
      refundReceiver: "0x0000000000000000000000000000000000000000",

      // Signature info
      nonce: BigNumber(nonceBase).plus(nonceAdditional).toString(),
    },

    // Defining the message types
    primaryType: "SafeTx",
    types: {
      EIP712Domain: [
        // { name: "name", type: "string" },
        // { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ],
      // Refer to PrimaryType
      SafeTx: [
        // Transaction info
        { name: "to", type: "address" },
        { name: "value", type: "uint256" },
        { name: "data", type: "bytes" },
        { name: "operation", type: "uint8" },

        // Payment info
        { name: "safeTxGas", type: "uint256" },
        { name: "baseGas", type: "uint256" },
        { name: "gasPrice", type: "uint256" },
        { name: "gasToken", type: "address" },
        { name: "refundReceiver", type: "address" },

        // Signature info
        { name: "nonce", type: "uint256" },
      ],
    },
  };
};
