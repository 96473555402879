import { BerryBushPlantNameType } from "./BerryBush.naming";

import PlantBlueberry from "./PlantBlueberry";
import PlantBlackberry from "./PlantBlackberry";
import PlantGojiBerry from "./PlantGojiBerry";
import PlantRaspberry from "./PlantRaspberry";
import PlantStrawberry from "./PlantStrawberry";

export const BerryBushMapping = {
  [BerryBushPlantNameType.blueberry]: PlantBlueberry,
  [BerryBushPlantNameType.blackberry]: PlantBlackberry,
  [BerryBushPlantNameType.gojiBerry]: PlantGojiBerry,
  [BerryBushPlantNameType.raspberry]: PlantRaspberry,
  [BerryBushPlantNameType.strawberry]: PlantStrawberry,
};
