export const possibleBlockchainErrors: any = {
  // MetaMask/blockchain errors
  "Nonce too": "Invalid nonce used in MetaMask.",
  "transaction underpriced": "The transaction failed, please try again.",

  // Account management errors
  "AcctReg:": "Game account error.",
  "nothing to activate": "No packs available to activate.",

  // Failed Transfers
  AccessControl: "There was an internal error.",
  "cant be gold": "You cannot buy/sell gold.",
  "swap failed": "The transaction failed, please try again.",
  pause: "The game is currently paused.",
  "PoolCore: transfer failed": "The transaction failed, please try again.",

  // Game engine specific
  "list lengths not equal": "Invalid purchase, please try again.",
  "price above max limit": "The price has increased, please try again.",
  "price below min limit": "The price has decreased, please try again.",
  "exceeds allowance": "You need to approve the app.",
  "exceeds balance": "You do not have enough gold/seeds.",
  "token amount is 0": "You must enter in more that 0.",

  // Pool specific
  "exceeds balance limit": "You are trying to buy more than for sale.",
  "exceeds token reserve": "No more of this crop for sale.",
  "exceeds token balance": "No more of this crop for sale.",
  "exceeds gold balance": "No more of this crop will be bought.",
  "exceeds gold reserve": "No more of this crop will be bought.",
  "gold out less than fee": "The gold from the sale doesn't cover the fee.",

  // Pack specific
  "owner of the pack": "You are not the owner of this pack.",
  NonexistentToken: "This pack does not exist.",

  // Plot specific
  "already staked": "This plot already has crops planted.",
  "element non-existent": "Invalid crop.",
  "harvest is dead": "The harvest is already dead.",
  "harvest not ready": "The harvest is not ready yet.",
  "harvest still alive": "The harvest is still alive on this plot.",
  "invalid plot id": "This isnt a valid plot.",
  "must own plot": "You must be the plot owner to farm on it.",
  "not enough to restake": "You do not have enough seeds to replant.",
  "nothing staked": "Nothing has been staked.",
  "nothing to harvest": "Nothing planted to harvest.",
  "nothing to clear": "Nothing planted to clear.",
};
