import iconCantaloupe from "../../../assets/crops/fruit_vine/cantaloupe.png";

import tileCantaloupeEarly from "../../../assets/tiles/plants/fruit_vine/tile-cantaloupe-1.png";
import tileCantaloupeFull from "../../../assets/tiles/plants/fruit_vine/tile-cantaloupe-2.png";
import tileCantaloupeDead from "../../../assets/tiles/plants/fruit_vine/tile-cantaloupe-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  FruitVinePlantNameType,
  FruitVinePlantDisplayNameType,
  FruitVinePlantBlockchainNameType,
} from "./FruitVine.naming";

const PlantCantaloupe: IPlantType = {
  name: FruitVinePlantNameType.cantaloupe,
  blockchainName: FruitVinePlantBlockchainNameType.cantaloupe,
  displayName: FruitVinePlantDisplayNameType.cantaloupe,
  plotType: PlantPlotType.fruitVine,
  icon: iconCantaloupe,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.tan,
    [PlantGrowthStage.sprout]: SproutType.vineGround,
    [PlantGrowthStage.early]: tileCantaloupeEarly,
    [PlantGrowthStage.full]: tileCantaloupeFull,
    [PlantGrowthStage.dead]: tileCantaloupeDead,
  },
};

export default PlantCantaloupe;
