import Button from "@mui/material/Button";

import "./CoreButton.style.css";

import backButton from "../assets/hud/buttons/button-flower_arrow.svg";
import { ButtonSize } from "./CoreButton";

export interface IBackButtonProp {
  onClickHandler: () => void;
  disabled?: boolean;
  size?: ButtonSize;
}

function BackButton({
  onClickHandler,
  disabled = false,
  size = ButtonSize.md,
}: IBackButtonProp) {
  return (
    <Button
      className={`BackButton ${size}`}
      onClick={onClickHandler}
      disabled={disabled}
    >
      <img className="CoreButton-image" src={backButton} alt="Button" />
    </Button>
  );
}

export default BackButton;
