import { ReactNode } from "react";
import Dialog from "@mui/material/Dialog";

import "../Modal.style.css";
import "./AlertModal.style.css";

import alertShell from "../../../assets/hud/alert/shell-alert.svg";
import alertShellLong from "../../../assets/hud/alert/shell-alert-long.svg";
import detailsShell from "../../../assets/hud/alert/shell-details.svg";
import detailsShellLong from "../../../assets/hud/alert/shell-details-long.svg";

import CoreButton, { ButtonColor } from "../../CoreButton";
import CloseButton from "../../CloseButton";

export enum AlertType {
  alert = "alert",
  details = "details",
}

export interface IAlertModalProp {
  open: boolean;
  disabled?: boolean;
  children: ReactNode;
  onConfirmHandler: () => void;

  confirmButtonText?: string;
  alternativeButtonText?: string;

  confirmButtonColor?: ButtonColor;
  long?: boolean;
  persistent?: boolean;
  showAlternativeButton?: boolean;
  type?: AlertType;

  onClickOutCloseHandler?: () => void;
  onAlternativeHandler?: () => void;
}

function AlertModal({
  open,
  disabled = false,
  children,

  confirmButtonText = "Ok",
  alternativeButtonText = "Cancel",

  confirmButtonColor = ButtonColor.green,
  long = false,
  persistent = false,
  showAlternativeButton = false,
  type = AlertType.details,

  onClickOutCloseHandler,
  onAlternativeHandler,
  onConfirmHandler,
}: IAlertModalProp) {
  const alertTypeMap = {
    [AlertType.alert]: long ? alertShellLong : alertShell,
    [AlertType.details]: long ? detailsShellLong : detailsShell,
  };

  const handleClickOutClose = () => {
    if (!!onClickOutCloseHandler) {
      onClickOutCloseHandler();
    }
  };

  return (
    <Dialog
      onClose={!persistent && !disabled ? handleClickOutClose : () => null}
      open={open}
      fullWidth={true}
      className={`AlertModal${long ? " AlertModal-long" : ""}`}
    >
      <img className="Modal-shell" src={alertTypeMap[type]} alt="Modal shell" />

      <div className="AlertShell-content_container">
        <div className="AlertShell-body_container">{children}</div>

        <div className="AlertShell-button_container">
          {showAlternativeButton && (
            <CoreButton
              disabled={disabled}
              onClickHandler={
                onAlternativeHandler ? onAlternativeHandler : () => {}
              }
              buttonText={alternativeButtonText}
              color={ButtonColor.orange}
            />
          )}
          <CoreButton
            disabled={disabled}
            onClickHandler={onConfirmHandler}
            buttonText={confirmButtonText}
            color={confirmButtonColor}
          />
        </div>
      </div>

      {!persistent && (
        <div className="AlertShell-close_button">
          <CloseButton
            disabled={disabled}
            onClickHandler={!disabled ? handleClickOutClose : () => null}
          />
        </div>
      )}
    </Dialog>
  );
}

export default AlertModal;
