import { BeanGardenPlantNameType } from "./BeanGarden.naming";

import PlantBlackBean from "./PlantBlackBean";
import PlantChickpea from "./PlantChickpea";
import PlantGreenPea from "./PlantGreenPea";
import PlantKidneyBean from "./PlantKidneyBean";
import PlantPintoBean from "./PlantPintoBean";

export const BeanGardenMapping = {
  [BeanGardenPlantNameType.blackBean]: PlantBlackBean,
  [BeanGardenPlantNameType.chickpea]: PlantChickpea,
  [BeanGardenPlantNameType.greenPea]: PlantGreenPea,
  [BeanGardenPlantNameType.kidneyBean]: PlantKidneyBean,
  [BeanGardenPlantNameType.pintoBean]: PlantPintoBean,
};
