import { IAccountRegistrationDetails } from "./accountRegistration";
import { getBlockchainErrorMessage, IBlockchainResponse } from "./blockchain";
import {
  IConnectedGoldAccount,
  IContractSet,
} from "./contracts/contracts.interfaces";
import { executeContractCall } from "./contracts";
import { PlotActions } from "./contracts/game/PlotActions";

export enum PlotCropAction {
  clearDead = "CLEAR_DEAD",
  clearGrowing = "CLEAR_GROWING",
  harvest = "HARVEST",
  plant = "PLANT",
}

// Handle the planting of crops
export const plantCrops = async (
  accountDetails: IAccountRegistrationDetails,
  connectedGoldAccount: IConnectedGoldAccount,
  contracts: IContractSet,
  transactionCountAhead: number,
  plotId: string,
  tokenId: string,
  cropName: string
): Promise<IBlockchainResponse> => {
  try {
    // Set up stake in plot
    const transactionDetails = await executeContractCall<PlotActions>({
      accountDetails,
      callDetails: [
        {
          contractDetails: contracts.core.plotActions,
          contractMethod: "actionStake",
          args: [plotId, tokenId],
        },
      ],
      connectedGoldAccount,
      paddingNonce: transactionCountAhead,
    });

    return {
      isSuccess: true,
      message: {
        text: `Successfully planted ${cropName} seeds!`,
        isMetaMask: false,
      },
      transactionDetails,
    };
  } catch (error: any) {
    return {
      isSuccess: false,
      message: getBlockchainErrorMessage(error),
      transactionDetails: null,
    };
  }
};

// Handle the clearing of the dead crops
export const clearDeadCrops = async (
  accountDetails: IAccountRegistrationDetails,
  connectedGoldAccount: IConnectedGoldAccount,
  contracts: IContractSet,
  transactionCountAhead: number,
  plotId: string,
  cropName: string
): Promise<IBlockchainResponse> => {
  try {
    // Set up clear dead
    const transactionDetails = await executeContractCall<PlotActions>({
      accountDetails,
      callDetails: [
        {
          contractDetails: contracts.core.plotActions,
          contractMethod: "actionClearDeadAndStake",
          args: [plotId, 0],
        },
      ],
      connectedGoldAccount,
      paddingNonce: transactionCountAhead,
    });

    return {
      isSuccess: true,
      message: {
        text: `Successfully cleared dead ${cropName} plants!`,
        isMetaMask: false,
      },
      transactionDetails,
    };
  } catch (error: any) {
    return {
      isSuccess: false,
      message: getBlockchainErrorMessage(error),
      transactionDetails: null,
    };
  }
};

// Handle the clearing growing crops
export const clearGrowingCrops = async (
  accountDetails: IAccountRegistrationDetails,
  connectedGoldAccount: IConnectedGoldAccount,
  contracts: IContractSet,
  transactionCountAhead: number,
  plotId: string,
  cropName: string
): Promise<IBlockchainResponse> => {
  try {
    // Set up clear growing
    const transactionDetails = await executeContractCall<PlotActions>({
      accountDetails,
      callDetails: [
        {
          contractDetails: contracts.core.plotActions,
          contractMethod: "actionClearAndStake",
          args: [plotId, 0],
        },
      ],
      connectedGoldAccount,
      paddingNonce: transactionCountAhead,
    });

    return {
      isSuccess: true,
      message: {
        text: `Successfully cleared ${cropName} plants!`,
        isMetaMask: false,
      },
      transactionDetails,
    };
  } catch (error: any) {
    return {
      isSuccess: false,
      message: getBlockchainErrorMessage(error),
      transactionDetails: null,
    };
  }
};

// Handle the clearing growing crops
export const harvestCrops = async (
  accountDetails: IAccountRegistrationDetails,
  connectedGoldAccount: IConnectedGoldAccount,
  contracts: IContractSet,
  transactionCountAhead: number,
  plotId: string,
  cropName: string
): Promise<IBlockchainResponse> => {
  try {
    // Set up harvest plot
    const transactionDetails = await executeContractCall<PlotActions>({
      accountDetails,
      callDetails: [
        {
          contractDetails: contracts.core.plotActions,
          contractMethod: "actionHarvestAndRestake",
          args: [plotId, 0],
        },
      ],
      connectedGoldAccount,
      paddingNonce: transactionCountAhead,
    });

    return {
      isSuccess: true,
      message: {
        text: `Successfully harvested ${cropName} seeds!`,
        isMetaMask: false,
      },
      transactionDetails,
    };
  } catch (error: any) {
    return {
      isSuccess: false,
      message: getBlockchainErrorMessage(error),
      transactionDetails: null,
    };
  }
};
