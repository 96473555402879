import { ConnectedWallet, usePrivy, useWallets } from "@privy-io/react-auth";
import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { Network, Alchemy } from "alchemy-sdk";
import BigNumber from "bignumber.js";
import { BrowserProvider, keccak256, toUtf8Bytes } from "ethers";
import { useEffect, useState } from "react";

import { ALCHEMY_KEY, GOLD_RPC_URL, IS_LIVE_RUN, SYSTEM_ADDRS } from "../env";
import {
  FertilizerType,
  IPackDetails,
  IPackRaw,
  PlotSpeedType,
  PlotYieldType,
  fertilizerTranslation,
  goldBracketsList,
  plantTypeTranslation,
  plotSizeList,
  plotTypeTranslation,
  speedTranslation,
  yieldTranslation,
} from "../utils/PlantPack.interfaces";
import {
  IPlantType,
  PlantPlotType,
  PlotSizeType,
} from "../utils/PlantPlot.interfaces";

import { IAccountRegistrationDetails } from "./accountRegistration";
import { possibleBlockchainErrors } from "./blockchainErrors";
import {
  executeContractCall,
  getContractSet,
  getCropContract,
  readContractCall,
} from "./contracts/";
import { GameToken } from "./contracts/game/GameToken";
import { Plot } from "./contracts/game/Plot";
import {
  IConnectedAccount,
  IConnectedBaseAccount,
  IConnectedGoldAccount,
  IContractSet,
  ITokenContract,
} from "./contracts/contracts.interfaces";

// // // // // // // // // // // // // // // // // // // // //
// // ACCOUNT REGISTRY
// // // // // // // // // // // // // // // // // // // // //

interface IAuthActions {
  loginBaseWallet: () => Promise<void>;
  loginGoldWallet: () => Promise<void>;
  logout: () => Promise<void>;
  logoutBaseWallet: () => Promise<void>;
}

interface IAuthState {
  authenticated: boolean;
  ready: boolean;
}

/**
 * A hook that pulls in the address and chain id
 *
 */
export const useConnectedAccount = (): {
  authActions: IAuthActions;
  authState: IAuthState;
  connectedAccount: IConnectedAccount;
} => {
  const { open: openWalletConnect } = useWeb3Modal();
  const { address: userWalletAddress, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const {
    ready: isPrivyLoaded,
    authenticated,
    login: loginPrivy,
    logout: logoutPrivy,
    signTypedData,
    user,
  } = usePrivy();
  const { disconnect } = useDisconnect();
  const { wallets } = useWallets();
  const [currentEmbeddedWalletAddress, setCurrentEmbeddedWalletAddress] =
    useState<string | null>(null);

  const [currentEmbeddedWallet, setCurrentEmbeddedWallet] =
    useState<ConnectedWallet | null>(null);

  const [isLoading, setIsLoading] = useState<boolean | null>(true);

  const setupGoldWallet = async (newEmbeddedWallet: ConnectedWallet) => {
    if (newEmbeddedWallet) {
      await newEmbeddedWallet.switchChain(4653);
      setCurrentEmbeddedWallet(newEmbeddedWallet);
    }
  };
  const foundEmbeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );
  const foundEmbeddedWalletAddress = foundEmbeddedWallet?.address || null;

  useEffect(() => {
    setIsLoading(true);
    if (
      !!foundEmbeddedWallet &&
      foundEmbeddedWalletAddress !== currentEmbeddedWalletAddress
    ) {
      setCurrentEmbeddedWalletAddress(foundEmbeddedWalletAddress);
      setupGoldWallet(foundEmbeddedWallet);
    } else {
      setCurrentEmbeddedWalletAddress(null);
    }
    setIsLoading(false);
  }, [foundEmbeddedWalletAddress]);

  const logout = async () => {
    await disconnect();
    await logoutPrivy();
  };

  const authActions = {
    loginBaseWallet: openWalletConnect,
    logoutBaseWallet: disconnect,
    loginGoldWallet: async () => {
      loginPrivy();
    },
    logout,
  };

  const authState = {
    authenticated,
    ready: isPrivyLoaded && !isLoading,
  };

  let connectedAccount: IConnectedAccount = {
    onchainId: null,
    base: null,
    gold: null,
    user: null,
  };

  if (!!isConnected && !!userWalletAddress && !!walletProvider) {
    connectedAccount.base = {
      address: userWalletAddress,
      wallet: new BrowserProvider(walletProvider),
    };
  }

  if (authState.ready && authenticated && !!currentEmbeddedWallet) {
    connectedAccount.onchainId = keccak256(currentEmbeddedWallet.address);
    connectedAccount.gold = {
      address: currentEmbeddedWallet.address,
      wallet: currentEmbeddedWallet,
      signTypedData,
    };
    connectedAccount.user = user;
  }

  return {
    authActions,
    authState,
    connectedAccount,
  };
};

export interface IBlockchainMessageResponse {
  text: string;
  isMetaMask: boolean;
}

export interface IBlockchainResponse {
  isSuccess: boolean;
  message: IBlockchainMessageResponse;
  transactionDetails: any;
}

export type IAddressToCropNameMap = Record<string, string>;

/**
 * Returns the contract
 */
export const getGoldContracts = () => {
  const contracts: IContractSet = {
    core: getContractSet(),
    crops: {},
  };

  const addressToCropNameMap: IAddressToCropNameMap = {};

  Object.keys(SYSTEM_ADDRS.crops).forEach((cropName: string) => {
    contracts.crops[cropName] = getCropContract(cropName);
    addressToCropNameMap[contracts.crops[cropName].address.toLowerCase()] =
      cropName;
  });

  return {
    message: "Success",
    addressToCropNameMap,
    contracts,
  };
};

// // // // // // // // // // // // // // // // // // // //
// CROP BALANCE
// // // // // // // // // // // // // // // // // // // //

// Pull details about the entire system
export const fetchWalletCropBalances = async (
  accountDetails: IAccountRegistrationDetails
) => {
  const { contracts } = getGoldContracts();

  const balances: any = {};
  const allowances: any = {};

  const requestListing: any[] = [];

  Object.keys(contracts.crops)
    .filter((cropName: string) => !!contracts.crops[cropName])
    .forEach((cropName: string) => {
      requestListing.push(
        readContractCall<GameToken>(contracts.crops[cropName], "balanceOf", [
          accountDetails.assetStorageSlots[0],
        ])
      );
      requestListing.push(
        fetchEngineAllowanceForContract(
          accountDetails,
          contracts.crops[cropName]
        )
      );
    });

  // Send one request for all the balances and allowances
  const balanceListing = await Promise.all(requestListing);

  Object.keys(contracts.crops)
    .filter((cropName: string) => !!contracts.crops[cropName])
    .forEach((cropName, index) => {
      // The first index is the balance
      balances[cropName] = BigNumber(balanceListing[index * 2]);

      // The second index is the allowance
      allowances[cropName] = BigNumber(balanceListing[index * 2 + 1]);
    });

  return { balances, allowances };
};

// // // // // // // // // // // // // // // // // // // //
// APPROVAL
// // // // // // // // // // // // // // // // // // // //

// Pull details about the entire system
export const initiateIncreaseAllowance = async (
  accountDetails: IAccountRegistrationDetails,
  connectedGoldAccount: IConnectedGoldAccount,
  contractDetails: ITokenContract
): Promise<IBlockchainResponse> => {
  try {
    const transactionDetails = await executeContractCall<GameToken>({
      accountDetails,
      callDetails: [
        {
          contractDetails,
          contractMethod: "approve",
          args: [
            SYSTEM_ADDRS.core.gameEngine,
            "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
          ],
        },
      ],
      connectedGoldAccount,
    });

    return {
      isSuccess: true,
      message: {
        text: "You have successfully granted permission.",
        isMetaMask: false,
      },
      transactionDetails,
    };
  } catch (error: any) {
    return {
      isSuccess: false,
      message: getBlockchainErrorMessage(error),
      transactionDetails: null,
    };
  }
};

// Pull details about the entire system
export const fetchEngineAllowanceForContract = async (
  accountDetails: IAccountRegistrationDetails,
  contractDetails: ITokenContract
) => {
  let totalAllowance: BigNumber = BigNumber(0);
  const owner = accountDetails.assetStorageSlots[0];
  const spender = SYSTEM_ADDRS.core.gameEngine;

  try {
    const rawResponse = await readContractCall<GameToken>(
      contractDetails,
      "allowance",
      [owner, spender]
    );

    // const rawResponse = IS_LIVE_RUN
    //   ? await _getAlchemyNetworkRPCd().alchemy.getTokenAllowance({
    //       contract: contract._address,
    //       owner,
    //       spender,
    //     })
    //   : await contract.contract.allowance(owner, spender);

    if (!!rawResponse) {
      totalAllowance = BigNumber(rawResponse.toString());
    }
  } catch (error: any) {
    console.log(`Error retrieving: allowance for ${contractDetails.address}`);
    console.log(error);
  }

  return totalAllowance;
};

// // // // // // // // // // // // // // // // // // // //
// WALLET BALANCE
// // // // // // // // // // // // // // // // // // // //

// Pull details about the entire system
export const fetchWalletGoldBalance = async (
  accountDetails: IAccountRegistrationDetails
) => {
  const { contracts } = getGoldContracts();

  let goldBalance: BigNumber | null = null;

  try {
    const goldResponse = await readContractCall<GameToken>(
      contracts.core.gold,
      "balanceOf",
      [accountDetails.assetStorageSlots[0]]
    );

    if (!!goldResponse) {
      goldBalance = BigNumber(goldResponse);
    }
  } catch (error: any) {
    console.log(`Error retrieving: gold balance`);
    console.log(error);
  }

  return goldBalance;
};

const _getAlchemy = () => {
  const settings = {
    apiKey: ALCHEMY_KEY,
    network: Network.BASE_MAINNET,
  };

  return new Alchemy(settings);
};

// // // // // // // // // // // // // // // // // // // //
// PACKS BALANCE
// // // // // // // // // // // // // // // // // // // //

// Fetch the packs in the user's wallet
export const getPacksInBaseWallet = async (
  connectedBaseAccount: IConnectedBaseAccount
): Promise<IBlockchainResponse> => {
  const { starterPack } = getContractSet();

  try {
    const alchemy = _getAlchemy();

    const packs = await alchemy.nft.getNftsForOwner(
      connectedBaseAccount.address,
      {
        contractAddresses: [starterPack.address],
      }
    );

    const cleanedUpPacks: IPackDetails[] = [];

    (packs.ownedNfts as any as IPackRaw[]).forEach((currentPack: IPackRaw) => {
      let baseSpeed: PlotSpeedType | null = null;
      let baseYield: PlotYieldType | null = null;
      let fertilizer: FertilizerType | null = null;
      let goldCount: number | null = null;
      let plantCount: number | null = null;
      let plantType: IPlantType | null = null;
      let plotType: PlantPlotType | null = null;
      let plotSizeType: PlotSizeType | null = null;
      let height: number | null = null;
      let width: number | null = null;
      let season: number = 1;

      currentPack.raw.metadata.attributes.forEach(({ trait_type, value }) => {
        if (trait_type === "Fertilizer" && value in fertilizerTranslation) {
          fertilizer = fertilizerTranslation[value];
        } else if (trait_type === "Growth Rate" && value in speedTranslation) {
          baseSpeed = speedTranslation[value];
        } else if (trait_type === "Plot Type" && value in plotTypeTranslation) {
          plotType = plotTypeTranslation[value];
        } else if (trait_type === "Plot Yield" && value in yieldTranslation) {
          baseYield = yieldTranslation[value];
        } else if (trait_type === "Seed Bag" && value in plantTypeTranslation) {
          plantType = plantTypeTranslation[value];
        } else if (trait_type === "Size Height") {
          height = parseInt(value);
          plotSizeType = plotSizeList[height - 1];
        } else if (trait_type === "Size Width") {
          width = parseInt(value);
        } else if (trait_type === "Season") {
          season = parseInt(value);
        }
      });

      if (!!height && !!width) {
        plantCount = height * width * 9;
        goldCount = goldBracketsList[season][width - 1];
      }

      if (
        !!baseSpeed &&
        !!baseYield &&
        !!goldCount &&
        !!plantCount &&
        // !!plantType &&
        !!plotSizeType &&
        !!plotType &&
        !!height &&
        !!width
      ) {
        cleanedUpPacks.push({
          packId: currentPack.tokenId,
          name: currentPack.name,
          image: currentPack.raw.metadata.image,
          fertilizer,
          goldCount,
          plotDetails: {
            baseSpeed,
            baseYield,
            plotCount: season === 1 ? 2 : 1,
            plotDimensions: {
              tileArea: 9,
              height,
              width,
            },
            plotSizeType,
            plotType,
          },
          plantDetails: plantType
            ? {
                plantType,
                plantCount,
              }
            : null,
        } as IPackDetails);
      }
    });

    return {
      isSuccess: true,
      message: {
        text: "Successfully retrieved packs.",
        isMetaMask: false,
      },
      transactionDetails: cleanedUpPacks,
    };
  } catch (error: any) {
    return {
      isSuccess: false,
      message: getBlockchainErrorMessage(error),
      transactionDetails: null,
    };
  }
};

// // // // // // // // // // // // // // // // // // // //
// PLOT BALANCE
// // // // // // // // // // // // // // // // // // // //

// Fetch the plots in the user's storage wallet
export const getPlotsInWallet = async (
  accountDetails: IAccountRegistrationDetails
): Promise<IBlockchainResponse> => {
  const { contracts } = getGoldContracts();

  try {
    let plotIds = await readContractCall<Plot>(
      contracts.core.plot,
      "walletOfOwner",
      [accountDetails.assetStorageSlots[0]]
    );

    return {
      isSuccess: true,
      message: {
        text: "Successfully retrieved plots.",
        isMetaMask: false,
      },
      transactionDetails: plotIds.map((currentPlotId: BigNumber) =>
        currentPlotId.toString()
      ),
    };
  } catch (error: any) {
    return {
      isSuccess: false,
      message: getBlockchainErrorMessage(error),
      transactionDetails: null,
    };
  }
};

// // // // // // // // // // // // // // // // // // // //
// ERROR MESSAGES
// // // // // // // // // // // // // // // // // // // //

const evaluatePossibleErrorMessage = (contractErrorMessage: string) => {
  let text = `Contract error - ${contractErrorMessage}`;

  Object.keys(possibleBlockchainErrors).forEach((errorMessageKey: string) => {
    if (text.indexOf(errorMessageKey) > -1 && errorMessageKey) {
      text = possibleBlockchainErrors[errorMessageKey];
    }
  });

  return text;
};

/**
 *
 * @param error blockchain error message formatting
 *
 */
export const getBlockchainErrorMessage = (
  error: any
): IBlockchainMessageResponse => {
  console.log(error);
  console.log(Object.keys(error));
  Object.keys(error).forEach((key) => {
    console.log(`$%$%$%$%$ error.${key}`);
    console.log(error[key]);
  });

  let text = "There was an error.";
  let isMetaMask = false;

  if (!!error && !!error.code) {
    if (error.code === 4001) {
      isMetaMask = true;
      text = "MetaMask request rejected.";
    } else if (error.code === -32002) {
      isMetaMask = true;
      text = "Please open MetaMask to connect.";
    } else if (error.code === -32603) {
      isMetaMask = false;
      const contractErrorMessage = error.message;
      text = evaluatePossibleErrorMessage(contractErrorMessage);
    } else if (!!error?.info?.error?.message) {
      isMetaMask = true;
      text = evaluatePossibleErrorMessage(error.info.error.message);
    }
  }

  return {
    text,
    isMetaMask,
  };
};
