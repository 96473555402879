import iconGreenPea from "../../../assets/crops/bean_garden/green_pea.png";

import tileGreenPeaEarly from "../../../assets/tiles/plants/bean_garden/tile-green_pea-1.png";
import tileGreenPeaFull from "../../../assets/tiles/plants/bean_garden/tile-green_pea-2.png";
import tileGreenPeaDead from "../../../assets/tiles/plants/bean_garden/tile-green_pea-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  BeanGardenPlantNameType,
  BeanGardenPlantDisplayNameType,
  BeanGardenPlantBlockchainNameType,
} from "./BeanGarden.naming";

const PlantGreenPea: IPlantType = {
  name: BeanGardenPlantNameType.greenPea,
  blockchainName: BeanGardenPlantBlockchainNameType.greenPea,
  displayName: BeanGardenPlantDisplayNameType.greenPea,
  plotType: PlantPlotType.beanGarden,
  icon: iconGreenPea,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.green,
    [PlantGrowthStage.sprout]: SproutType.leaf,
    [PlantGrowthStage.early]: tileGreenPeaEarly,
    [PlantGrowthStage.full]: tileGreenPeaFull,
    [PlantGrowthStage.dead]: tileGreenPeaDead,
  },
};

export default PlantGreenPea;
