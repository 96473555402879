import { getDimensions } from "./constants";

import { IPlantGrowthStages } from "./PlantGrowthStage";

export enum PlotSizeType {
  size1x1 = "1x1",
  size2x2 = "2x2",
  size3x3 = "3x3",
  size4x4 = "4x4",
  size5x5 = "5x5",
}

export enum PlantPlotType {
  beanGarden = "beanGarden",
  berryBush = "berryBush",
  flowerBed = "flowerBed",
  fruitVine = "fruitVine",
  grainField = "grainField",
}

export enum BlockchainPlantPlotType {
  BEAN_GARDEN = PlantPlotType.beanGarden,
  BERRY_BUSH = PlantPlotType.berryBush,
  FLOWER_BED = PlantPlotType.flowerBed,
  FRUIT_VINE = PlantPlotType.fruitVine,
  GRAIN_FIELD = PlantPlotType.grainField,
}

export const PlantPlotShortName = {
  [PlantPlotType.beanGarden]: "Beans",
  [PlantPlotType.berryBush]: "Berries",
  [PlantPlotType.flowerBed]: "Flowers",
  [PlantPlotType.fruitVine]: "Fruits",
  [PlantPlotType.grainField]: "Grains",
};

export const PlantPlotLongName = {
  [PlantPlotType.beanGarden]: "Bean Garden",
  [PlantPlotType.berryBush]: "Berry Bush",
  [PlantPlotType.flowerBed]: "Flower Bed",
  [PlantPlotType.fruitVine]: "Fruit Vine",
  [PlantPlotType.grainField]: "Grain Field",
};

export interface IPlantType {
  name: string;
  displayName: string;
  blockchainName: string;
  icon: string;
  plotType: PlantPlotType;
  growthStages: IPlantGrowthStages;
}

export interface IPlotSizeDetails {
  name: string;
  image: string;
}

export interface IPlotDimensions {
  height: number;
  width: number;
  tileArea: number;
}

export interface ITimeSet {
  started: number;
  ready: number;
  expired: number;
  breakdown: number[];
}

export interface IYieldConfig {
  maxYield: number;
  minYield: number;
}

export interface IPlotSet {
  plotId: string;
  countClears: number;
  countHarvests: number;
  countDeathClears: number;
  baseYield: number;
  baseSpeed: number;
  plotType: PlantPlotType;
  plantType: IPlantType | null;
  plotDimensions: IPlotDimensions;
  timeSet: ITimeSet;
  yieldConfig: IYieldConfig;
}

export const calculatePlotWidth = (plotDimensions: IPlotDimensions) =>
  plotDimensions.width * getDimensions().plotInteriorWidth +
  (plotDimensions.width - 1) * getDimensions().paddingInterior +
  getDimensions().paddingExterior * 2;
