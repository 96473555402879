import BigNumber from "bignumber.js";

import "./ShopModalSelectedCropSection.style.css";

import goldImage from "../../../assets/hud/items/item-gold.png";
import { IPlantType } from "../../../utils/PlantPlot.interfaces";
import {
  calculateBuyPrice,
  calculateSellPrice,
  getPoolObj,
  goldNumberWithCommas,
} from "../../../utils/helpers";

import CoreButton, { ButtonColor } from "../../CoreButton";
import ToggleAmountPanel from "../../ToggleAmountPanel";

export interface IShopModalSelectedCropSectionProp {
  cropWalletAllowances: any;
  poolBalances: any;
  isLoading: boolean;
  isLoadingAfterAction: boolean;
  isSelling: boolean;

  selectedCrop: IPlantType | null;
  selectedCropAmount: number;
  selectedCropMaxAmount: number;

  handleAddToCart: () => void;
  handleIncreaseCropAllowance: () => void;
  handleSellSeeds: () => void;
  setSelectedCropAmount: (selectedCropCount: number) => void;
}

function ShopModalSelectedCropSection({
  cropWalletAllowances,
  poolBalances,
  isLoading,
  isLoadingAfterAction,
  isSelling,
  selectedCrop,
  selectedCropAmount,
  selectedCropMaxAmount,
  handleAddToCart,
  handleIncreaseCropAllowance,
  handleSellSeeds,
  setSelectedCropAmount,
}: IShopModalSelectedCropSectionProp) {
  const calculatePrice = isSelling ? calculateSellPrice : calculateBuyPrice;

  let previewButtonText = "Add to Cart";
  let previewButtonColor = ButtonColor.green;
  let previewButtonHandler = handleAddToCart;

  if (isSelling) {
    previewButtonText = "Sell";
    previewButtonHandler = handleSellSeeds;

    if (!!selectedCrop) {
      const tokenTrackedAmount = BigNumber(
        poolBalances[selectedCrop.name].tokenTrackedAmount
      );
      const tokenReserveAmount = BigNumber(
        poolBalances[selectedCrop.name].tokenReserveAmount
      );
      const isMoreAllowanceNeeded =
        cropWalletAllowances[selectedCrop.name].lt(selectedCropAmount);
      const isRequestOverBalance =
        tokenTrackedAmount.lt(tokenReserveAmount) ||
        tokenTrackedAmount.minus(tokenReserveAmount).lt(selectedCropAmount);

      if (isLoadingAfterAction) {
        previewButtonText = "Loading...";
        previewButtonColor = ButtonColor.gray;
      } else if (isRequestOverBalance) {
        previewButtonText = "None for Sale";
        previewButtonColor = ButtonColor.red;
      } else if (isMoreAllowanceNeeded) {
        previewButtonText = "Continue";
        previewButtonColor = ButtonColor.orange;
        previewButtonHandler = handleIncreaseCropAllowance;
      }
    }
  }

  return (
    <div className="Shell-selected">
      <div className="Shell-selected_top">
        {!!selectedCrop ? (
          <div className="SelectedSection-title">
            {selectedCrop.displayName} Seeds
          </div>
        ) : (
          <div className="SelectedSection-placeholder_title">
            Select Crop to {isSelling ? "Sell" : "Buy"}
          </div>
        )}

        {!!selectedCrop && (
          <img
            className="SelectedSection-image"
            src={selectedCrop.icon}
            alt="Crop"
          />
        )}
        <div
          className={`Shell-price_container ${
            !selectedCrop && "Shell-price_container-faded"
          }`}
        >
          <img className="Shell-gold_image" src={goldImage} alt="Gold" />
          {!!selectedCrop && (
            <div className="SelectedSection-price_text">
              {!isLoading &&
                selectedCrop.name in poolBalances &&
                goldNumberWithCommas(
                  calculatePrice(
                    selectedCropAmount,
                    getPoolObj(poolBalances, selectedCrop).goldTrackedAmount,
                    getPoolObj(poolBalances, selectedCrop).tokenTrackedAmount
                  )
                )}
            </div>
          )}
        </div>
      </div>

      <div className="Shell-selected_bottom">
        <ToggleAmountPanel
          currentAmount={selectedCropAmount}
          maxAmount={selectedCropMaxAmount}
          isDisabled={!selectedCrop}
          isLoading={isLoading || isLoadingAfterAction}
          setCurrentAmount={setSelectedCropAmount}
        />

        <CoreButton
          disabled={
            !selectedCrop ||
            selectedCropAmount === 0 ||
            isLoading ||
            isLoadingAfterAction
          }
          color={previewButtonColor}
          onClickHandler={previewButtonHandler}
          buttonText={previewButtonText}
        />
      </div>
    </div>
  );
}

export default ShopModalSelectedCropSection;
