import { useEffect, useState } from "react";

import landBackground from "../assets/backgrounds/1.jpg";

import { IPlotDimensions, IPlotSet } from "../utils/PlantPlot.interfaces";
import { useElementSize, useWindowSize } from "../utils/helpers";

import PlotMapNoPlots from "./PlotMapNoPlots";
import PlotRowContainer from "./PlotRowContainer";
import ToggleAmountPanel from "./ToggleAmountPanel";
export interface IPlotMapProp {
  isLoading: boolean;
  showPlotData: boolean;

  cropDetails: any;
  plotsLoadingState: any;
  plotSet: IPlotSet[];
  rowSeparatedPlots: IPlotSet[][];

  rowLength: number;

  maxPlotWidth: number;
  maxPlotDimensions: IPlotDimensions;

  currentPlotPage: number;
  maxPlotPage: number;
  setCurrentPlotPage: (updatedCurrentPlotPage: number) => void;

  handleClickPlot: (plotSet: IPlotSet) => void;
}

function PlotMap({
  isLoading,
  showPlotData,

  cropDetails,
  plotsLoadingState,
  plotSet,
  rowSeparatedPlots,

  rowLength,

  maxPlotWidth,
  maxPlotDimensions,

  currentPlotPage,
  maxPlotPage,
  setCurrentPlotPage,

  handleClickPlot,
}: IPlotMapProp) {
  // const isLoading = true;
  const {
    ref: backgroundRef,
    width: backgroundWidth,
    height: backgroundHeight,
    forceUpdateSize: forceUpdateSizeBackground,
  } = useElementSize();
  const {
    ref: mapInnerRef,
    width: mapInnerWidth,
    height: mapInnerHeight,
    forceUpdateSize: forceUpdateSizeMapInner,
  } = useElementSize();
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const [currentPlotSetLength, setCurrentPlotSetLength] = useState(
    plotSet.length
  );

  const updateScreenLocation = () => {
    forceUpdateSizeMapInner();
    forceUpdateSizeBackground();
    setCurrentPlotSetLength(plotSet.length);
  };

  useEffect(() => {
    if (
      !isLoading &&
      plotSet.length > 0 &&
      plotSet.length !== currentPlotSetLength
    ) {
      updateScreenLocation();
    }

    const mapContainer = document.getElementById("map");

    if (mapContainer) {
      // Calculate the scroll position to center the inner container
      const scrollX = (mapContainer.scrollWidth - window.innerWidth) / 2;
      const scrollY = (mapContainer.scrollHeight - window.innerHeight) / 2;

      // Set the scroll position of the outer container
      mapContainer.scrollTo(scrollX, scrollY);
    }
  }, [isLoading]);

  const mapBackgroundStyle: any = {
    minWidth: `100%`,
    minHeight: `1400px`,
    left: 0,
    top: 0,
  };

  const leftPositioning =
    windowWidth / 2 > Math.floor(backgroundWidth / 2)
      ? windowWidth / 2
      : Math.floor(backgroundWidth / 2);

  const calculatedTop = (backgroundHeight - mapInnerHeight) / 2;

  const mapInteriorStyle: any = {
    // top: `${mapInnerHeight / 2}px`, // calc(1368px / 2 - 85px)
    top: `${calculatedTop >= -70 ? calculatedTop : -70}px`,
    left: `${leftPositioning}px`,
  };

  const renderPlotData = () => {
    if (isLoading || plotSet.length === 0) {
      return (
        <>
          <PlotMapNoPlots isLoading={isLoading} />
        </>
      );
    }

    return (
      <div className="map-outer-container">
        <div
          ref={mapInnerRef as any}
          className="map-inner-container"
          style={mapInteriorStyle}
        >
          <PlotRowContainer
            cropDetails={cropDetails}
            plotsLoadingState={plotsLoadingState}
            plotSet={plotSet}
            rowSeparatedPlots={rowSeparatedPlots}
            rowLength={rowLength}
            maxPlotWidth={maxPlotWidth}
            maxPlotDimensions={maxPlotDimensions}
            handleClickPlot={(plotIndex: number) =>
              handleClickPlot(plotSet[plotIndex])
            }
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {maxPlotPage > 1 && (
        <div className="ToggleAmountPanel-overall-container">
          <ToggleAmountPanel
            currentAmount={currentPlotPage}
            maxAmount={maxPlotPage}
            isDisabled={false}
            minAmount={1}
            isLoading={isLoading}
            setCurrentAmount={setCurrentPlotPage}
            inputPretext={`Farm`}
            inputPosttext={`/ ${maxPlotPage}`}
          />
        </div>
      )}

      <img
        ref={backgroundRef as any}
        className="Map-background"
        src={landBackground}
        style={mapBackgroundStyle}
        alt="Map"
      />

      {showPlotData && renderPlotData()}
    </>
  );
}

export default PlotMap;
