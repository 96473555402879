export interface IPlotMapNoPlotsProp {
  isLoading: boolean;
  textTitle?: string;
  textBody?: string;
}

function PlotMapNoPlots({
  isLoading,
  textTitle,
  textBody,
}: IPlotMapNoPlotsProp) {
  return (
    <div className="NoPlots-container">
      {isLoading ? (
        <div className="NoPlots-text">Loading...</div>
      ) : (
        <>
          <div className="NoPlots-text">
            {!!textTitle ? textTitle : "No Plots Found"}
          </div>
          <div className="NoPlots-subtext">
            {!!textBody
              ? textBody
              : "If you recently activated a pack and the plots have not arrived it may take a minute or 2 for it to pop up here. First try refreshing and if the plots still do not show up, send us a message on Farcaster or X/Twitter @Skyoneer and we can help."}
          </div>
        </>
      )}
    </div>
  );
}

export default PlotMapNoPlots;
