export const COLORS = {
  red: "#fb5846",
  darkBlue: "#22273f",
  darkBlueHover: "#303758",
  darkGray: "#8c8c8c",
  primaryBlue: "#5b8dd3",
  gray: "#dfe0e0",
  text: {
    dark: "#292929",
    green: "darkgreen",
  },
  orange: {
    base: "rgb(255, 162, 22)",
    opacity: "rgba(255, 162, 22, 0.85)",
  },
  brown: {
    base: "rgb(96, 60, 33)",
    opacity: "rgba(96, 60, 33, 0.85)",
  },
  lightGray: {
    base: "rgb(230,230,230)",
    opacity: "rgba(230,230,230, 0.85)",
  },
};

export const FONT_SIZE = {
  mini: "0.7rem",
  small: "0.875rem",
  medium: "1rem",
  large: "1.125rem",
};

export const PLOT_SIZE = {
  regular: 120,
  large: 240,
};

export const MINIMUM_PLOT_TRANSITION_SIZE = 4;

export const getDimensions = (basePlotSize = PLOT_SIZE.regular) => ({
  plotSizeHeight: basePlotSize,
  plotSizeWidth: basePlotSize,

  plotInteriorHeight: (basePlotSize * 5) / 12, // 50px
  plotInteriorWidth: basePlotSize * 0.6, // 72px
  paddingExterior: basePlotSize * 0.2, // 24px
  paddingInterior: 12,

  plotInteriorPadding: (basePlotSize * 4) / 15, // 32px

  verticalCenterLineAdjustment: 14,

  displaceTop: (9 / 40) * basePlotSize, // 27px
  displaceLeft: (13 / 40) * basePlotSize, // 39px
});
