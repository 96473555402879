import Button from "@mui/material/Button";
import { useEffect } from "react";

import iconGetStartedImage from "../assets/hud/buttons/button-get_started.svg";
import {
  getBlockchainErrorMessage,
  useConnectedAccount,
} from "../interfaces/blockchain";

import { useToolType } from "./tooltip/TooltipAlert";
import "./ButtonContainer.style.css";

function GetStartedButton() {
  const { invokeMetaMaskAlert } = useToolType();

  const { authActions, connectedAccount } = useConnectedAccount();
  const { onchainId } = connectedAccount;

  useEffect(() => {
    if (!!onchainId) {
      invokeMetaMaskAlert("Connected Wallet!");
    }
  }, [onchainId]);

  const handleClickGetStarted = async () => {
    await authActions.loginGoldWallet();
  };

  return (
    <div className="GetStartedButtonContainer-shell">
      <Button
        className="SidebarButton GetStartedButton"
        onClick={handleClickGetStarted}
        // disabled={disableLogin}
      >
        <img
          className="SidebarButton-background_image"
          src={iconGetStartedImage}
          alt="Shop"
        />
      </Button>
      <div className="GetStartedButton-text">Get Started</div>
    </div>
  );
}

export default GetStartedButton;
