import moment from "moment";

import "./PlotRowContainer.style.css";
import { MAX_TILES_IN_PLOT } from "../env";

import PlotTileSet from "./tiles/PlotTileSet";
import { getDimensions } from "../utils/constants";
import {
  calculatePlotWidth,
  IPlotDimensions,
  IPlotSet,
} from "../utils/PlantPlot.interfaces";

export interface IPlotRowContainerProp {
  cropDetails: any;
  plotsLoadingState: any;
  plotSet: IPlotSet[];
  rowSeparatedPlots: IPlotSet[][];

  rowLength: number;

  maxPlotWidth: number;
  maxPlotDimensions: IPlotDimensions;

  handleClickPlot: (plotIndex: number) => void;
}

function PlotRowContainer({
  cropDetails,
  plotsLoadingState,
  plotSet,
  rowSeparatedPlots,

  rowLength,

  maxPlotWidth,
  maxPlotDimensions,

  handleClickPlot,
}: IPlotRowContainerProp) {
  const plotRenderedWidth = plotSet.map((currentPlotSet: IPlotSet) =>
    calculatePlotWidth(currentPlotSet.plotDimensions)
  );

  const aggregateDisplacement: IPlotDimensions[] = [];

  // Go through the rows and calculate the growing delta
  rowSeparatedPlots.forEach((rowOfPlots, rowIndex) => {
    if (rowIndex === 0) {
      aggregateDisplacement.push({
        height: 0,
        width: 0,
        tileArea: 9,
      });
    } else {
      const currentStepDeltaHeight =
        -maxPlotWidth / 2 - getDimensions().displaceTop;

      const currentStepDeltaWidth =
        maxPlotWidth / 2 + getDimensions().displaceLeft;

      let rowShiftTop =
        aggregateDisplacement[rowIndex - 1].height + currentStepDeltaHeight;

      let rowShiftLeft =
        aggregateDisplacement[rowIndex - 1].width + currentStepDeltaWidth;

      aggregateDisplacement.push({
        height: rowShiftTop,
        width: rowShiftLeft,
        tileArea: 9,
      });
    }
  });

  const lastPlotIndex = rowSeparatedPlots.length - 1;
  const middleDisplacement = Math.floor(
    aggregateDisplacement[lastPlotIndex].height / 2
  );
  const overallSetHeight = `100% + ${middleDisplacement}px`;

  let overallShiftLeft = `0px`;

  if (rowSeparatedPlots.length === rowLength) {
    overallShiftLeft = `${getDimensions().plotSizeWidth * -1}px`;

    if (rowLength === 2) {
      overallShiftLeft = `${
        maxPlotWidth / 4 - getDimensions().plotSizeWidth
      }px`;
    }
  }

  return (
    <div
      className="PlotRowContainer"
      style={{
        left: `calc(${overallShiftLeft} + ${
          getDimensions().paddingExterior
        }px)`,
        // height: `calc(${overallSetHeight})`,
      }}
    >
      {rowSeparatedPlots.map((currentRowPlotSet, rowIndex) => {
        const adjustedRowIndex = rowIndex * rowLength;

        let rowDisplacementLeft = 0;
        let rowDisplacementTop = 0;

        let incompleteRowTopAdjustment = 0;
        let incompleteRowLeftAdjustment = 0;

        let justifyContent = "start";

        let computedTop = aggregateDisplacement[rowIndex].height + maxPlotWidth;

        // If the row is missing one element adjust the left except when overall plot arrangement is less 2 plots by 2 plots
        if (currentRowPlotSet.length === rowLength - 1) {
          justifyContent = "center";
          if (rowLength !== 2) {
          } else {
            incompleteRowTopAdjustment =
              -maxPlotWidth +
              (getDimensions().plotSizeHeight -
                getDimensions().plotInteriorHeight) +
              computedTop;
          }
        }

        let calculatedPlotRowWidth = maxPlotWidth * currentRowPlotSet.length;
        let topString = `${computedTop + incompleteRowTopAdjustment}px`;

        if (currentRowPlotSet.length === 1 || maxPlotDimensions.height === 1) {
          // make full widh if the number of elemtnts is 1
          calculatedPlotRowWidth = maxPlotWidth * rowLength;

          // For a plot set with only 1 element
          if (plotSet.length === 1 || maxPlotDimensions.height === 1) {
            topString = "50%";
          }
        }

        return (
          <div
            className="PlotRow"
            key={`plot-row-${rowIndex}`}
            style={{
              top: topString,
              left: `${
                aggregateDisplacement[rowIndex].width +
                incompleteRowLeftAdjustment
              }px`,
              width: `${calculatedPlotRowWidth}px`,
              justifyContent,
            }}
          >
            {currentRowPlotSet.map((currentPlot, specificPlotIndex) => {
              const { plotId, plantType, plotDimensions, plotType } =
                currentPlot;

              // Get the specific index
              const adjustedPlotIndex = adjustedRowIndex + specificPlotIndex;

              // Make the tile take up as much space as you get to the largest tile size
              const adjustmentWidthPadding =
                (maxPlotWidth - plotRenderedWidth[adjustedPlotIndex]) / 2;

              const adjustTop =
                (maxPlotDimensions.height - plotDimensions.height) *
                (getDimensions().plotInteriorHeight -
                  getDimensions().plotInteriorPadding);

              // Calculate the overall shift
              const currentPlotDisplacementTop =
                maxPlotWidth / -2 - getDimensions().displaceTop;
              const currentPlotDisplacementLeft =
                maxPlotWidth / -2 - getDimensions().displaceLeft;

              // Calculated delta for the tile to move
              const elementShiftTop =
                rowDisplacementTop +
                (specificPlotIndex ? currentPlotDisplacementTop : 0);

              const elementShiftLeft =
                rowDisplacementLeft +
                (specificPlotIndex ? currentPlotDisplacementLeft : 0);

              // Update the displacements for the next tile set
              rowDisplacementTop =
                elementShiftTop +
                (getDimensions().displaceTop +
                  getDimensions().plotInteriorHeight) *
                  specificPlotIndex;
              rowDisplacementLeft =
                elementShiftLeft +
                (getDimensions().displaceLeft +
                  getDimensions().plotInteriorWidth) *
                  specificPlotIndex;

              // Movement of the tile within the padding
              const tileSpecificAdjustmentTop = specificPlotIndex
                ? (rowLength * getDimensions().plotInteriorHeight) / 2
                : 0;
              const tileSpecificAdjustmentLeft = specificPlotIndex
                ? (rowLength * getDimensions().plotInteriorWidth) / 2
                : 0;
              const readyTimestamp: number =
                (currentPlot.timeSet.started + currentPlot.timeSet.ready) *
                1000;

              const expiredTimeStamp =
                (currentPlot.timeSet.started + currentPlot.timeSet.expired) *
                1000;

              const isReadyTimer = moment.now() < readyTimestamp;
              const isExpiredTimer = moment.now() < expiredTimeStamp;

              return (
                <PlotTileSet
                  key={`plot-${adjustedPlotIndex}`}
                  currentPlot={currentPlot}
                  readyTimestamp={
                    isExpiredTimer
                      ? new Date(
                          isReadyTimer ? readyTimestamp : expiredTimeStamp
                        )
                      : null
                  }
                  loadingPlotAction={
                    plotId in plotsLoadingState.plotIdsUsed
                      ? plotsLoadingState.plotIdsUsed[plotId]
                      : null
                  }
                  plantType={plantType}
                  plotIndex={adjustedPlotIndex}
                  plotType={plotType}
                  plotDimensions={plotDimensions}
                  maxPlotDimensions={maxPlotDimensions}
                  zIndexPadding={rowIndex * MAX_TILES_IN_PLOT}
                  adjustmentLeft={
                    plotSet.length > 2
                      ? tileSpecificAdjustmentLeft + elementShiftLeft
                      : 0
                  }
                  adjustmentTop={
                    plotSet.length > 2
                      ? tileSpecificAdjustmentTop + elementShiftTop - adjustTop
                      : -adjustTop
                  }
                  adjustmentPadding={adjustmentWidthPadding}
                  heightArray={[...Array(plotDimensions.height)]}
                  widthArray={[...Array(plotDimensions.width)]}
                  handleClickPlot={handleClickPlot}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default PlotRowContainer;
