import { gql } from "@apollo/client";

export const BASE_QUERY = gql`
  query ($plotIds: [String]!) {
    yieldConfigs(first: 30, subgraphError: allow, where: { isActive: true }) {
      maxYield
      minYield
      width
    }
    plots(first: 1000, subgraphError: allow, where: { id_in: $plotIds }) {
      id
      width
      height
      tileArea
      baseSpeed
      baseYield
      countClears
      countHarvests
      countDeathClears
      baseYield
      yieldConfig {
        minYield
        maxYield
      }
      stakedCrop {
        id
        elementName
        addressMapping {
          addressName
          tokenAddress
        }
      }
      plotType {
        id
        name
      }
      amountStaked
      timeStartStaked
      timeReadyDelta
      timeExpiredDelta
    }
    gameStates(first: 10, subgraphError: allow) {
      id
      isPaused
    }
    crops(first: 30, subgraphError: allow) {
      id
      elementName
      plotType {
        id
        name
      }
      addressMapping {
        addressName
        tokenAddress
      }
      growthTimeTable {
        id
        deltaNothingToStart
        deltaStartToEarly
        deltaEarlyToMature
        deltaMatureToExpire
      }
    }
  }
`;

export const POOL_QUERY = gql`
  query {
    pools(first: 30, subgraphError: allow) {
      goldTrackedAmount
      tokenTrackedAmount
      goldReserveAmount
      tokenReserveAmount
      tokenAddress
    }
  }
`;
