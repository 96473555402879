import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import moment from "moment";

import { ALCHEMY_KEY, PACK_METADATA_URL } from "../env";
import { IPackDetails } from "../utils/PlantPack.interfaces";
import {
  BlockchainPlantPlotType,
  IPlantType,
  IPlotSet,
} from "../utils/PlantPlot.interfaces";
import { PlantMapping } from "../utils/PlantPlot.mapping";

import { IAccountRegistrationDetails } from "./accountRegistration";
import {
  IAddressToCropNameMap,
  fetchEngineAllowanceForContract,
  fetchWalletGoldBalance,
  getGoldContracts,
  getPacksInBaseWallet,
  getPlotsInWallet,
  useConnectedAccount,
} from "./blockchain";
import { ICropDetailSet } from "./crops";
import { PlotCropAction } from "./plot";
import { BASE_QUERY } from "./subgraph";

/**
 * A hook that pulls in the details about the packs that a wallet owns
 *
 *
 */
export const usePacksOwnedStatus = () => {
  const { connectedAccount } = useConnectedAccount();
  const [currentBaseAddress, setCurrentBaseAddress] = useState<string | null>(
    null
  );
  const [isLoadingPackSet, setIsLoadingPackSet] = useState(true);
  const [packsListing, setPacksListing] = useState<IPackDetails[]>([]);

  const { base } = connectedAccount;
  let baseAddress = base?.address || null;

  const refreshUserOwnedPacks = async (isForce = true) => {
    setIsLoadingPackSet(true);
    if (!!base && (isForce || baseAddress !== currentBaseAddress)) {
      const packIdsInWallet = await getPacksInBaseWallet(base);

      if (packIdsInWallet.isSuccess) {
        setPacksListing(packIdsInWallet.transactionDetails);
      } else {
        setPacksListing([]);
      }

      setCurrentBaseAddress(baseAddress);
    } else {
      setCurrentBaseAddress(null);
      setPacksListing([]);
    }

    setIsLoadingPackSet(false);
  };

  useEffect(() => {
    refreshUserOwnedPacks(false);
  }, [baseAddress]);

  return {
    isLoadingPackSet,
    packsListing,
    refreshUserOwnedPacks,
  };
};

/**
 * A hook that pulls in the details about the plots that a wallet owns
 *
 *
 */
export const usePlotsOwnedStatus = (
  accountDetails: IAccountRegistrationDetails
) => {
  const { connectedAccount } = useConnectedAccount();
  const [currentOnchainId, setCurrentOnchainId] = useState<string | null>(null);
  const [isLoadingPlotSet, setIsLoadingPlotSet] = useState(true);
  const [plotSet, setPlotSet] = useState<string[]>([]);
  const { onchainId } = connectedAccount;

  const refreshUserOwnedPlots = async (isForce = true) => {
    if (!!onchainId && (isForce || onchainId !== currentOnchainId)) {
      setIsLoadingPlotSet(true);
      const plotIdsInWallet = await getPlotsInWallet(accountDetails);

      if (plotIdsInWallet.isSuccess) {
        setPlotSet(plotIdsInWallet.transactionDetails);
      } else {
        setPlotSet([]);
      }

      setCurrentOnchainId(onchainId);
    } else {
      setCurrentOnchainId(null);
      setPlotSet([]);
    }

    setIsLoadingPlotSet(false);
  };

  useEffect(() => {
    refreshUserOwnedPlots(false);
  }, [onchainId]);

  return {
    isLoadingPlotSet,
    plotSet,
    refreshUserOwnedPlots,
  };
};

/**
 * A hook that pulls in the balance of gold and allowance in a specific wallet
 *
 */
export const useWalletGoldBalance = (
  accountDetails: IAccountRegistrationDetails
) => {
  const { connectedAccount } = useConnectedAccount();
  const [currentOnchainId, setCurrentOnchainId] = useState<string | null>(null);
  const [goldAllowance, setGoldAllowance] = useState<BigNumber | null>(null);
  const [isLoadingWalletGoldBalance, setIsLoadingWalletGoldBalances] =
    useState(true);
  const [walletGoldBalance, setWalletGoldBalances] = useState<BigNumber | null>(
    null
  );
  const { onchainId } = connectedAccount;

  const { contracts } = getGoldContracts();

  const refreshWalletGoldState = async (isForce = true) => {
    if (!!onchainId && (isForce || onchainId !== currentOnchainId)) {
      setIsLoadingWalletGoldBalances(true);

      const [currentGoldAllowance, currentGoldBalance] = await Promise.all([
        // Get the allowance to move gold on behalf of this user
        fetchEngineAllowanceForContract(accountDetails, contracts.core.gold),
        fetchWalletGoldBalance(accountDetails),
      ]);

      setCurrentOnchainId(onchainId);
      setGoldAllowance(currentGoldAllowance);
      setWalletGoldBalances(currentGoldBalance);
    } else {
      setCurrentOnchainId(null);
      setGoldAllowance(null);
      setWalletGoldBalances(null);
    }

    setIsLoadingWalletGoldBalances(false);
  };

  useEffect(() => {
    refreshWalletGoldState(false);
  }, [onchainId]);

  return {
    isLoadingWalletGoldBalance,
    goldAllowance,
    walletGoldBalance,
    refreshWalletGoldState,
  };
};
const handlePlotDetails = (
  baseQueryData: any,
  addressToCropNameMap: IAddressToCropNameMap,
  setCropDetails: (cropData: any) => void,
  setPlotDetails: (plotData: any) => void
) => {
  // Handle crops
  const cropDetailsMap: any = {};
  baseQueryData.crops.forEach(
    ({ addressMapping, growthTimeTable, id }: any) => {
      const plantName =
        addressToCropNameMap[addressMapping.tokenAddress.toLowerCase()];
      cropDetailsMap[plantName] = {
        plantName,
        elementId: growthTimeTable.id,
        growthTable: [
          parseInt(growthTimeTable.deltaNothingToStart, 10),
          parseInt(growthTimeTable.deltaStartToEarly, 10),
          parseInt(growthTimeTable.deltaEarlyToMature, 10),
          parseInt(growthTimeTable.deltaMatureToExpire, 10),
        ],
      } as ICropDetailSet;
    }
  );
  setCropDetails({ ...cropDetailsMap });

  // Handle plots
  const plotDetailsMap: any = {};
  baseQueryData.plots.forEach((currentPlot: any) => {
    let plantType: IPlantType | null = null;
    let plantName: string | null = null;

    if (
      !!currentPlot.stakedCrop &&
      !!currentPlot.stakedCrop.addressMapping.tokenAddress &&
      currentPlot.stakedCrop.addressMapping.tokenAddress in
        addressToCropNameMap &&
      !!addressToCropNameMap[
        currentPlot.stakedCrop.addressMapping.tokenAddress
      ] &&
      addressToCropNameMap[
        currentPlot.stakedCrop.addressMapping.tokenAddress
      ] in PlantMapping
    ) {
      plantName = addressToCropNameMap[
        currentPlot.stakedCrop.addressMapping.tokenAddress
      ] as string;
      plantType =
        plantName in PlantMapping ? (PlantMapping as any)[plantName] : null;
    }

    plotDetailsMap[currentPlot.id] = {
      plantType,
      plotId: currentPlot.id,
      countClears: parseInt(currentPlot.countClears, 10),
      countHarvests: parseInt(currentPlot.countHarvests, 10),
      countDeathClears: parseInt(currentPlot.countDeathClears, 10),
      plotType: (BlockchainPlantPlotType as any)[currentPlot.plotType.name],
      baseYield: parseInt(currentPlot.baseYield, 10),
      baseSpeed: parseInt(currentPlot.baseSpeed, 10),
      plotDimensions: {
        height: parseInt(currentPlot.height, 10),
        width: parseInt(currentPlot.width, 10),
        tileArea: parseInt(currentPlot.tileArea, 10),
      },
      timeSet: {
        started: parseInt(currentPlot.timeStartStaked, 10),
        ready: parseInt(currentPlot.timeReadyDelta, 10),
        expired: parseInt(currentPlot.timeExpiredDelta, 10),
        breakdown: !!plantName ? cropDetailsMap[plantName].growthTable : [],
      },
      yieldConfig: {
        maxYield: parseInt(currentPlot.yieldConfig.maxYield, 10),
        minYield: parseInt(currentPlot.yieldConfig.minYield, 10),
      },
    } as IPlotSet;
  });
  setPlotDetails({ ...plotDetailsMap });
};

export const usePlotDetails = (
  addressToCropNameMap: IAddressToCropNameMap,
  plotIds: string[] | null
) => {
  const [cropDetails, setCropDetails] = useState<any>({});
  const [plotDetails, setPlotDetails] = useState<any>({});
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
  const [currentPlotIds, setCurrentPlotIds] = useState<string>("");

  const compressedPlotIds = !!plotIds ? plotIds.join(",") : "";

  const {
    loading: isLoadingBaseQuery,
    data: baseQueryData,
    refetch,
  } = useQuery(BASE_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      plotIds: !!plotIds ? plotIds : [],
    },
  });

  // Need this part to ensure that the user's plot details are refreshed
  useEffect(() => {
    if (currentPlotIds !== compressedPlotIds) {
      setCurrentPlotIds(compressedPlotIds);
      refreshPlotData();
    }
  }, [compressedPlotIds]);

  useEffect(() => {
    if (!isLoadingBaseQuery && !!baseQueryData && !!baseQueryData.crops) {
      handlePlotDetails(
        baseQueryData,
        addressToCropNameMap,
        setCropDetails,
        setPlotDetails
      );
    }
  }, [isLoadingBaseQuery, baseQueryData]);

  const refreshPlotData = async () => {
    setIsLoadingRefresh(true);
    const {
      loading: isLoadingRefetch,
      error: refetchPoolQueryError,
      data: refetchPoolQueryData,
    } = await refetch();

    if (!refetchPoolQueryError && !!refetchPoolQueryData && !isLoadingRefetch) {
      handlePlotDetails(
        refetchPoolQueryData,
        addressToCropNameMap,
        setCropDetails,
        setPlotDetails
      );
      setIsLoadingRefresh(false);
    }
  };

  const forcedUpdatePlotData = (
    actionType: PlotCropAction,
    currentPlot: IPlotSet,
    selectedCrop: ICropDetailSet | null
  ) => {
    const currentSpecificPlotDetails = {
      ...plotDetails[currentPlot.plotId],
    } as IPlotSet;

    if (!!selectedCrop) {
      // Add the planted crop's plant name
      currentSpecificPlotDetails.plantType =
        selectedCrop.plantName in PlantMapping
          ? (PlantMapping as any)[selectedCrop.plantName]
          : null;

      // Convert the current time from milliseconds to seconds
      const currentTime = moment.now() / 1000;

      // Pull the times of the plot
      const [nothingToStart, startToEarly, earlyToMature, matureToExpire] =
        selectedCrop.growthTable;

      const readyDelta = nothingToStart + startToEarly + earlyToMature;

      // Update the times of this plot
      currentSpecificPlotDetails.timeSet = {
        started: currentTime,
        ready: readyDelta,
        expired: readyDelta + matureToExpire,
        breakdown: selectedCrop.growthTable,
      };
    } else {
      // Clear the plant name
      currentSpecificPlotDetails.plantType = null;

      // zero out the times of this plot
      currentSpecificPlotDetails.timeSet = {
        started: 0,
        ready: 0,
        expired: 0,
        breakdown: [],
      };

      // Update the count
      if (actionType === PlotCropAction.clearDead) {
        currentSpecificPlotDetails.countDeathClears += 1;
      } else if (actionType === PlotCropAction.clearGrowing) {
        currentSpecificPlotDetails.countClears += 1;
      } else if (actionType === PlotCropAction.harvest) {
        currentSpecificPlotDetails.countHarvests += 1;
      }
    }

    // Update the map of plot details
    setPlotDetails({
      ...plotDetails,
      [currentPlot.plotId]: currentSpecificPlotDetails,
    });
  };

  return {
    isLoadingPlotDetails: isLoadingBaseQuery || isLoadingRefresh,
    cropDetails,
    plotDetails,
    forcedUpdatePlotData,
  };
};
