import { IAccountRegistrationDetails } from "./accountRegistration";
import { getBlockchainErrorMessage, IBlockchainResponse } from "./blockchain";
import {
  IConnectedGoldAccount,
  IContractSet,
} from "./contracts/contracts.interfaces";
import { executeContractCall } from "./contracts";
import { GameEngine } from "./contracts/core/GameEngine";

// Handle the purchase of a group of crops
export const processSetPurchase = async (
  accountDetails: IAccountRegistrationDetails,
  connectedGoldAccount: IConnectedGoldAccount,
  contracts: IContractSet,
  listOfElementIds: string[],
  listOfTokenAmounts: string[],
  listOfPriceMaxLimits: string[]
): Promise<IBlockchainResponse> => {
  try {
    const args = [listOfElementIds, listOfTokenAmounts, listOfPriceMaxLimits];

    // Set up the purchase
    const transactionDetails = await executeContractCall<GameEngine>({
      accountDetails,
      callDetails: [
        {
          contractDetails: contracts.core.gameEngine,
          contractMethod: "buySetOfTokens",
          args,
        },
      ],
      connectedGoldAccount,
    });

    return {
      isSuccess: true,
      message: {
        text: "Successfully bought seeds!",
        isMetaMask: false,
      },
      transactionDetails,
    };
  } catch (error: any) {
    return {
      isSuccess: false,
      message: getBlockchainErrorMessage(error),
      transactionDetails: null,
    };
  }
};

// Handle the sale of a group of crops
export const processSell = async (
  accountDetails: IAccountRegistrationDetails,
  connectedGoldAccount: IConnectedGoldAccount,
  contracts: IContractSet,
  tokenId: string,
  tokenAmount: string,
  sellPriceMin: string
): Promise<IBlockchainResponse> => {
  try {
    // Set up the purchase
    const transactionDetails = await executeContractCall<GameEngine>({
      accountDetails,
      callDetails: [
        {
          contractDetails: contracts.core.gameEngine,
          contractMethod: "sellToken",
          args: [tokenId, tokenAmount, sellPriceMin],
        },
      ],
      connectedGoldAccount,
    });

    return {
      isSuccess: true,
      message: {
        text: "Successfully sold seeds!",
        isMetaMask: false,
      },
      transactionDetails,
    };
  } catch (error: any) {
    return {
      isSuccess: false,
      message: getBlockchainErrorMessage(error),
      transactionDetails: null,
    };
  }
};
