import { useReducer } from "react";
import { PlotCropAction } from "../interfaces/plot";

export enum PlotsLoadingActionType {
  ADD_PLOT = "ADD_PLOT",
  REMOVE_PLOT = "REMOVE_PLOT",
}

export interface IPlotsLoadingState {
  plotIdsUsed: Record<string, PlotCropAction>;
  cropAmountsAllocated: Record<string, number>;
}

export interface IPlotsLoadingAction {
  type: PlotsLoadingActionType;
  plotId: string;
  plotAction?: PlotCropAction;
  cropBlockchainName?: string;
  cropAmountDelta?: number;
}

const initialPlotsLoadingState: any = {
  // Mapping of plots and the state that is causing them to be loading
  plotIdsUsed: {},
  // Mapping of a crops amount that is set aside when used in this transaction
  cropAmountsAllocated: {},
};

const plotsLoadingReducer = (
  state: IPlotsLoadingState,
  action: IPlotsLoadingAction
) => {
  switch (action.type) {
    case PlotsLoadingActionType.ADD_PLOT: {
      const newPlotIdsUsed: any = { ...state.plotIdsUsed };
      newPlotIdsUsed[action.plotId] = action.plotAction;

      const newCropAmountsAllocated: any = { ...state.cropAmountsAllocated };
      if (action.cropBlockchainName) {
        if (action.cropBlockchainName in newCropAmountsAllocated) {
          newCropAmountsAllocated[action.cropBlockchainName] +=
            action.cropAmountDelta;
        } else {
          newCropAmountsAllocated[action.cropBlockchainName] =
            action.cropAmountDelta;
        }
      }

      return {
        cropAmountsAllocated: { ...newCropAmountsAllocated },
        plotIdsUsed: { ...newPlotIdsUsed },
      };
    }
    case PlotsLoadingActionType.REMOVE_PLOT: {
      const newPlotIdsUsed: any = { ...state.plotIdsUsed };

      delete newPlotIdsUsed[action.plotId];

      return {
        cropAmountsAllocated: { ...state.cropAmountsAllocated },
        plotIdsUsed: { ...newPlotIdsUsed },
      };
    }
    default:
      return state;
  }
};

export const usePlotsLoadingReducer = () => {
  const [state, dispatch] = useReducer(
    plotsLoadingReducer,
    initialPlotsLoadingState
  );

  return { plotsLoadingState: state, dispatchPlotsLoadingAction: dispatch };
};
