import Button from "@mui/material/Button";
import BigNumber from "bignumber.js";
import { Scrollbars } from "react-custom-scrollbars";

import "../Modal.style.css";
import "./PacksModalCheckoutSection.style.css";

import goldImage from "../../../assets/hud/items/item-gold.png";

import { IPlantType, PlotSizeType } from "../../../utils/PlantPlot.interfaces";
import { PlotSizeMapping } from "../../../utils/PlantPlot.mapping";
import { goldNumberWithCommas, numberWithCommas } from "../../../utils/helpers";

import CoreButton, { ButtonColor, ButtonSize } from "../../CoreButton";

export interface IPacksModalCropCartItem {
  amount: number;
  cartItem: IPlantType;
}

export interface IPacksModalPlotCartItem {
  amount: number;
  cartItem: PlotSizeType;
}
export interface IPacksModalCheckoutSectionProp {
  cartPlotItems: IPacksModalPlotCartItem[];
  cartSeedItems: IPacksModalCropCartItem[];
  isLoading: boolean;
  isLoadingAfterAction: boolean;
  totalGold: BigNumber;
  handleActivateClick: () => void;
}

function PacksModalCheckoutSection({
  cartPlotItems,
  cartSeedItems,
  isLoading,
  isLoadingAfterAction,
  totalGold,
  handleActivateClick,
}: IPacksModalCheckoutSectionProp) {
  let checkoutButtonText = "Activate";
  let checkoutButtonColor = ButtonColor.green;
  let checkoutButtonTextSize = ButtonSize.lg;

  if (isLoadingAfterAction) {
    checkoutButtonText = "Loading...";
    checkoutButtonColor = ButtonColor.gray;
  }

  return (
    <div className="PackCartSection-cart">
      <div className="PackCartSection-cart_holder">
        <div className="PackCartItem-title">You Receive:</div>
        <div className="PackCartSection-cart_scroll">
          <Scrollbars>
            <div className="PackCartSection-cart_section">
              <div className="PackCartItem-subtitle">Gold:</div>
              <div className="PackCartSection-cart_price">
                <div className="PackShell-price_container">
                  <img
                    className="PackShell-gold_image"
                    src={goldImage}
                    alt="Seed Price"
                  />
                  {!isLoading && goldNumberWithCommas(totalGold)}
                </div>
              </div>
            </div>

            <div className="PackCartSection-cart_section">
              <div className="PackCartItem-subtitle">Plots:</div>
              {cartPlotItems.map((currentCartItem, cartItemIndex) => (
                <div
                  key={`cart_item-${cartItemIndex}`}
                  className="PackCartItem"
                >
                  <div className="PackCartItem-crop_frame">
                    <img
                      className="PackCartItem-image"
                      src={PlotSizeMapping[currentCartItem.cartItem].image}
                      alt="Plot size Icon"
                    />
                  </div>
                  <div className="PackCartItem-crop_description">
                    <div className="PackCartItem-crop_description-title">
                      {currentCartItem.amount} plots
                    </div>

                    <div className="PackCartItem-crop_description-text">
                      {PlotSizeMapping[currentCartItem.cartItem].name} Size
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="PackCartSection-cart_section">
              <div className="PackCartItem-subtitle">Seeds:</div>
              {cartSeedItems.map((currentCartItem, cartItemIndex) => (
                <div
                  key={`cart_item-${cartItemIndex}`}
                  className="PackCartItem"
                >
                  <div className="PackCartItem-crop_frame">
                    <img
                      className="PackCartItem-image"
                      // src={cropDetailsFrame}
                      // alt="Crop Icon Frame"
                      src={currentCartItem.cartItem.icon}
                      alt="Crop Icon"
                    />
                    {/* <img
                    className="PackCartItem-image PackCartItem-crop_frame-crop_image"
                    src={currentCartItem.cartItem.icon}
                    alt="Crop Icon"
                  /> */}
                  </div>
                  <div className="PackCartItem-crop_description">
                    <div className="PackCartItem-crop_description-title">
                      {currentCartItem.cartItem.displayName}
                    </div>

                    <div className="PackCartItem-crop_description-text">
                      {numberWithCommas(BigNumber(currentCartItem.amount))} seed
                      {currentCartItem.amount !== 1 && "s"}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Scrollbars>
        </div>
      </div>

      <div className="PackCartSection-cart_checkout">
        <CoreButton
          disabled={isLoadingAfterAction}
          onClickHandler={handleActivateClick}
          buttonText={checkoutButtonText}
          color={checkoutButtonColor}
          size={ButtonSize.lg}
          textSize={checkoutButtonTextSize}
        />
      </div>
    </div>
  );
}

export default PacksModalCheckoutSection;
