import Button from "@mui/material/Button";

import "./CoreButton.style.css";

import grayButton from "../assets/hud/buttons/button-pill-gray.svg";
import greenButton from "../assets/hud/buttons/button-pill-green.svg";
import orangeButton from "../assets/hud/buttons/button-pill-orange.svg";
import redButton from "../assets/hud/buttons/button-pill-red.svg";

export enum ButtonColor {
  gray = "gray",
  green = "green",
  red = "red",
  orange = "orange",
}

export enum ButtonSize {
  sm = "small",
  md = "medium",
  lg = "large",
}

export interface ICoreButtonProp {
  onClickHandler: () => void;
  buttonText: string;
  color?: ButtonColor;
  disabled?: boolean;
  size?: ButtonSize;
  textSize?: ButtonSize | null;
}

function CoreButton({
  onClickHandler,
  buttonText,
  color = ButtonColor.green,
  size = ButtonSize.md,
  disabled = false,
  textSize = null,
}: ICoreButtonProp) {
  const buttonColorMap = {
    [ButtonColor.gray]: grayButton,
    [ButtonColor.green]: greenButton,
    [ButtonColor.red]: redButton,
    [ButtonColor.orange]: orangeButton,
  };

  return (
    <Button
      className={`CoreButton ${size}`}
      onClick={onClickHandler}
      disabled={disabled}
    >
      <div className={`CoreButton-text ${!!textSize ? textSize : ""}`}>
        {buttonText}
      </div>
      <img
        className="CoreButton-image"
        src={buttonColorMap[color]}
        alt="Button"
      />
    </Button>
  );
}

export default CoreButton;
