import Button from "@mui/material/Button";
import BigNumber from "bignumber.js";
import { Scrollbars } from "react-custom-scrollbars";

import "../Modal.style.css";
import "./ShopModalCheckoutSection.style.css";

import removeButton from "../../../assets/hud/general/button-square-minus_remove_red.svg";
import cropDetailsFrame from "../../../assets/hud/shop/details_frame.svg";
import goldImage from "../../../assets/hud/items/item-gold.png";

import { IPlantType } from "../../../utils/PlantPlot.interfaces";
import {
  calculateBuyPrice,
  getPoolObj,
  goldNumberWithCommas,
  numberWithCommas,
} from "../../../utils/helpers";

import CoreButton, { ButtonColor, ButtonSize } from "../../CoreButton";

export interface IShopModalCheckoutSectionProp {
  cartItems: IShopModalCartItem[];
  poolBalances: any;
  isLoading: boolean;
  isLoadingAfterAction: boolean;
  goldAllowance: BigNumber;
  goldBalance: BigNumber;
  handleCheckoutPurchase: () => void;
  handleIncreaseGoldAllowance: () => void;
  handleRemoveFromCart: (removalIndex: number) => void;
}

export interface IShopModalCartItem {
  amount: number;
  cartItem: IPlantType;
}

function ShopModalCheckoutSection({
  cartItems,
  poolBalances,
  isLoading,
  isLoadingAfterAction,
  goldAllowance,
  goldBalance,
  handleCheckoutPurchase,
  handleIncreaseGoldAllowance,
  handleRemoveFromCart,
}: IShopModalCheckoutSectionProp) {
  const getCropCost = (amount: number, currentCartItem: IShopModalCartItem) =>
    calculateBuyPrice(
      amount,
      getPoolObj(poolBalances, currentCartItem.cartItem).goldTrackedAmount,
      getPoolObj(poolBalances, currentCartItem.cartItem).tokenTrackedAmount
    );

  const overallGoldCost = cartItems.reduce(
    (totalValue, currentCartItem) =>
      getCropCost(currentCartItem.amount, currentCartItem).plus(totalValue),
    BigNumber(0)
  );

  const isMoreAllowanceNeeded = overallGoldCost.gt(goldAllowance);
  const isCostOverBalance = overallGoldCost.gt(goldBalance);

  let checkoutButtonText = "Checkout";
  let checkoutButtonColor = ButtonColor.green;
  let checkoutButtonTextSize = ButtonSize.lg;

  if (isLoadingAfterAction) {
    checkoutButtonText = "Loading...";
    checkoutButtonColor = ButtonColor.gray;
  } else if (isCostOverBalance) {
    checkoutButtonText = "Too Expensive";
    checkoutButtonColor = ButtonColor.red;
    checkoutButtonTextSize = ButtonSize.md;
  } else if (isMoreAllowanceNeeded) {
    checkoutButtonText = "Continue";
    checkoutButtonColor = ButtonColor.orange;
  }

  return (
    <div className="CartSection-cart">
      <div className="CartSection-cart_list">
        <Scrollbars>
          {cartItems.map((currentCartItem, cartItemIndex) => (
            <div key={`cart_item-${cartItemIndex}`} className="CartItem">
              <div className="CartItem-remove">
                <Button
                  disabled={isLoadingAfterAction}
                  className="CartItem-remove_button"
                  onClick={() => handleRemoveFromCart(cartItemIndex)}
                >
                  <img
                    className="CartItem-image"
                    src={removeButton}
                    alt="Remove button"
                  />
                </Button>
              </div>
              <div className="CartItem-crop_frame">
                <img
                  className="CartItem-image"
                  src={cropDetailsFrame}
                  alt="Crop Icon Frame"
                />
                <img
                  className="CartItem-image CartItem-crop_frame-crop_image"
                  src={currentCartItem.cartItem.icon}
                  alt="Crop Icon"
                />
              </div>
              <div className="CartItem-crop_description">
                <div className="CartItem-crop_description-title">
                  {currentCartItem.cartItem.displayName}
                </div>

                <div className="CartItem-crop_description-text">
                  {numberWithCommas(BigNumber(currentCartItem.amount))} seed
                  {currentCartItem.amount !== 1 && "s"}
                </div>

                <div className="CartItem-crop_description-text">
                  <img
                    className="CartItem-crop_description-gold_image"
                    src={goldImage}
                    alt="Seed Price"
                  />

                  <div className="CartItem-crop_description-amount">
                    {!isLoading &&
                      currentCartItem.cartItem.name in poolBalances &&
                      !!getPoolObj(poolBalances, currentCartItem.cartItem) &&
                      goldNumberWithCommas(
                        getCropCost(currentCartItem.amount, currentCartItem)
                      )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Scrollbars>
      </div>

      <div className="CartSection-cart_price">
        <div className="Shell-price_container">
          <img className="Shell-gold_image" src={goldImage} alt="Seed Price" />
          {!isLoading && goldNumberWithCommas(overallGoldCost)}
        </div>
      </div>

      <div className="CartSection-cart_checkout">
        <CoreButton
          disabled={isLoadingAfterAction || isCostOverBalance}
          onClickHandler={
            isMoreAllowanceNeeded
              ? handleIncreaseGoldAllowance
              : handleCheckoutPurchase
          }
          buttonText={checkoutButtonText}
          color={checkoutButtonColor}
          size={ButtonSize.lg}
          textSize={checkoutButtonTextSize}
        />
      </div>
    </div>
  );
}

export default ShopModalCheckoutSection;
