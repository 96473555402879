import plot_1_1 from "../../assets/hud/packs/plots/filled/plot-filled-1x1.svg";

import { IPlotSizeDetails, PlotSizeType } from "../PlantPlot.interfaces";

const PlotSize1x1: IPlotSizeDetails = {
  name: PlotSizeType.size1x1,
  image: plot_1_1,
};

export default PlotSize1x1;
