export enum BerryBushPlantNameType {
  blueberry = "blueberry",
  blackberry = "blackberry",
  gojiBerry = "gojiBerry",
  raspberry = "raspberry",
  strawberry = "strawberry",
}

export enum BerryBushPlantDisplayNameType {
  blueberry = "Blueberry",
  blackberry = "Blackberry",
  gojiBerry = "Goji berry",
  raspberry = "Raspberry",
  strawberry = "Strawberry",
}

export enum BerryBushPlantBlockchainNameType {
  blueberry = "zBLACKBERRY",
  blackberry = "zBLUEBERRY",
  gojiBerry = "zGOJIBERRY",
  raspberry = "zRASPBERRY",
  strawberry = "zSTRAWBERRY",
}
