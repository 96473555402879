import plot_2_2 from "../../assets/hud/packs/plots/filled/plot-filled-2x2.svg";

import { IPlotSizeDetails, PlotSizeType } from "../PlantPlot.interfaces";

const PlotSize2x2: IPlotSizeDetails = {
  name: PlotSizeType.size2x2,
  image: plot_2_2,
};

export default PlotSize2x2;
