import { ReactElement, useState } from "react";
import BigNumber from "bignumber.js";
import moment from "moment";
import pluralize from "pluralize";
import Countdown from "react-countdown";

import "./DetailsModal.style.css";

import { OPENSEA_LINK, SYSTEM_ADDRS } from "../../../env";

import { IAccountRegistrationDetails } from "../../../interfaces/accountRegistration";
import {
  IBlockchainMessageResponse,
  IBlockchainResponse,
  useConnectedAccount,
} from "../../../interfaces/blockchain";
import {
  IConnectedGoldAccount,
  IContractSet,
} from "../../../interfaces/contracts/contracts.interfaces";
import {
  clearDeadCrops,
  clearGrowingCrops,
  harvestCrops,
  PlotCropAction,
} from "../../../interfaces/plot";

import { IPlotsLoadingState } from "../../../reducers/plotsLoading";
import { renderTime } from "../../../utils/helpers";
import {
  IPlantType,
  IPlotSet,
  PlantPlotLongName,
} from "../../../utils/PlantPlot.interfaces";
import {
  getPlantStage,
  PlantGrowthStageDisplayName,
} from "../../../utils/PlantGrowthStage";
import {
  getGrowthRate,
  getYieldRate,
} from "../../../utils/PlantPlotDetails.interfaces";

import { ButtonColor } from "../../CoreButton";
import Loader from "../../Loader";
import { useToolType } from "../../tooltip/TooltipAlert";
import { getPlotHarvestDetails } from "../../tiles/PlotTileSet";

import AlertModal, { AlertType } from "../alert/AlertModal";

export interface IDetailsModalProp {
  accountDetails: IAccountRegistrationDetails;
  contracts: IContractSet;
  cropDetails: any;
  currentPlot: IPlotSet | null;
  open: boolean;
  plotsLoadingState: IPlotsLoadingState;
  handleClickOutCloseHandler: () => void;
  handleOpenStorage: () => void;
  handlePlotLoadingAddition: (
    plotId: string,
    actionState: PlotCropAction,
    cropBlockchainName: string,
    cropAmountDelta: number
  ) => void;
  handlePlotLoadingRemoval: (
    plotId: string,
    cropBlockchainName: string,
    cropAmountDelta: number
  ) => void;
  handleUpdatePlotData: (
    actionType: PlotCropAction,
    currentPlot: IPlotSet,
    crop: any | null
  ) => void;
}

interface IModalRow {
  name: string;
  value: ReactElement;
  style?: any | undefined;
}

function DetailsModal({
  accountDetails,
  contracts,
  cropDetails,
  currentPlot,
  open,
  plotsLoadingState,
  handleClickOutCloseHandler,
  handleOpenStorage,
  handlePlotLoadingAddition,
  handlePlotLoadingRemoval,
  handleUpdatePlotData,
}: IDetailsModalProp) {
  const { connectedAccount } = useConnectedAccount();
  const { invokeAlert, invokeCxyzAlert, invokeMetaMaskAlert } = useToolType();
  let modalDetails: IModalRow[][] = [];
  const [isLoadingPlotAction, setIsLoadingPlotAction] = useState(false);

  const getPlotHarvest = (plotToCheck: IPlotSet) => {
    // Calculate the total yield, 15 is the max value of the base yield (uint4)
    return Math.floor(
      (plotToCheck.yieldConfig.maxYield - plotToCheck.yieldConfig.minYield) *
        (plotToCheck.baseYield / 15) +
        plotToCheck.yieldConfig.minYield
    );
  };

  if (!!currentPlot) {
    let plantStage = getPlantStage(currentPlot);
    const plantedAmount =
      currentPlot.plotDimensions.height *
      currentPlot.plotDimensions.width *
      currentPlot.plotDimensions.tileArea;

    let readyTimestamp: number = Infinity;
    let expiredTimeStamp: number = Infinity;
    const isPlanted = !!plantStage;

    if (isPlanted) {
      readyTimestamp =
        (currentPlot.timeSet.started + currentPlot.timeSet.ready) * 1000;

      expiredTimeStamp =
        (currentPlot.timeSet.started + currentPlot.timeSet.expired) * 1000;
    }

    const isPastReadyTime = moment.now() > readyTimestamp;
    const isPastExpiredTime = moment.now() > expiredTimeStamp;

    modalDetails = [
      [
        {
          name: "Plot Type:",
          value: <>{PlantPlotLongName[currentPlot.plotType]}</>,
        },
        {
          name: "Speed:",
          value: <>{getGrowthRate(currentPlot.baseSpeed)}</>,
        },
        {
          name: "Yield:",
          value: (
            <>{!!currentPlot ? getYieldRate(currentPlot.baseYield) : "None"}</>
          ),
        },
        {
          name: "Harvests",
          value: <>{currentPlot.countHarvests}</>,
        },
      ],
      [
        {
          name: "Crops Planted:",
          value: (
            <>
              {currentPlot.plantType
                ? pluralize(currentPlot.plantType.displayName)
                : "Nothing"}
            </>
          ),
        },
        {
          name: "Stage:",
          value: (
            <>
              {plantStage
                ? PlantGrowthStageDisplayName[plantStage]
                : "Fresh Soil"}
            </>
          ),
        },
        {
          name: `Seeds ${plantStage ? "Planted" : "Needed"}:`,
          value: <>{plantedAmount}</>,
        },
      ],
    ];
    if (!isPastExpiredTime) {
      modalDetails[1].push({
        name: isPastReadyTime ? "Harvest Amount:" : "Potential Harvest:",
        value: <>{getPlotHarvest(currentPlot)}</>,
      });
    }

    if (isPlanted) {
      const harvestModalSet = [
        {
          name: `Planted:`,
          value: (
            <>
              {moment(currentPlot.timeSet.started * 1000).format(
                "MMM Do [at] h:mm a"
              )}
            </>
          ),
          style: { fontSize: "1rem" },
        },
      ];

      if (!isPastExpiredTime) {
        const countdownTimeStamp = new Date(
          isPastReadyTime ? expiredTimeStamp : readyTimestamp
        );

        harvestModalSet.push({
          name: isPastReadyTime ? "Crops Die in:" : "Harvestable in",
          value: <Countdown date={countdownTimeStamp} renderer={renderTime} />,
          style: { fontSize: "1rem" },
        });
      } else {
        harvestModalSet.push({
          name: "Crops:",
          value: <>Died</>,
          style: { fontSize: "1rem" },
        });
      }

      modalDetails.push(harvestModalSet);
    }
  }

  const {
    buttonText: confirmButtonText,
    confirmButtonColor,
    harvestState,
  } = getPlotHarvestDetails(currentPlot);

  const handleOpenOpensea = () => {
    if (!!currentPlot?.plotId) {
      const openseaLink = `${OPENSEA_LINK}${SYSTEM_ADDRS.core.plot}/${currentPlot.plotId}`;
      const win: any = window.open(openseaLink, "_blank");
      win.focus();
    }
  };

  const handleCloseAffirmativeDetailsAlert = async () => {
    if (!!connectedAccount.gold) {
      if (harvestState === PlotCropAction.plant) {
        handleClickOutCloseHandler();
        handleOpenStorage();
      } else {
        setIsLoadingPlotAction(true);

        // Start with the action as clear growing crops
        let plotAction: (
          accountDetails: IAccountRegistrationDetails,
          connectedGoldAccount: IConnectedGoldAccount,
          contracts: IContractSet,
          transactionCountAhead: number,
          plotId: string,
          cropName: string
        ) => Promise<IBlockchainResponse> = clearGrowingCrops;

        if (harvestState === PlotCropAction.clearDead) {
          // Clear the dead
          plotAction = clearDeadCrops;
        } else if (harvestState === PlotCropAction.harvest) {
          // Harvest the plot
          plotAction = harvestCrops;
        }

        if (!!currentPlot && !!currentPlot.plantType) {
          setIsLoadingPlotAction(false);
          // Add the plot to the loading log
          handlePlotLoadingAddition(
            currentPlot.plotId,
            harvestState,
            currentPlot.plantType.blockchainName,
            0
          );
          handleClickOutCloseHandler();

          // console.log("-=-= Object.keys(plotsLoadingState.plotIdsUsed).length");
          // console.log(Object.keys(plotsLoadingState.plotIdsUsed).length);
          const { isSuccess, transactionDetails, message } = await plotAction(
            accountDetails,
            connectedAccount.gold,
            contracts,
            Object.keys(plotsLoadingState.plotIdsUsed).length,
            currentPlot.plotId,
            currentPlot.plantType.displayName
          );

          const completePlotActionCallback = (
            message: IBlockchainMessageResponse,
            showCropIcon?: boolean
          ) => {
            const currentCrop = currentPlot.plantType;
            // Take the plot out of the loading log
            handlePlotLoadingRemoval(
              currentPlot.plotId,
              currentCrop ? currentCrop.blockchainName : "",
              harvestState === PlotCropAction.harvest
                ? getPlotHarvest(currentPlot)
                : 0
            );

            if (message.isMetaMask) {
              invokeMetaMaskAlert(message.text);
            } else {
              if (showCropIcon && currentCrop?.icon) {
                invokeAlert(currentCrop?.icon, message.text);
              } else {
                invokeCxyzAlert(message.text);
              }
            }
            setIsLoadingPlotAction(false);
          };

          if (isSuccess) {
            // Pass transaction to be watched
            handleUpdatePlotData(harvestState, currentPlot, null);
            completePlotActionCallback(message, true);
          } else {
            // Show error message
            completePlotActionCallback(message);
          }
        }
      }
    }
  };

  return (
    <AlertModal
      long
      disabled={isLoadingPlotAction}
      showAlternativeButton={false}
      open={open}
      alternativeButtonText={"View NFT"}
      type={AlertType.details}
      onClickOutCloseHandler={handleClickOutCloseHandler}
      onAlternativeHandler={handleOpenOpensea}
      onConfirmHandler={handleCloseAffirmativeDetailsAlert}
      confirmButtonColor={
        !isLoadingPlotAction ? confirmButtonColor : ButtonColor.gray
      }
      confirmButtonText={
        !isLoadingPlotAction ? confirmButtonText : "Loading..."
      }
    >
      <Loader isLoading={isLoadingPlotAction} />

      {modalDetails.map((modalSectionDetails, index) => (
        <div
          key={`alert-section-${index}`}
          className="DetailsModal-section_container"
        >
          {modalSectionDetails.map((currentRow, index) => (
            <div
              key={`alert-row-${index}`}
              className="DetailsModal-section_row"
            >
              <span>{currentRow.name}</span>
              <span
                className="DetailsModal-results"
                style={currentRow.style ? currentRow.style : null}
              >
                {currentRow.value}
              </span>
            </div>
          ))}
        </div>
      ))}
    </AlertModal>
  );
}

export default DetailsModal;
