export enum FruitVinePlantNameType {
  cantaloupe = "cantaloupe",
  grape = "grape",
  pumpkin = "pumpkin",
  tomato = "tomato",
  watermelon = "watermelon",
}

export enum FruitVinePlantDisplayNameType {
  cantaloupe = "Cantaloupe",
  grape = "Grapes",
  pumpkin = "Pumpkin",
  tomato = "Tomato",
  watermelon = "Watermelon",
}

export enum FruitVinePlantBlockchainNameType {
  cantaloupe = "zCANTALOUPE",
  grape = "zGRAPE",
  pumpkin = "zPUMPKIN",
  tomato = "zTOMATO",
  watermelon = "zWATERMELON",
}
