import { getDimensions } from "../../utils/constants";
import { SproutType, SproutImageMap } from "../../utils/Sprout";

import { ConstructedTile } from "./BaseTile";

interface ISproutTileProp {
  isLifted: boolean;
  sproutType: SproutType;
  height: number;
  width: number;
}

function SproutTile({
  isLifted,
  sproutType,
  height = getDimensions().plotSizeHeight,
  width = getDimensions().plotSizeWidth,
}: ISproutTileProp) {
  return (
    <ConstructedTile
      imageSource={SproutImageMap[sproutType]}
      isLifted={isLifted}
      height={height}
      width={width}
    />
  );
}

export default SproutTile;
