import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import BigNumber from "bignumber.js";

import goldImage from "../assets/hud/items/item-gold.png";
import skyoneerLogo from "../assets/logo/logo.png";

import { useConnectedAccount } from "../interfaces/blockchain";

import { goldNumberWithCommas } from "../utils/helpers";

import CoreButton, { ButtonColor, ButtonSize } from "./CoreButton";
import classes, {
  LogoIcon,
  LogoImage,
  WalletDetailsTitle,
} from "./Header.styles";
import "./Header.style.css";

export interface IHeaderProp {
  walletGoldBalance?: BigNumber | null;
  isLoadingWalletGoldBalance?: boolean | null;
}

function Header({
  walletGoldBalance = null,
  isLoadingWalletGoldBalance = null,
}: IHeaderProp) {
  const { authActions, authState, connectedAccount } = useConnectedAccount();

  const handleDisconnectClick = async () => {
    await authActions.logout();
  };

  return (
    <AppBar position="static" color="transparent" sx={classes.appBar}>
      <Toolbar>
        <Grid
          container
          direction="row"
          alignItems="center"
          className="Header-container"
        >
          <Grid item className="Header-title Header-element">
            <a
              href="https://www.skyoneer.com"
              aria-current="page"
              aria-label="home"
            >
              <LogoImage src={skyoneerLogo} alt="Skyoneer logo" />
            </a>
          </Grid>

          <Grid item className="Header-element">
            <Grid container direction="row" sx={classes.walletBalanceContainer}>
              {!!connectedAccount.gold &&
                (isLoadingWalletGoldBalance || !!walletGoldBalance) && (
                  <Box
                    sx={(theme) => ({
                      ...classes.walletInternalDetailsHolder(theme),
                      ...classes.walletInternalDetailsNumbers(theme),
                    })}
                  >
                    <Box sx={classes.iconContainer}>
                      <LogoIcon src={goldImage} alt="Gold" />
                    </Box>

                    <Grid item sx={classes.walletInternalDetailsTitle}>
                      <WalletDetailsTitle>
                        {isLoadingWalletGoldBalance
                          ? "Loading"
                          : goldNumberWithCommas(walletGoldBalance)}
                      </WalletDetailsTitle>
                    </Grid>
                  </Box>
                )}
            </Grid>
          </Grid>

          {authState.authenticated && (
            <Grid item className="Header-element">
              <Grid container direction="row" className="Header-wallet_element">
                <Box sx={classes.walletInternalDetailsHolder}>
                  <Grid
                    container
                    direction="row"
                    className="Header-wallet_element"
                  >
                    <Grid item sx={classes.walletInternalDetailsTitle}>
                      <WalletDetailsTitle>
                        Welcome Explorer!{" "}
                      </WalletDetailsTitle>
                    </Grid>
                    <Grid item sx={classes.walletDisconnectButton}>
                      <CoreButton
                        disabled={false}
                        onClickHandler={handleDisconnectClick}
                        buttonText="Log out"
                        color={ButtonColor.red}
                        size={ButtonSize.md}
                        textSize={ButtonSize.md}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
