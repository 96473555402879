import BigNumber from "bignumber.js";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

export const isNumber = (str: any): boolean => {
  return typeof str === "number";
};

export const isNumeric = (str: any): boolean => {
  if (typeof str != "string") return false;
  return !BigNumber(str).isNaN();
};

interface IStringReductionOptions {
  showAll?: boolean;
  shownAmount?: number;
  hideLast?: number;
}

export const addressStringReduction = (
  address: any,
  options?: IStringReductionOptions
) => {
  if (options?.showAll) {
    return address;
  }

  const lettersShown = options?.shownAmount || 5;
  let firstChunk = address.substring(0, lettersShown);
  let lastChunk = address.substring(address.length - lettersShown - 1);

  return `${firstChunk}...${!options?.hideLast && lastChunk}`;
};

// // // // // // // // // // // // // // // // // // // //
// NUMBER FORMATTING
// // // // // // // // // // // // // // // // // // // //

interface IFormatDecimalOptions {
  decimalsOfInputPadding?: number;
  decimalsToDisplay?: number;
  prefixText?: string | null;
  postText?: string | null;
  showComma?: boolean | null;
  isPrefixAfterNegative?: boolean | null;
}

export const formatDecimal = (
  rawX: BigNumber | string | number | null,
  inputFormatOptions?: IFormatDecimalOptions
) => {
  let formatOptions = { ...inputFormatOptions };
  if (
    !("decimalsOfInputPadding" in formatOptions) ||
    !formatOptions.decimalsOfInputPadding
  ) {
    formatOptions.decimalsOfInputPadding = 0;
  }
  if (
    !("decimalsToDisplay" in formatOptions) ||
    (!formatOptions.decimalsToDisplay && formatOptions.decimalsToDisplay !== 0)
  ) {
    formatOptions.decimalsToDisplay = 2;
  }
  if (!("showComma" in formatOptions)) {
    formatOptions.showComma = true;
  }

  let x: BigNumber | null = null;
  if (BigNumber.isBigNumber(rawX) || isNumeric(rawX) || isNumber(rawX)) {
    const powerOfInputPadding = BigNumber(10).pow(
      formatOptions.decimalsOfInputPadding
    );
    x = BigNumber(parseFloat(rawX as string)).div(powerOfInputPadding);
  }

  if (!x) {
    return null;
  }

  const prefixText = formatOptions?.prefixText || "";
  const postText = formatOptions?.postText || "";
  const isPrefixAfterNegative = formatOptions?.isPrefixAfterNegative || false;

  const negativeElement = x.isLessThan(0) ? "-" : "";
  const prefixElement = isPrefixAfterNegative
    ? `${negativeElement}${prefixText}`
    : `${prefixText}${negativeElement}`;

  const numberFormat: BigNumber.Format = {
    decimalSeparator: ".",
    groupSeparator: formatOptions?.showComma ? "," : "",
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: " ",
    fractionGroupSize: 0,
  };

  return `${prefixElement}${x
    .abs()
    .toFormat(formatOptions.decimalsToDisplay, numberFormat)}${postText}`;
};

interface IFormatDollarsOptions {
  decimalsOfInputPadding?: number;
  decimalsToDisplay?: number;
}

export const formatDollars = (
  rawX: BigNumber | string | number | null,
  formatOptions: IFormatDollarsOptions = {}
) =>
  formatDecimal(rawX, {
    ...formatOptions,
    prefixText: "$",
    isPrefixAfterNegative: true,
  });

export const formatPercent = ({
  value,
  decimalsToDisplay,
  isDecimal = true,
  showPercentText = true,
  showComma = true,
}: {
  value: BigNumber | string | number;
  decimalsToDisplay: number;
  isDecimal?: boolean;
  showPercentText?: boolean;
  showComma?: boolean;
}) => {
  const valueAsBigNumber = isDecimal ? BigNumber(value).times(100) : value;
  return formatDecimal(valueAsBigNumber, {
    decimalsToDisplay,
    postText: showPercentText ? "%" : "",
    showComma,
  });
};

export const convertToAmountSet = (amount: string) => amount.split(".");

// // // // // // // // // // // // // // // // // // // //
// DATE FORMATTING
// // // // // // // // // // // // // // // // // // // //

export const reformatToMonthYear = (
  yearMonthString: string | Date,
  isShort: boolean = false
): string => dayjs(yearMonthString).format(isShort ? `MMM'YY` : `MMMM YYYY`);

export const reformatCleanDate = (yearMonthString: string | Date): string =>
  dayjs(yearMonthString).format("MMMM D, YYYY");

interface IReformatCleanShortDateOptions {
  removeDate?: boolean;
  hasHour?: boolean;
  hasMinute?: boolean;
}

export const reformatCleanShortDate = (
  yearMonthString: string | Date,
  options?: IReformatCleanShortDateOptions
): string =>
  dayjs(yearMonthString).format(
    `${options?.removeDate ? "" : "MMM D"}${
      !options?.removeDate && options?.hasHour ? " - " : ""
    }${
      options?.hasHour && options?.hasMinute // Has both minute and hour
        ? "h:mm A"
        : options?.hasHour // Has only hour
        ? "h A"
        : ""
    }`
  );
