// // // // // // // // // // // // // // // // // // // //
// ENVIRONMENT STATE
// // // // // // // // // // // // // // // // // // // //

const IS_DEV =
  !!process.env.REACT_APP_ENV &&
  process.env.REACT_APP_ENV === "dev";
const IS_PROD =
  !!process.env.REACT_APP_ENV &&
  process.env.REACT_APP_ENV === "prod";

export const IS_LIVE_RUN = IS_PROD || IS_DEV;

// Maximum plots to include on one page
export const MAX_PLOT_PER_PAGE_LIMIT = parseInt(
  process.env.REACT_APP_MAX_PLOT_PER_PAGE_LIMIT
    ? process.env
        .REACT_APP_MAX_PLOT_PER_PAGE_LIMIT
    : "9",
  10
);

// Maximum tiles in one plot
export const MAX_TILES_IN_PLOT = parseInt(
  process.env.REACT_APP_MAX_TILES_IN_PLOT
    ? process.env.REACT_APP_MAX_TILES_IN_PLOT
    : "5",
  10
);

// Page content should show
export const IS_PAGE_LIVE =
  !!process.env.REACT_APP_IS_PAGE_LIVE &&
  process.env.REACT_APP_IS_PAGE_LIVE === "yes";

// // // // // // // // // // // // // // // // // // // //
// ALCHEMY RPC NODE KEY
// // // // // // // // // // // // // // // // // // // //

const LOCAL_ALCHEMY_NODE_KEY = process.env
  .REACT_APP_LOCAL_ALCHEMY_NODE_KEY
  ? process.env.REACT_APP_LOCAL_ALCHEMY_NODE_KEY
  : "";
const DEV_ALCHEMY_KEY = process.env
  .REACT_APP_DEV_ALCHEMY_KEY
  ? process.env.REACT_APP_DEV_ALCHEMY_KEY
  : "";
const PROD_ALCHEMY_KEY = process.env
  .REACT_APP_PROD_ALCHEMY_KEY
  ? process.env.REACT_APP_PROD_ALCHEMY_KEY
  : "";

export const ALCHEMY_KEY = IS_PROD
  ? PROD_ALCHEMY_KEY
  : IS_DEV
  ? DEV_ALCHEMY_KEY
  : LOCAL_ALCHEMY_NODE_KEY;

// // // // // // // // // // // // // // // // // // // //
// BASE PACK METADATA URL
// // // // // // // // // // // // // // // // // // // //

const LOCAL_PACK_METADATA_URL = process.env
  .REACT_APP_LOCAL_PACK_METADATA_URL
  ? process.env.REACT_APP_LOCAL_PACK_METADATA_URL
  : "";
const DEV_PACK_METADATA_URL = process.env
  .REACT_APP_DEV_PACK_METADATA_URL
  ? process.env.REACT_APP_DEV_PACK_METADATA_URL
  : "";
const PROD_PACK_METADATA_URL = process.env
  .REACT_APP_PROD_PACK_METADATA_URL
  ? process.env.REACT_APP_PROD_PACK_METADATA_URL
  : "";

export const PACK_METADATA_URL = IS_PROD
  ? PROD_PACK_METADATA_URL
  : IS_DEV
  ? DEV_PACK_METADATA_URL
  : LOCAL_PACK_METADATA_URL;

// // // // // // // // // // // // // // // // // // // //
// BASE RPC NODE URL
// // // // // // // // // // // // // // // // // // // //

const LOCAL_BASE_RPC_URL = process.env
  .REACT_APP_LOCAL_BASE_RPC_URL
  ? process.env.REACT_APP_LOCAL_BASE_RPC_URL
  : "";
const DEV_BASE_RPC_URL = process.env
  .REACT_APP_DEV_BASE_RPC_URL
  ? process.env.REACT_APP_DEV_BASE_RPC_URL
  : "";
const PROD_BASE_RPC_URL = process.env
  .REACT_APP_PROD_BASE_RPC_URL
  ? process.env.REACT_APP_PROD_BASE_RPC_URL
  : "";

export const BASE_RPC_URL = IS_PROD
  ? PROD_BASE_RPC_URL
  : IS_DEV
  ? DEV_BASE_RPC_URL
  : LOCAL_BASE_RPC_URL;

// // // // // // // // // // // // // // // // // // // //
// GOLD RPC NODE URL
// // // // // // // // // // // // // // // // // // // //

const LOCAL_GOLD_RPC_URL = process.env
  .REACT_APP_LOCAL_GOLD_RPC_URL
  ? process.env.REACT_APP_LOCAL_GOLD_RPC_URL
  : "";
const DEV_GOLD_RPC_URL = process.env
  .REACT_APP_DEV_GOLD_RPC_URL
  ? process.env.REACT_APP_DEV_GOLD_RPC_URL
  : "";
const PROD_GOLD_RPC_URL = process.env
  .REACT_APP_PROD_GOLD_RPC_URL
  ? process.env.REACT_APP_PROD_GOLD_RPC_URL
  : "";

export const GOLD_RPC_URL = IS_PROD
  ? PROD_GOLD_RPC_URL
  : IS_DEV
  ? DEV_GOLD_RPC_URL
  : LOCAL_GOLD_RPC_URL;

// // // // // // // // // // // // // // // // // // // //
// SUBGRAPH URL
// // // // // // // // // // // // // // // // // // // //

const LOCAL_SUBGRAPH_URL = process.env
  .REACT_APP_LOCAL_SUBGRAPH_URL
  ? process.env.REACT_APP_LOCAL_SUBGRAPH_URL
  : "";
const DEV_SUBGRAPH_URL = process.env
  .REACT_APP_DEV_SUBGRAPH_URL
  ? process.env.REACT_APP_DEV_SUBGRAPH_URL
  : "";
const PROD_SUBGRAPH_URL = process.env
  .REACT_APP_PROD_SUBGRAPH_URL
  ? process.env.REACT_APP_PROD_SUBGRAPH_URL
  : "";

export const SUBGRAPH_URL = IS_PROD
  ? PROD_SUBGRAPH_URL
  : IS_DEV
  ? DEV_SUBGRAPH_URL
  : LOCAL_SUBGRAPH_URL;

// // // // // // // // // // // // // // // // // // // //
// OPENSEA LINK
// // // // // // // // // // // // // // // // // // // //

const LOCAL_OPENSEA_LINK = process.env
  .REACT_APP_LOCAL_OPENSEA_LINK
  ? process.env.REACT_APP_LOCAL_OPENSEA_LINK
  : "";
const DEV_OPENSEA_LINK = process.env
  .REACT_APP_DEV_OPENSEA_LINK
  ? process.env.REACT_APP_DEV_OPENSEA_LINK
  : "";
const PROD_OPENSEA_LINK = process.env
  .REACT_APP_PROD_OPENSEA_LINK
  ? process.env.REACT_APP_PROD_OPENSEA_LINK
  : "";

export const OPENSEA_LINK = IS_PROD
  ? PROD_OPENSEA_LINK
  : IS_DEV
  ? DEV_OPENSEA_LINK
  : LOCAL_OPENSEA_LINK;

// // // // // // // // // // // // // // // // // // // //
// MINT PACK LINK
// // // // // // // // // // // // // // // // // // // //

const LOCAL_MINT_PACK_LINK = process.env
  .REACT_APP_LOCAL_MINT_PACK_LINK
  ? process.env.REACT_APP_LOCAL_MINT_PACK_LINK
  : "";
const DEV_MINT_PACK_LINK = process.env
  .REACT_APP_DEV_MINT_PACK_LINK
  ? process.env.REACT_APP_DEV_MINT_PACK_LINK
  : "";
const PROD_MINT_PACK_LINK = process.env
  .REACT_APP_PROD_MINT_PACK_LINK
  ? process.env.REACT_APP_PROD_MINT_PACK_LINK
  : "";

export const MINT_PACK_LINK = IS_PROD
  ? PROD_MINT_PACK_LINK
  : IS_DEV
  ? DEV_MINT_PACK_LINK
  : LOCAL_MINT_PACK_LINK;

// // // // // // // // // // // // // // // // // // // //
// OPENSEA PACK LINK
// // // // // // // // // // // // // // // // // // // //

const LOCAL_OPENSEA_PACK_LINK = process.env
  .REACT_APP_LOCAL_OPENSEA_PACK_LINK
  ? process.env.REACT_APP_LOCAL_OPENSEA_PACK_LINK
  : "";
const DEV_OPENSEA_PACK_LINK = process.env
  .REACT_APP_DEV_OPENSEA_PACK_LINK
  ? process.env.REACT_APP_DEV_OPENSEA_PACK_LINK
  : "";
const PROD_OPENSEA_PACK_LINK = process.env
  .REACT_APP_PROD_OPENSEA_PACK_LINK
  ? process.env.REACT_APP_PROD_OPENSEA_PACK_LINK
  : "";

export const OPENSEA_PACK_LINK = IS_PROD
  ? PROD_OPENSEA_PACK_LINK
  : IS_DEV
  ? DEV_OPENSEA_PACK_LINK
  : LOCAL_OPENSEA_PACK_LINK;

// // // // // // // // // // // // // // // // // // // //
// CHAIN ID
// // // // // // // // // // // // // // // // // // // //

const LOCAL_CHAIN_ID = process.env
  .REACT_APP_LOCAL_CHAIN_ID
  ? process.env.REACT_APP_LOCAL_CHAIN_ID
  : "";
const DEV_CHAIN_ID = process.env
  .REACT_APP_DEV_CHAIN_ID
  ? process.env.REACT_APP_DEV_CHAIN_ID
  : "";
const PROD_CHAIN_ID = process.env
  .REACT_APP_PROD_CHAIN_ID
  ? process.env.REACT_APP_PROD_CHAIN_ID
  : "";

export const CHAIN_ID = IS_PROD
  ? PROD_CHAIN_ID
  : IS_DEV
  ? DEV_CHAIN_ID
  : LOCAL_CHAIN_ID;

// // // // // // // // // // // // // // // // // // // //
// CHAIN NAME
// // // // // // // // // // // // // // // // // // // //

const LOCAL_CHAIN_NAME = process.env
  .REACT_APP_LOCAL_CHAIN_NAME
  ? process.env.REACT_APP_LOCAL_CHAIN_NAME
  : "";
const DEV_CHAIN_NAME = process.env
  .REACT_APP_DEV_CHAIN_NAME
  ? process.env.REACT_APP_DEV_CHAIN_NAME
  : "";
const PROD_CHAIN_NAME = process.env
  .REACT_APP_PROD_CHAIN_NAME
  ? process.env.REACT_APP_PROD_CHAIN_NAME
  : "";

export const CHAIN_NAME = IS_PROD
  ? PROD_CHAIN_NAME
  : IS_DEV
  ? DEV_CHAIN_NAME
  : LOCAL_CHAIN_NAME;

// // // // // // // // // // // // // // // // // // // //
// AIRSTACK PROJECT ID
// // // // // // // // // // // // // // // // // // // //

const LOCAL_AIRSTACK_PROJECT_ID = process.env
  .REACT_APP_LOCAL_AIRSTACK_PROJECT_ID
  ? process.env
      .REACT_APP_LOCAL_AIRSTACK_PROJECT_ID
  : "";
const DEV_AIRSTACK_PROJECT_ID = process.env
  .REACT_APP_DEV_AIRSTACK_PROJECT_ID
  ? process.env.REACT_APP_DEV_AIRSTACK_PROJECT_ID
  : "";
const PROD_AIRSTACK_PROJECT_ID = process.env
  .REACT_APP_PROD_AIRSTACK_PROJECT_ID
  ? process.env.REACT_APP_PROD_AIRSTACK_PROJECT_ID
  : "";

export const AIRSTACK_PROJECT_ID = IS_PROD
  ? PROD_AIRSTACK_PROJECT_ID
  : IS_DEV
  ? DEV_AIRSTACK_PROJECT_ID
  : LOCAL_AIRSTACK_PROJECT_ID;

// // // // // // // // // // // // // // // // // // // //
// BICONOMY BUNDLER URL
// // // // // // // // // // // // // // // // // // // //

const LOCAL_BICONOMY_GOLD_BUNDLER_URL = process
  .env.REACT_APP_LOCAL_BICONOMY_GOLD_BUNDLER_URL
  ? process.env
      .REACT_APP_LOCAL_BICONOMY_GOLD_BUNDLER_URL
  : "";
const DEV_BICONOMY_GOLD_BUNDLER_URL = process.env
  .REACT_APP_DEV_BICONOMY_GOLD_BUNDLER_URL
  ? process.env
      .REACT_APP_DEV_BICONOMY_GOLD_BUNDLER_URL
  : "";
const PROD_BICONOMY_GOLD_BUNDLER_URL = process.env
  .REACT_APP_PROD_BICONOMY_GOLD_BUNDLER_URL
  ? process.env
      .REACT_APP_PROD_BICONOMY_GOLD_BUNDLER_URL
  : "";

export const BICONOMY_GOLD_BUNDLER_URL = IS_PROD
  ? PROD_BICONOMY_GOLD_BUNDLER_URL
  : IS_DEV
  ? DEV_BICONOMY_GOLD_BUNDLER_URL
  : LOCAL_BICONOMY_GOLD_BUNDLER_URL;

// // // // // // // // // // // // // // // // // // // //
// BICONOMY PAYMASTER API KEY
// // // // // // // // // // // // // // // // // // // //

const LOCAL_BICONOMY_GOLD_PAYMASTER_API_KEY =
  process.env
    .REACT_APP_LOCAL_BICONOMY_GOLD_PAYMASTER_API_KEY
    ? process.env
        .REACT_APP_LOCAL_BICONOMY_GOLD_PAYMASTER_API_KEY
    : "";
const DEV_BICONOMY_GOLD_PAYMASTER_API_KEY =
  process.env
    .REACT_APP_DEV_BICONOMY_GOLD_PAYMASTER_API_KEY
    ? process.env
        .REACT_APP_DEV_BICONOMY_GOLD_PAYMASTER_API_KEY
    : "";
const PROD_BICONOMY_GOLD_PAYMASTER_API_KEY =
  process.env
    .REACT_APP_PROD_BICONOMY_GOLD_PAYMASTER_API_KEY
    ? process.env
        .REACT_APP_PROD_BICONOMY_GOLD_PAYMASTER_API_KEY
    : "";

export const BICONOMY_GOLD_PAYMASTER_API_KEY =
  IS_PROD
    ? PROD_BICONOMY_GOLD_PAYMASTER_API_KEY
    : IS_DEV
    ? DEV_BICONOMY_GOLD_PAYMASTER_API_KEY
    : LOCAL_BICONOMY_GOLD_PAYMASTER_API_KEY;

// // // // // // // // // // // // // // // // // // // //
// PRIVY API KEY
// // // // // // // // // // // // // // // // // // // //

const LOCAL_PRIVY_API_KEY = process.env
  .REACT_APP_LOCAL_PRIVY_API_KEY
  ? process.env.REACT_APP_LOCAL_PRIVY_API_KEY
  : "";
const DEV_PRIVY_API_KEY = process.env
  .REACT_APP_DEV_PRIVY_API_KEY
  ? process.env.REACT_APP_DEV_PRIVY_API_KEY
  : "";
const PROD_PRIVY_API_KEY = process.env
  .REACT_APP_PROD_PRIVY_API_KEY
  ? process.env.REACT_APP_PROD_PRIVY_API_KEY
  : "";

export const PRIVY_API_KEY = IS_PROD
  ? PROD_PRIVY_API_KEY
  : IS_DEV
  ? DEV_PRIVY_API_KEY
  : LOCAL_PRIVY_API_KEY;

// // // // // // // // // // // // // // // // // // // //
// WALLET CONNECT PROJECT ID
// // // // // // // // // // // // // // // // // // // //

const LOCAL_WALLET_CONNECT_PROJECT_ID = process
  .env.REACT_APP_LOCAL_WALLET_CONNECT_PROJECT_ID
  ? process.env
      .REACT_APP_LOCAL_WALLET_CONNECT_PROJECT_ID
  : "";
const DEV_WALLET_CONNECT_PROJECT_ID = process.env
  .REACT_APP_DEV_WALLET_CONNECT_PROJECT_ID
  ? process.env
      .REACT_APP_DEV_WALLET_CONNECT_PROJECT_ID
  : "";
const PROD_WALLET_CONNECT_PROJECT_ID = process.env
  .REACT_APP_PROD_WALLET_CONNECT_PROJECT_ID
  ? process.env
      .REACT_APP_PROD_WALLET_CONNECT_PROJECT_ID
  : "";

export const WALLET_CONNECT_PROJECT_ID = IS_PROD
  ? PROD_WALLET_CONNECT_PROJECT_ID
  : IS_DEV
  ? DEV_WALLET_CONNECT_PROJECT_ID
  : LOCAL_WALLET_CONNECT_PROJECT_ID;

// // // // // // // // // // // // // // // // // // // //
// ADDRESSES
// // // // // // // // // // // // // // // // // // // //

// Contract address
export const SYSTEM_ADDRS = IS_PROD
  ? {
      core: {
        // Base addresses
        starterPack: process.env
          .REACT_APP_PROD_ADDR_BASE_STARTER_PACK
          ? process.env
              .REACT_APP_PROD_ADDR_BASE_STARTER_PACK
          : "",

        // Core addresses
        accountRegistry: process.env
          .REACT_APP_PROD_ADDR_CORE_ACCOUNT_REGISTRY
          ? process.env
              .REACT_APP_PROD_ADDR_CORE_ACCOUNT_REGISTRY
          : "",
        gameEngine: process.env
          .REACT_APP_PROD_ADDR_CORE_GAME_ENGINE
          ? process.env
              .REACT_APP_PROD_ADDR_CORE_GAME_ENGINE
          : "",

        // Game addresses
        plot: process.env
          .REACT_APP_PROD_ADDR_GAME_PLOT
          ? process.env
              .REACT_APP_PROD_ADDR_GAME_PLOT
          : "",
        plotActions: process.env
          .REACT_APP_PROD_ADDR_GAME_PLOT_ACTIONS
          ? process.env
              .REACT_APP_PROD_ADDR_GAME_PLOT_ACTIONS
          : "",

        // Gold
        gold: process.env
          .REACT_APP_PROD_ADDR_GAME_GOLD
          ? process.env
              .REACT_APP_PROD_ADDR_GAME_GOLD
          : "",
      },
      // Crop addresses
      crops: {
        // Bean Garden
        blackBean: process.env
          .REACT_APP_PROD_ADDR_CROP_VOIDPOP
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_VOIDPOP
          : "",
        chickpea: process.env
          .REACT_APP_PROD_ADDR_CROP_PASTEROID
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_PASTEROID
          : "",
        greenPea: process.env
          .REACT_APP_PROD_ADDR_CROP_PLANETOID
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_PLANETOID
          : "",
        kidneyBean: process.env
          .REACT_APP_PROD_ADDR_CROP_MARSPOP
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_MARSPOP
          : "",
        pintoBean: process.env
          .REACT_APP_PROD_ADDR_CROP_CRATERPOP
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_CRATERPOP
          : "",

        // Berry Bush
        blackberry: process.env
          .REACT_APP_PROD_ADDR_CROP_VOIDBERRY
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_VOIDBERRY
          : "",
        blueberry: process.env
          .REACT_APP_PROD_ADDR_CROP_SKYBERRY
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_SKYBERRY
          : "",
        gojiBerry: process.env
          .REACT_APP_PROD_ADDR_CROP_DWARFBERRY
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_DWARFBERRY
          : "",
        raspberry: process.env
          .REACT_APP_PROD_ADDR_CROP_GLOWBERRY
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_GLOWBERRY
          : "",
        strawberry: process.env
          .REACT_APP_PROD_ADDR_CROP_STARBERRY
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_STARBERRY
          : "",

        // Flower Bed
        daisy: process.env
          .REACT_APP_PROD_ADDR_CROP_CLOUDBLOOM
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_CLOUDBLOOM
          : "",
        hop: process.env
          .REACT_APP_PROD_ADDR_CROP_VINEBLOOM
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_VINEBLOOM
          : "",
        lily: process.env
          .REACT_APP_PROD_ADDR_CROP_NOVABLOOM
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_NOVABLOOM
          : "",
        rose: process.env
          .REACT_APP_PROD_ADDR_CROP_GALAXYBLOOM
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_GALAXYBLOOM
          : "",
        sunflower: process.env
          .REACT_APP_PROD_ADDR_CROP_SOLARBLOOM
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_SOLARBLOOM
          : "",

        // Fruit Vine
        cantaloupe: process.env
          .REACT_APP_PROD_ADDR_CROP_MOONFRUIT
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_MOONFRUIT
          : "",
        grape: process.env
          .REACT_APP_PROD_ADDR_CROP_CLUSTERBERRY
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_CLUSTERBERRY
          : "",
        pumpkin: process.env
          .REACT_APP_PROD_ADDR_CROP_MARSQUASH
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_MARSQUASH
          : "",
        tomato: process.env
          .REACT_APP_PROD_ADDR_CROP_SUNBURST
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_SUNBURST
          : "",
        watermelon: process.env
          .REACT_APP_PROD_ADDR_CROP_EARTHMELON
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_EARTHMELON
          : "",

        // Grain Field
        corn: process.env
          .REACT_APP_PROD_ADDR_CROP_CAPRICORN
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_CAPRICORN
          : "",
        oat: process.env
          .REACT_APP_PROD_ADDR_CROP_COSMOAT
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_COSMOAT
          : "",
        wheat: process.env
          .REACT_APP_PROD_ADDR_CROP_SUNGRASS
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_SUNGRASS
          : "",
        wildRice: process.env
          .REACT_APP_PROD_ADDR_CROP_MOONGRASS
          ? process.env
              .REACT_APP_PROD_ADDR_CROP_MOONGRASS
          : "",
      },
    }
  : IS_DEV
  ? {
      core: {
        // Base addresses
        starterPack: process.env
          .REACT_APP_DEV_ADDR_BASE_STARTER_PACK
          ? process.env
              .REACT_APP_DEV_ADDR_BASE_STARTER_PACK
          : "",

        // Core addresses
        accountRegistry: process.env
          .REACT_APP_DEV_ADDR_CORE_ACCOUNT_REGISTRY
          ? process.env
              .REACT_APP_DEV_ADDR_CORE_ACCOUNT_REGISTRY
          : "",
        gameEngine: process.env
          .REACT_APP_DEV_ADDR_CORE_GAME_ENGINE
          ? process.env
              .REACT_APP_DEV_ADDR_CORE_GAME_ENGINE
          : "",

        // Game addresses
        plot: process.env
          .REACT_APP_DEV_ADDR_GAME_PLOT
          ? process.env
              .REACT_APP_DEV_ADDR_GAME_PLOT
          : "",
        plotActions: process.env
          .REACT_APP_DEV_ADDR_GAME_PLOT_ACTIONS
          ? process.env
              .REACT_APP_DEV_ADDR_GAME_PLOT_ACTIONS
          : "",

        // Gold
        gold: process.env
          .REACT_APP_DEV_ADDR_GAME_GOLD
          ? process.env
              .REACT_APP_DEV_ADDR_GAME_GOLD
          : "",
      },
      // Crop addresses
      crops: {
        // Bean Garden
        blackBean: process.env
          .REACT_APP_DEV_ADDR_CROP_VOIDPOP
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_VOIDPOP
          : "",
        chickpea: process.env
          .REACT_APP_DEV_ADDR_CROP_PASTEROID
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_PASTEROID
          : "",
        greenPea: process.env
          .REACT_APP_DEV_ADDR_CROP_PLANETOID
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_PLANETOID
          : "",
        kidneyBean: process.env
          .REACT_APP_DEV_ADDR_CROP_MARSPOP
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_MARSPOP
          : "",
        pintoBean: process.env
          .REACT_APP_DEV_ADDR_CROP_CRATERPOP
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_CRATERPOP
          : "",

        // Berry Bush
        blackberry: process.env
          .REACT_APP_DEV_ADDR_CROP_VOIDBERRY
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_VOIDBERRY
          : "",
        blueberry: process.env
          .REACT_APP_DEV_ADDR_CROP_SKYBERRY
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_SKYBERRY
          : "",
        gojiBerry: process.env
          .REACT_APP_DEV_ADDR_CROP_DWARFBERRY
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_DWARFBERRY
          : "",
        raspberry: process.env
          .REACT_APP_DEV_ADDR_CROP_GLOWBERRY
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_GLOWBERRY
          : "",
        strawberry: process.env
          .REACT_APP_DEV_ADDR_CROP_STARBERRY
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_STARBERRY
          : "",

        // Flower Bed
        daisy: process.env
          .REACT_APP_DEV_ADDR_CROP_CLOUDBLOOM
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_CLOUDBLOOM
          : "",
        hop: process.env
          .REACT_APP_DEV_ADDR_CROP_VINEBLOOM
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_VINEBLOOM
          : "",
        lily: process.env
          .REACT_APP_DEV_ADDR_CROP_NOVABLOOM
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_NOVABLOOM
          : "",
        rose: process.env
          .REACT_APP_DEV_ADDR_CROP_GALAXYBLOOM
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_GALAXYBLOOM
          : "",
        sunflower: process.env
          .REACT_APP_DEV_ADDR_CROP_SOLARBLOOM
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_SOLARBLOOM
          : "",

        // Fruit Vine
        cantaloupe: process.env
          .REACT_APP_DEV_ADDR_CROP_MOONFRUIT
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_MOONFRUIT
          : "",
        grape: process.env
          .REACT_APP_DEV_ADDR_CROP_CLUSTERBERRY
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_CLUSTERBERRY
          : "",
        pumpkin: process.env
          .REACT_APP_DEV_ADDR_CROP_MARSQUASH
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_MARSQUASH
          : "",
        tomato: process.env
          .REACT_APP_DEV_ADDR_CROP_SUNBURST
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_SUNBURST
          : "",
        watermelon: process.env
          .REACT_APP_DEV_ADDR_CROP_EARTHMELON
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_EARTHMELON
          : "",

        // Grain Field
        corn: process.env
          .REACT_APP_DEV_ADDR_CROP_CAPRICORN
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_CAPRICORN
          : "",
        oat: process.env
          .REACT_APP_DEV_ADDR_CROP_COSMOAT
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_COSMOAT
          : "",
        wheat: process.env
          .REACT_APP_DEV_ADDR_CROP_SUNGRASS
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_SUNGRASS
          : "",
        wildRice: process.env
          .REACT_APP_DEV_ADDR_CROP_MOONGRASS
          ? process.env
              .REACT_APP_DEV_ADDR_CROP_MOONGRASS
          : "",
      },
    }
  : {
      core: {
        // Base addresses
        starterPack: process.env
          .REACT_APP_LOCAL_ADDR_BASE_STARTER_PACK
          ? process.env
              .REACT_APP_LOCAL_ADDR_BASE_STARTER_PACK
          : "",

        // Core addresses
        accountRegistry: process.env
          .REACT_APP_LOCAL_ADDR_CORE_ACCOUNT_REGISTRY
          ? process.env
              .REACT_APP_LOCAL_ADDR_CORE_ACCOUNT_REGISTRY
          : "",
        gameEngine: process.env
          .REACT_APP_LOCAL_ADDR_CORE_GAME_ENGINE
          ? process.env
              .REACT_APP_LOCAL_ADDR_CORE_GAME_ENGINE
          : "",

        // Game addresses
        plot: process.env
          .REACT_APP_LOCAL_ADDR_GAME_PLOT
          ? process.env
              .REACT_APP_LOCAL_ADDR_GAME_PLOT
          : "",
        plotActions: process.env
          .REACT_APP_LOCAL_ADDR_GAME_PLOT_ACTIONS
          ? process.env
              .REACT_APP_LOCAL_ADDR_GAME_PLOT_ACTIONS
          : "",

        // Gold
        gold: process.env
          .REACT_APP_LOCAL_ADDR_GAME_GOLD
          ? process.env
              .REACT_APP_LOCAL_ADDR_GAME_GOLD
          : "",
      },
      // Crop addresses
      crops: {
        // Bean Garden
        blackBean: process.env
          .REACT_APP_LOCAL_ADDR_CROP_VOIDPOP
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_VOIDPOP
          : "",
        chickpea: process.env
          .REACT_APP_LOCAL_ADDR_CROP_PASTEROID
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_PASTEROID
          : "",
        greenPea: process.env
          .REACT_APP_LOCAL_ADDR_CROP_PLANETOID
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_PLANETOID
          : "",
        kidneyBean: process.env
          .REACT_APP_LOCAL_ADDR_CROP_MARSPOP
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_MARSPOP
          : "",
        pintoBean: process.env
          .REACT_APP_LOCAL_ADDR_CROP_CRATERPOP
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_CRATERPOP
          : "",

        // Berry Bush
        blackberry: process.env
          .REACT_APP_LOCAL_ADDR_CROP_VOIDBERRY
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_VOIDBERRY
          : "",
        blueberry: process.env
          .REACT_APP_LOCAL_ADDR_CROP_SKYBERRY
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_SKYBERRY
          : "",
        gojiBerry: process.env
          .REACT_APP_LOCAL_ADDR_CROP_DWARFBERRY
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_DWARFBERRY
          : "",
        raspberry: process.env
          .REACT_APP_LOCAL_ADDR_CROP_GLOWBERRY
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_GLOWBERRY
          : "",
        strawberry: process.env
          .REACT_APP_LOCAL_ADDR_CROP_STARBERRY
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_STARBERRY
          : "",

        // Flower Bed
        daisy: process.env
          .REACT_APP_LOCAL_ADDR_CROP_CLOUDBLOOM
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_CLOUDBLOOM
          : "",
        hop: process.env
          .REACT_APP_LOCAL_ADDR_CROP_VINEBLOOM
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_VINEBLOOM
          : "",
        lily: process.env
          .REACT_APP_LOCAL_ADDR_CROP_NOVABLOOM
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_NOVABLOOM
          : "",
        rose: process.env
          .REACT_APP_LOCAL_ADDR_CROP_GALAXYBLOOM
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_GALAXYBLOOM
          : "",
        sunflower: process.env
          .REACT_APP_LOCAL_ADDR_CROP_SOLARBLOOM
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_SOLARBLOOM
          : "",

        // Fruit Vine
        cantaloupe: process.env
          .REACT_APP_LOCAL_ADDR_CROP_MOONFRUIT
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_MOONFRUIT
          : "",
        grape: process.env
          .REACT_APP_LOCAL_ADDR_CROP_CLUSTERBERRY
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_CLUSTERBERRY
          : "",
        pumpkin: process.env
          .REACT_APP_LOCAL_ADDR_CROP_MARSQUASH
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_MARSQUASH
          : "",
        tomato: process.env
          .REACT_APP_LOCAL_ADDR_CROP_SUNBURST
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_SUNBURST
          : "",
        watermelon: process.env
          .REACT_APP_LOCAL_ADDR_CROP_EARTHMELON
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_EARTHMELON
          : "",

        // Grain Field
        corn: process.env
          .REACT_APP_LOCAL_ADDR_CROP_CAPRICORN
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_CAPRICORN
          : "",
        oat: process.env
          .REACT_APP_LOCAL_ADDR_CROP_COSMOAT
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_COSMOAT
          : "",
        wheat: process.env
          .REACT_APP_LOCAL_ADDR_CROP_SUNGRASS
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_SUNGRASS
          : "",
        wildRice: process.env
          .REACT_APP_LOCAL_ADDR_CROP_MOONGRASS
          ? process.env
              .REACT_APP_LOCAL_ADDR_CROP_MOONGRASS
          : "",
      },
    };
