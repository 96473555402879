import { GrainFieldPlantNameType } from "./GrainField.naming";

import PlantCorn from "./PlantCorn";
import PlantOat from "./PlantOat";
import PlantWildRice from "./PlantWildRice";
import PlantWheat from "./PlantWheat";

export const GrainFieldMapping = {
  [GrainFieldPlantNameType.corn]: PlantCorn,
  [GrainFieldPlantNameType.oat]: PlantOat,
  [GrainFieldPlantNameType.wheat]: PlantWheat,
  [GrainFieldPlantNameType.wildRice]: PlantWildRice,
};
