import iconKidneyBean from "../../../assets/crops/bean_garden/kidney_bean.png";

import tileKidneyBeanEarly from "../../../assets/tiles/plants/bean_garden/tile-kidney_bean-1.png";
import tileKidneyBeanFull from "../../../assets/tiles/plants/bean_garden/tile-kidney_bean-2.png";
import tileKidneyBeanDead from "../../../assets/tiles/plants/bean_garden/tile-kidney_bean-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  BeanGardenPlantNameType,
  BeanGardenPlantDisplayNameType,
  BeanGardenPlantBlockchainNameType,
} from "./BeanGarden.naming";

const PlantKidneyBean: IPlantType = {
  name: BeanGardenPlantNameType.kidneyBean,
  blockchainName: BeanGardenPlantBlockchainNameType.kidneyBean,
  displayName: BeanGardenPlantDisplayNameType.kidneyBean,
  plotType: PlantPlotType.beanGarden,
  icon: iconKidneyBean,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.darkRed,
    [PlantGrowthStage.sprout]: SproutType.leaf,
    [PlantGrowthStage.early]: tileKidneyBeanEarly,
    [PlantGrowthStage.full]: tileKidneyBeanFull,
    [PlantGrowthStage.dead]: tileKidneyBeanDead,
  },
};

export default PlantKidneyBean;
