export enum GrainFieldPlantNameType {
  corn = "corn",
  oat = "oat",
  wheat = "wheat",
  wildRice = "wildRice",
}

export enum GrainFieldPlantDisplayNameType {
  corn = "Corn",
  oat = "Oats",
  wheat = "Wheat",
  wildRice = "Wild rice",
}

export enum GrainFieldPlantBlockchainNameType {
  corn = "zCORN",
  oat = "zOAT",
  wheat = "zWHEAT",
  wildRice = "zWILDRICE",
}
