import BigNumber from "bignumber.js";
import { BaseContract, Contract, ethers } from "ethers";

import { IAccountRegistrationDetails } from "../accountRegistration";
import { BASE_RPC_URL, GOLD_RPC_URL, SYSTEM_ADDRS } from "../../env";
import { getSignedDataPayloadSafe, sendUserOp } from "../../modules/aa.module";

// Core contracts
import accountRegistryAbi from "./core/SugarcaneAccountRegistryV1.abi";
import { SugarcaneAccountRegistryV1 as AccountRegistry } from "./core/SugarcaneAccountRegistryV1";
import gameEngineAbi from "./core/GameEngine.abi";
import { GameEngine } from "./core/GameEngine";
import gnosisSafeL2Abi from "./core/GnosisSafeL2.abi";
import { GnosisSafeL2 } from "./core/GnosisSafeL2";

// Plot contracts
import plotAbi from "./game/Plot.abi";
import { Plot } from "./game/Plot";
import plotActionsAbi from "./game/PlotActions.abi";
import { PlotActions } from "./game/PlotActions";

// Game contracts
import starterPackAbi from "./game/StarterPack.abi";
import { CropXYZStarterPacks as StarterPack } from "./game/StarterPack";
import gameTokenAbi from "./game/GameToken.abi";
import { GameToken } from "./game/GameToken";

// Interfaces
import {
  IConnectedGoldAccount,
  IContractCall,
  IContractDetails,
  IContractFunctionDetails,
  IContractSetCore,
  ITokenContract,
} from "./contracts.interfaces";

const _getBaseProvider = () => new ethers.JsonRpcProvider(BASE_RPC_URL);
const _getGoldProvider = () => new ethers.JsonRpcProvider(GOLD_RPC_URL);

function _getContract<T>(
  address: string,
  abi: any[],
  injectSigner: ethers.JsonRpcSigner | null = null,
  isGoldProvider: boolean = true
) {
  const provider = !!injectSigner
    ? injectSigner
    : isGoldProvider
    ? _getGoldProvider()
    : _getBaseProvider();

  return new Contract(address, abi, provider) as unknown as T;
}

export const abis: any = {
  core: {
    // Core addresses
    accountRegistry: accountRegistryAbi,
    engine: gameEngineAbi,

    // Plot addresses
    plot: plotAbi,
    plotActions: plotActionsAbi,
  },

  pack: starterPackAbi,
  token: gameTokenAbi,
};

// // // // // // // // // // // // // // // // // // // //
// FUNCTIONS
// // // // // // // // // // // // // // // // // // // //

export const getContractSet = (
  injectSigner: ethers.JsonRpcSigner | null = null
): IContractSetCore => {
  return {
    // Asset addresses
    gold: {
      address: SYSTEM_ADDRS.core.gold,
      contract: _getContract<GameToken>(
        SYSTEM_ADDRS.core.gold,
        gameTokenAbi,
        injectSigner
      ),
    },

    // Core addresses
    accountRegistry: {
      address: SYSTEM_ADDRS.core.accountRegistry,
      contract: _getContract<AccountRegistry>(
        SYSTEM_ADDRS.core.accountRegistry,
        accountRegistryAbi,
        injectSigner
      ),
    },

    gameEngine: {
      address: SYSTEM_ADDRS.core.gameEngine,
      contract: _getContract<GameEngine>(
        SYSTEM_ADDRS.core.gameEngine,
        gameEngineAbi,
        injectSigner
      ),
    },

    // Plot addresses
    plot: {
      address: SYSTEM_ADDRS.core.plot,
      contract: _getContract<Plot>(
        SYSTEM_ADDRS.core.plot,
        plotAbi,
        injectSigner
      ),
    },
    plotActions: {
      address: SYSTEM_ADDRS.core.plotActions,
      contract: _getContract<PlotActions>(
        SYSTEM_ADDRS.core.plotActions,
        plotActionsAbi,
        injectSigner
      ),
    },

    starterPack: {
      address: SYSTEM_ADDRS.core.starterPack,
      contract: _getContract<StarterPack>(
        SYSTEM_ADDRS.core.starterPack,
        starterPackAbi,
        injectSigner,
        false
      ),
    },
  };
};

export const getCropContract = (
  cropName: string,
  injectSigner: ethers.JsonRpcSigner | null = null
): ITokenContract => {
  return {
    address: (SYSTEM_ADDRS.crops as any)[cropName],
    contract: _getContract<GameToken>(
      (SYSTEM_ADDRS.crops as any)[cropName],
      gameTokenAbi,
      injectSigner
    ),
  };
};

export const getSafeContract = (
  safeAddress: string,
  injectSigner: ethers.JsonRpcSigner | null = null
): { address: string; contract: GnosisSafeL2 } => {
  return {
    address: safeAddress,
    contract: _getContract<GnosisSafeL2>(
      safeAddress,
      gnosisSafeL2Abi,
      injectSigner
    ),
  };
};

/**
 *
 * @param contract on chain contract
 * @param contractMethod on chain contract
 *
 */
export function readContractCall<T>(
  contractDetails: IContractDetails,
  contractMethod: keyof T,
  args: any[]
) {
  return (contractDetails.contract as any)[contractMethod](...args);
}

/**
 *
 * @param contract on chain contract
 * @param contractMethod on chain contract
 *
 */
export function encodeContractCall<T>(
  contractDetails: IContractDetails,
  contractMethod: keyof T,
  args: any[]
): string {
  return (
    contractDetails.contract as BaseContract
  ).interface.encodeFunctionData(contractMethod as string, args);
}

const _getAssetStorageNonce = async (
  accountDetails: IAccountRegistrationDetails,
  injectSigner: ethers.JsonRpcSigner | null = null
) => {
  const accountRegistryContract: IContractDetails = {
    address: SYSTEM_ADDRS.core.accountRegistry,
    contract: _getContract<AccountRegistry>(
      SYSTEM_ADDRS.core.accountRegistry,
      accountRegistryAbi,
      injectSigner
    ),
  };

  // Get the Transaction nonce of this Safe wallet.
  return await readContractCall<AccountRegistry>(
    // contractDetails: IContractDetails,
    accountRegistryContract,
    // contractMethod: keyof T,
    "assetStorageSlotNonceWithAddress",
    // args: any[]
    [
      // bytes32 sugarcaneId,
      accountDetails.onchainId,
      // address assetStorageSlot
      accountDetails.assetStorageSlots[0],
    ]
  );
};

interface ICallDetails<T> {
  contractDetails: IContractDetails;
  contractMethod: keyof T;
  args: any[];
}
/**
 * @param accountDetails the logged in Privy details
 * @param callDetails the set of transactions that are to be made
 * @param connectedGoldAccount the user's Gold account
 * @param paddingNonce addtional nonce used when multiple actions are requested one after the other
 */
export async function executeContractCall<T>({
  accountDetails,
  callDetails,
  connectedGoldAccount,
  paddingNonce = 0,
}: {
  accountDetails: IAccountRegistrationDetails;
  callDetails: ICallDetails<T>[];
  connectedGoldAccount: IConnectedGoldAccount;
  paddingNonce?: number;
}): Promise<string> {
  const nonceBase = await _getAssetStorageNonce(accountDetails);

  const contractCallListing: IContractCall[] = callDetails.map(
    (
      { contractDetails: baseContract, contractMethod, args },
      contractCallIndex
    ) => {
      const dataPayload = encodeContractCall(
        baseContract,
        contractMethod,
        args
      );
      const functionDetails: IContractFunctionDetails = {
        // Making a transaction on a SAFE so "execTransaction" is the function
        name: "execTransaction",
        dataPayload: getSignedDataPayloadSafe({
          dataPayload,
          nonceBase: BigNumber(nonceBase).toString(),
          nonceAdditional: paddingNonce + contractCallIndex,
          toAddress: baseContract.address,
          verifyingContract: accountDetails.assetStorageSlots[0],
        }),
        paramsOrder: [
          "to",
          "value",
          "data",
          "operation",
          "safeTxGas",
          "baseGas",
          "gasPrice",
          "gasToken",
          "refundReceiver",
        ],
      };

      return {
        functionDetails,
        targetContract: getSafeContract(accountDetails.assetStorageSlots[0]),
      };
    }
  );

  return await sendUserOp({
    additionalNonce: paddingNonce,
    connectedGoldAccount,
    contractCallListing,
  });
}
