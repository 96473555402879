import iconPumpkin from "../../../assets/crops/fruit_vine/pumpkin.png";

import tilePumpkinEarly from "../../../assets/tiles/plants/fruit_vine/tile-pumpkin-1.png";
import tilePumpkinFull from "../../../assets/tiles/plants/fruit_vine/tile-pumpkin-2.png";
import tilePumpkinDead from "../../../assets/tiles/plants/fruit_vine/tile-pumpkin-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  FruitVinePlantNameType,
  FruitVinePlantDisplayNameType,
  FruitVinePlantBlockchainNameType,
} from "./FruitVine.naming";

const PlantPumpkin: IPlantType = {
  name: FruitVinePlantNameType.pumpkin,
  blockchainName: FruitVinePlantBlockchainNameType.pumpkin,
  displayName: FruitVinePlantDisplayNameType.pumpkin,
  plotType: PlantPlotType.fruitVine,
  icon: iconPumpkin,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.orange,
    [PlantGrowthStage.sprout]: SproutType.vineGround,
    [PlantGrowthStage.early]: tilePumpkinEarly,
    [PlantGrowthStage.full]: tilePumpkinFull,
    [PlantGrowthStage.dead]: tilePumpkinDead,
  },
};

export default PlantPumpkin;
