import iconRaspberry from "../../../assets/crops/berry_bush/raspberry.png";

import tileRaspberryEarly from "../../../assets/tiles/plants/berry_bush/tile-raspberry-1.png";
import tileRaspberryFull from "../../../assets/tiles/plants/berry_bush/tile-raspberry-2.png";
import tileRaspberryDead from "../../../assets/tiles/plants/berry_bush/tile-raspberry-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  BerryBushPlantNameType,
  BerryBushPlantDisplayNameType,
  BerryBushPlantBlockchainNameType,
} from "./BerryBush.naming";

const PlantRaspberry: IPlantType = {
  name: BerryBushPlantNameType.raspberry,
  blockchainName: BerryBushPlantBlockchainNameType.raspberry,
  displayName: BerryBushPlantDisplayNameType.raspberry,
  plotType: PlantPlotType.berryBush,
  icon: iconRaspberry,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.purple,
    [PlantGrowthStage.sprout]: SproutType.bush,
    [PlantGrowthStage.early]: tileRaspberryEarly,
    [PlantGrowthStage.full]: tileRaspberryFull,
    [PlantGrowthStage.dead]: tileRaspberryDead,
  },
};

export default PlantRaspberry;
