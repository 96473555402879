import { getDimensions } from "../../utils/constants";
import { SeedAnimationMap, SeedColor, SeedImageMap } from "../../utils/Seed";

import { ConstructedTile } from "./BaseTile";

interface ISeedTileProp {
  isLifted: boolean;
  isLoading: boolean;
  seedColor: SeedColor;
  height?: number;
  width?: number;
}

function SeedTile({
  isLifted,
  isLoading,
  seedColor,
  height = getDimensions().plotSizeHeight,
  width = getDimensions().plotSizeWidth,
}: ISeedTileProp) {
  return (
    <ConstructedTile
      isLifted={isLifted}
      imageSource={
        isLoading ? SeedAnimationMap[seedColor] : SeedImageMap[seedColor]
      }
      height={height}
      width={width}
    />
  );
}

export default SeedTile;
