export enum FlowerBedPlantNameType {
  daisy = "daisy",
  hop = "hop",
  lily = "lily",
  rose = "rose",
  sunflower = "sunflower",
}

export enum FlowerBedPlantDisplayNameType {
  daisy = "Daisy",
  hop = "Hops",
  lily = "Lily",
  rose = "Roses",
  sunflower = "Sunflower",
}

export enum FlowerBedPlantBlockchainNameType {
  daisy = "zDAISY",
  hop = "zHOP",
  lily = "zLILY",
  rose = "zROSE",
  sunflower = "zSUNFLOWER",
}
