import iconCorn from "../../../assets/crops/grain_field/corn.png";

import tileCornEarly from "../../../assets/tiles/plants/grain_field/tile-corn-1.png";
import tileCornFull from "../../../assets/tiles/plants/grain_field/tile-corn-2.png";
import tileCornDead from "../../../assets/tiles/plants/grain_field/tile-corn-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  GrainFieldPlantNameType,
  GrainFieldPlantDisplayNameType,
  GrainFieldPlantBlockchainNameType,
} from "./GrainField.naming";

const PlantCorn: IPlantType = {
  name: GrainFieldPlantNameType.corn,
  blockchainName: GrainFieldPlantBlockchainNameType.corn,
  displayName: GrainFieldPlantDisplayNameType.corn,
  plotType: PlantPlotType.grainField,
  icon: iconCorn,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.tan,
    [PlantGrowthStage.sprout]: SproutType.grain,
    [PlantGrowthStage.early]: tileCornEarly,
    [PlantGrowthStage.full]: tileCornFull,
    [PlantGrowthStage.dead]: tileCornDead,
  },
};

export default PlantCorn;
