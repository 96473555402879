import plot_5_5 from "../../assets/hud/packs/plots/filled/plot-filled-5x5.svg";

import { IPlotSizeDetails, PlotSizeType } from "../PlantPlot.interfaces";

const PlotSize5x5: IPlotSizeDetails = {
  name: PlotSizeType.size5x5,
  image: plot_5_5,
};

export default PlotSize5x5;
