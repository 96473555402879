import Button from "@mui/material/Button";

import "./ButtonContainer.style.css";

import iconBackgroundImage from "../assets/hud/buttons/button-background.svg";
import bookIconImage from "../assets/hud/buttons/button-book.png";
import shopIconImage from "../assets/hud/buttons/button-seed_shop.png";
import storageIconImage from "../assets/hud/buttons/button-storage.png";
import packsIconImage from "../assets/hud/buttons/button-packs.png";

export interface IButtonContainerProp {
  handleOpenShop: () => void;
  handleOpenMyStore: () => void;
  handleOpenPacks: () => void;
}

function ButtonContainer({
  handleOpenShop,
  handleOpenMyStore,
  handleOpenPacks,
}: IButtonContainerProp) {
  return (
    <div className="SidebarButtonContainer">
      <div className="SidebarButtonContainer-shell">
        <Button className="SidebarButton" onClick={handleOpenShop}>
          <img
            className="SidebarButton-background_image"
            src={iconBackgroundImage}
            alt="Shop"
          />
          <img
            className="SidebarButton-image SidebarButton-shop_image"
            src={shopIconImage}
            alt="Shop"
          />
        </Button>
        <div className="SidebarButton-text">Buy</div>
      </div>

      <div className="SidebarButtonContainer-shell">
        <Button className="SidebarButton" onClick={handleOpenMyStore}>
          <img
            className="SidebarButton-background_image"
            src={iconBackgroundImage}
            alt="Storage"
          />
          <img
            className="SidebarButton-image SidebarButton-storage_image"
            src={storageIconImage}
            alt="Storage"
          />
        </Button>
        <div className="SidebarButton-text">Sell</div>
      </div>

      <div className="SidebarButtonContainer-shell">
        <Button className="SidebarButton" onClick={handleOpenPacks}>
          <img
            className="SidebarButton-background_image"
            src={iconBackgroundImage}
            alt="Packs"
          />
          <img
            className="SidebarButton-image SidebarButton-packs_image"
            src={packsIconImage}
            alt="Packs"
          />
        </Button>
        <div className="SidebarButton-text">Packs</div>
      </div>

      {/* <div className="SidebarButtonContainer-shell">
        <Button className="SidebarButton" onClick={() => {}} disabled>
          <img
            className="SidebarButton-background_image"
            src={iconBackgroundImage}
            alt="Shop"
          />
          <img
            className="SidebarButton-image SidebarButton-book_image"
            src={bookIconImage}
            alt="Shop"
          />
        </Button>
        <div className="SidebarButton-text">Crops</div>
      </div> */}
    </div>
  );
}

export default ButtonContainer;
