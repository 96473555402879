import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";

import { getContractSet, readContractCall } from "./contracts";
import { SugarcaneAccountRegistryV1 } from "./contracts/core/SugarcaneAccountRegistryV1";

export interface IAccountRegistrationDetails {
  onchainId: string | null; // bytes32
  registrationNumber: BigNumber | null; // uint256
  assetStorageSlots: string[]; // address[]
  isActive: boolean; // bool
  isRegistered: boolean; // bool
}

/**
 * A hook that pulls in the account registration details
 */
export const useAccountRegistration = (
  onchainId: string | null | undefined
) => {
  const [currentOnchainId, setCurrentOnchainId] = useState<string | null>(null);

  const { accountRegistry } = getContractSet();

  const [isLoadingAccountDetails, setIsLoadingAccountDetails] = useState(true);

  const [accountDetails, setAccountDetails] =
    useState<IAccountRegistrationDetails | null>(null);

  const refreshAccountDetails = async (isForce = true) => {
    setIsLoadingAccountDetails(true);
    if (!!onchainId && (isForce || onchainId !== currentOnchainId)) {
      setCurrentOnchainId(onchainId);
      const returnedAccountDetails =
        await readContractCall<SugarcaneAccountRegistryV1>(
          accountRegistry,
          "accountDetails",
          [onchainId]
        );

      const cleanedAccountDetails: IAccountRegistrationDetails = {
        onchainId: !!returnedAccountDetails.isRegistered
          ? returnedAccountDetails.sugarcaneId
          : null, // bytes32
        registrationNumber: !!returnedAccountDetails.isRegistered
          ? BigNumber(returnedAccountDetails.registrationNumber.toString())
          : null, // uint256
        assetStorageSlots: !!returnedAccountDetails.isRegistered
          ? returnedAccountDetails.assetStorageSlots.map(
              (assetStorageSlots: string) => String(assetStorageSlots)
            )
          : [], // address[]
        isActive: returnedAccountDetails.isActive, // bool
        isRegistered: returnedAccountDetails.isRegistered, // bool
      };

      setAccountDetails(cleanedAccountDetails);
    } else {
      setCurrentOnchainId(null);
      setAccountDetails(null);
    }
    setIsLoadingAccountDetails(false);
  };

  useEffect(() => {
    refreshAccountDetails(false);
  }, [onchainId]);

  return {
    isLoadingAccountDetails,
    accountDetails,
    refreshAccountDetails,
  };
};
