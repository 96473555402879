import { styled } from "@mui/system";

import { COLORS } from "../../utils/constants";

export const IconDismissImage = styled("img")(
  ({ theme }) => `
    width: 15px;
  `
);

export const IconAlertImage = styled("img")(
  ({ theme }) => `
    width: 50px;
  `
);

const classesTooltip = {
  alertDismissButton: (theme) => ({
    minWidth: "50px",
    minHeight: "50px",
    borderRadius: "15px 0",
  }),
  alertText: (theme) => ({
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: "5px",
  }),
  shell: (theme) => ({
    minWidth: "300px",
    width: "300px",
    height: "54px",
    border: `2px solid ${COLORS.lightGray.base}`,
    padding: "0 0 0 10px",
    borderRadius: "15px 0",
    color: COLORS.text.green,
    backgroundColor: COLORS.lightGray.opacity,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  coreContent: (theme) => ({
    display: "flex",
    alignItems: "center",
  }),
};

export default classesTooltip;
