import iconSunflower from "../../../assets/crops/flower_bed/sunflower.png";

import tileSunflowerEarly from "../../../assets/tiles/plants/flower_bed/tile-sunflower-1.png";
import tileSunflowerFull from "../../../assets/tiles/plants/flower_bed/tile-sunflower-2.png";
import tileSunflowerDead from "../../../assets/tiles/plants/flower_bed/tile-sunflower-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  FlowerBedPlantNameType,
  FlowerBedPlantDisplayNameType,
  FlowerBedPlantBlockchainNameType,
} from "./FlowerBed.naming";

const PlantSunflower: IPlantType = {
  name: FlowerBedPlantNameType.sunflower,
  blockchainName: FlowerBedPlantBlockchainNameType.sunflower,
  displayName: FlowerBedPlantDisplayNameType.sunflower,
  plotType: PlantPlotType.flowerBed,
  icon: iconSunflower,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.black,
    [PlantGrowthStage.sprout]: SproutType.leaf,
    [PlantGrowthStage.early]: tileSunflowerEarly,
    [PlantGrowthStage.full]: tileSunflowerFull,
    [PlantGrowthStage.dead]: tileSunflowerDead,
  },
};

export default PlantSunflower;
