import BigNumber from "bignumber.js";
import { useEffect, useRef, useState } from "react";

import { IPlantType } from "./PlantPlot.interfaces";
import { formatDecimal } from "./format.util";

export const numberWithCommas = (x: BigNumber | null) => {
  if (!x) {
    return "-";
  }

  return formatDecimal(x, {
    decimalsToDisplay: 0,
    // decimalsOfInputPadding: 2,
  });
};

export const goldNumberWithCommas = (x: BigNumber | null) => {
  if (!x) {
    return "-";
  }

  return formatDecimal(x, {
    decimalsToDisplay: 2,
    decimalsOfInputPadding: 2,
  });
};

export const addressStringReduction = (str: any) => {
  let firstChunk = str.substring(0, 5);
  let lastChunk = str.substring(str.length - 4);

  return `${firstChunk}...${lastChunk}`;
};

export const calculateSellPrice = (
  tokenSellAmount: number,
  goldBalance: string,
  tokenBalance: string
) => {
  const initialGoldBalance = BigNumber(goldBalance);
  const initialTokenBalance = BigNumber(tokenBalance);

  // K = initialGoldBalance * initialTokenBalance
  const K_val = initialGoldBalance.times(initialTokenBalance);

  // updatedTokenBalance = initialTokenBalance + tokenSellAmount
  const updatedTokenBalance = initialTokenBalance.plus(tokenSellAmount);

  // updatedGoldBalance = K / updatedTokenBalance
  const updatedGoldBalance = K_val.div(updatedTokenBalance).integerValue(
    BigNumber.ROUND_FLOOR
  );

  // goldOutPrice = initialGoldBalance - updatedGoldBalance
  const goldOutPrice = initialGoldBalance.minus(updatedGoldBalance);

  // Set 2.5% discount on calculated cost just in case price moves.
  return goldOutPrice.times(985).div(1000).integerValue(BigNumber.ROUND_FLOOR);
};

export const calculateBuyPrice = (
  tokenBuyAmount: number,
  goldBalance: string,
  tokenBalance: string
) => {
  const initialGoldBalance = BigNumber(goldBalance);
  const initialTokenBalance = BigNumber(tokenBalance);

  // K = initialGoldBalance * initialTokenBalance
  const K_val = initialGoldBalance.times(initialTokenBalance);

  // updatedTokenBalance = initialTokenBalance - tokenBuyAmount
  const updatedTokenBalance = initialTokenBalance.minus(tokenBuyAmount);

  // updatedGoldBalance = K / updatedTokenBalance
  const updatedGoldBalance = K_val.div(updatedTokenBalance).integerValue(
    BigNumber.ROUND_FLOOR
  );

  // goldInPrice = updatedGoldBalance - initialGoldBalance
  const goldInPrice = updatedGoldBalance.minus(initialGoldBalance);

  // Set 2.5% increase on calculated cost just in case price moves.
  return goldInPrice.times(1025).div(1000).integerValue(BigNumber.ROUND_FLOOR);
};

export const getPoolObj = (poolBalances: any, cartItem: IPlantType) =>
  poolBalances[cartItem.name];

interface Time {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export const convertSecondsToTime = (secondsInput: number): Time => {
  const days = Math.floor(secondsInput / (3600 * 24));
  const hours = Math.floor((secondsInput % (3600 * 24)) / 3600);
  const minutes = Math.floor((secondsInput % 3600) / 60);
  const seconds = secondsInput % 60;

  return { days, hours, minutes, seconds };
};

interface IRenderTime {
  isCompressed: boolean;
}

export const renderTime = (
  { days, hours, minutes, seconds }: any,
  { isCompressed }: IRenderTime = { isCompressed: false }
) => {
  if (days > 0) {
    return (
      <div>
        {days > 0 ? (
          <span>
            {days}
            {isCompressed ? "d " : ` day${days !== 1 ? "s" : ""} `}
          </span>
        ) : null}
        {hours > 0 ? (
          <span>
            {hours}
            {isCompressed ? "h " : ` hour${hours !== 1 ? "s" : ""} `}
          </span>
        ) : null}
      </div>
    );
  } else if (hours > 0) {
    return (
      <div>
        {hours > 0 ? (
          <span>
            {hours}
            {isCompressed ? "h " : ` hour${hours !== 1 ? "s" : ""} `}
          </span>
        ) : null}
        {minutes > 0 ? (
          <span>
            {minutes}
            {isCompressed ? "m " : ` min${minutes !== 1 ? "s" : ""} `}
          </span>
        ) : null}
      </div>
    );
  }
  return (
    <div>
      {minutes > 0 ? (
        <span>
          {minutes}
          {isCompressed ? "m " : ` min${minutes !== 1 ? "s" : ""} `}
        </span>
      ) : null}
      {seconds > 0 ? (
        <span>
          {seconds}
          {isCompressed ? "s " : ` sec${seconds !== 1 ? "s" : ""} `}
        </span>
      ) : null}
    </div>
  );
};

export const useElementSize = () => {
  const ref = useRef<HTMLElement | HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const forceUpdateSize = () => {
    if (ref.current) {
      setSize({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", forceUpdateSize);
    forceUpdateSize(); // Initial size on mount

    return () => window.removeEventListener("resize", forceUpdateSize);
  }, []); // Empty dependency array ensures this runs once on mount

  return { forceUpdateSize, ref, ...size };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};
