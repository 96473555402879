import tileSeedBlack from "../assets/tiles/seeds/tile-seed-black.png";
import tileSeedDarkRed from "../assets/tiles/seeds/tile-seed-dark_red.png";
import tileSeedGreen from "../assets/tiles/seeds/tile-seed-green.png";
import tileSeedOrange from "../assets/tiles/seeds/tile-seed-orange.png";
import tileSeedPurple from "../assets/tiles/seeds/tile-seed-purple.png";
import tileSeedRed from "../assets/tiles/seeds/tile-seed-red.png";
import tileSeedTan from "../assets/tiles/seeds/tile-seed-tan.png";
import tileSeedYellow from "../assets/tiles/seeds/tile-seed-yellow.png";

import tileAnimateSeedBlack from "../assets/tiles/seeds/animation/tile-animate-black.gif";
import tileAnimateSeedDarkRed from "../assets/tiles/seeds/animation/tile-animate-dark_red.gif";
import tileAnimateSeedGreen from "../assets/tiles/seeds/animation/tile-animate-green.gif";
import tileAnimateSeedOrange from "../assets/tiles/seeds/animation/tile-animate-orange.gif";
import tileAnimateSeedPurple from "../assets/tiles/seeds/animation/tile-animate-purple.gif";
import tileAnimateSeedRed from "../assets/tiles/seeds/animation/tile-animate-red.gif";
import tileAnimateSeedTan from "../assets/tiles/seeds/animation/tile-animate-tan.gif";
import tileAnimateSeedYellow from "../assets/tiles/seeds/animation/tile-animate-yellow.gif";

export enum SeedColor {
  black = "black",
  darkRed = "darkRed",
  green = "green",
  orange = "orange",
  purple = "purple",
  red = "red",
  tan = "tan",
  yellow = "yellow",
}

export interface ISeedImageMap {
  [SeedColor.black]: string;
  [SeedColor.darkRed]: string;
  [SeedColor.green]: string;
  [SeedColor.orange]: string;
  [SeedColor.purple]: string;
  [SeedColor.red]: string;
  [SeedColor.tan]: string;
  [SeedColor.yellow]: string;
}

export const SeedImageMap: ISeedImageMap = {
  [SeedColor.black]: tileSeedBlack,
  [SeedColor.darkRed]: tileSeedDarkRed,
  [SeedColor.green]: tileSeedGreen,
  [SeedColor.orange]: tileSeedOrange,
  [SeedColor.purple]: tileSeedPurple,
  [SeedColor.red]: tileSeedRed,
  [SeedColor.tan]: tileSeedTan,
  [SeedColor.yellow]: tileSeedYellow,
};

export const SeedAnimationMap: ISeedImageMap = {
  [SeedColor.black]: tileAnimateSeedBlack,
  [SeedColor.darkRed]: tileAnimateSeedDarkRed,
  [SeedColor.green]: tileAnimateSeedGreen,
  [SeedColor.orange]: tileAnimateSeedOrange,
  [SeedColor.purple]: tileAnimateSeedPurple,
  [SeedColor.red]: tileAnimateSeedRed,
  [SeedColor.tan]: tileAnimateSeedTan,
  [SeedColor.yellow]: tileAnimateSeedYellow,
};
