import iconChickpea from "../../../assets/crops/bean_garden/chickpea.png";

import tileChickpeaEarly from "../../../assets/tiles/plants/bean_garden/tile-chickpea-1.png";
import tileChickpeaFull from "../../../assets/tiles/plants/bean_garden/tile-chickpea-2.png";
import tileChickpeaDead from "../../../assets/tiles/plants/bean_garden/tile-chickpea-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  BeanGardenPlantNameType,
  BeanGardenPlantDisplayNameType,
  BeanGardenPlantBlockchainNameType,
} from "./BeanGarden.naming";

const PlantChickpea: IPlantType = {
  name: BeanGardenPlantNameType.chickpea,
  blockchainName: BeanGardenPlantBlockchainNameType.chickpea,
  displayName: BeanGardenPlantDisplayNameType.chickpea,
  plotType: PlantPlotType.beanGarden,
  icon: iconChickpea,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.orange,
    [PlantGrowthStage.sprout]: SproutType.leaf,
    [PlantGrowthStage.early]: tileChickpeaEarly,
    [PlantGrowthStage.full]: tileChickpeaFull,
    [PlantGrowthStage.dead]: tileChickpeaDead,
  },
};

export default PlantChickpea;
