import { FruitVinePlantNameType } from "./FruitVine.naming";

import PlantCantaloupe from "./PlantCantaloupe";
import PlantGrape from "./PlantGrape";
import PlantPumpkin from "./PlantPumpkin";
import PlantTomato from "./PlantTomato";
import PlantWatermelon from "./PlantWatermelon";

export const FruitVineMapping = {
  [FruitVinePlantNameType.cantaloupe]: PlantCantaloupe,
  [FruitVinePlantNameType.grape]: PlantGrape,
  [FruitVinePlantNameType.pumpkin]: PlantPumpkin,
  [FruitVinePlantNameType.tomato]: PlantTomato,
  [FruitVinePlantNameType.watermelon]: PlantWatermelon,
};
