import iconStrawberry from "../../../assets/crops/berry_bush/strawberry.png";

import tileStrawberryEarly from "../../../assets/tiles/plants/berry_bush/tile-strawberry-1.png";
import tileStrawberryFull from "../../../assets/tiles/plants/berry_bush/tile-strawberry-2.png";
import tileStrawberryDead from "../../../assets/tiles/plants/berry_bush/tile-strawberry-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  BerryBushPlantNameType,
  BerryBushPlantDisplayNameType,
  BerryBushPlantBlockchainNameType,
} from "./BerryBush.naming";

const PlantStrawberry: IPlantType = {
  name: BerryBushPlantNameType.strawberry,
  blockchainName: BerryBushPlantBlockchainNameType.strawberry,
  displayName: BerryBushPlantDisplayNameType.strawberry,
  plotType: PlantPlotType.berryBush,
  icon: iconStrawberry,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.red,
    [PlantGrowthStage.sprout]: SproutType.bush,
    [PlantGrowthStage.early]: tileStrawberryEarly,
    [PlantGrowthStage.full]: tileStrawberryFull,
    [PlantGrowthStage.dead]: tileStrawberryDead,
  },
};

export default PlantStrawberry;
