import tileSproutBush from "../assets/tiles/sprouts/tile-sprout-bush.png";
import tileSproutGrain from "../assets/tiles/sprouts/tile-sprout-grain.png";
import tileSproutLeaf from "../assets/tiles/sprouts/tile-sprout-leaf.png";
import tileSproutVineGround from "../assets/tiles/sprouts/tile-sprout-vine_ground.png";
import tileSproutVineStake from "../assets/tiles/sprouts/tile-sprout-vine_stake.png";

export enum SproutType {
  bush = "bush",
  grain = "grain",
  leaf = "leaf",
  vineGround = "vineGround",
  vineStake = "vineStake",
}

export interface ISproutImageMap {
  [SproutType.bush]: string;
  [SproutType.grain]: string;
  [SproutType.leaf]: string;
  [SproutType.vineGround]: string;
  [SproutType.vineStake]: string;
}

export const SproutImageMap: ISproutImageMap = {
  [SproutType.bush]: tileSproutBush,
  [SproutType.grain]: tileSproutGrain,
  [SproutType.leaf]: tileSproutLeaf,
  [SproutType.vineGround]: tileSproutVineGround,
  [SproutType.vineStake]: tileSproutVineStake,
};
