import iconDaisy from "../../../assets/crops/flower_bed/daisy.png";

import tileDaisyEarly from "../../../assets/tiles/plants/flower_bed/tile-daisy-1.png";
import tileDaisyFull from "../../../assets/tiles/plants/flower_bed/tile-daisy-2.png";
import tileDaisyDead from "../../../assets/tiles/plants/flower_bed/tile-daisy-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  FlowerBedPlantNameType,
  FlowerBedPlantDisplayNameType,
  FlowerBedPlantBlockchainNameType,
} from "./FlowerBed.naming";

const PlantDaisy: IPlantType = {
  name: FlowerBedPlantNameType.daisy,
  blockchainName: FlowerBedPlantBlockchainNameType.daisy,
  displayName: FlowerBedPlantDisplayNameType.daisy,
  plotType: PlantPlotType.flowerBed,
  icon: iconDaisy,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.yellow,
    [PlantGrowthStage.sprout]: SproutType.leaf,
    [PlantGrowthStage.early]: tileDaisyEarly,
    [PlantGrowthStage.full]: tileDaisyFull,
    [PlantGrowthStage.dead]: tileDaisyDead,
  },
};

export default PlantDaisy;
