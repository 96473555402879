import React from "react";

import landBackground from "../assets/backgrounds/1.jpg";

import "./PlotMapContainer.style.css";

import Header from "./Header";

function MapBackgroundWrapper({ children }: { children: React.ReactElement }) {
  return (
    <div>
      <Header />

      <div className="Map" style={{ overflowY: "hidden" }}>
        <img
          className="Map-background"
          src={landBackground}
          style={{ height: "100%", minWidth: `100%` }}
          alt="Map"
        />
        <div className="NoPlots-container">{children}</div>
      </div>
    </div>
  );
}

export default MapBackgroundWrapper;
