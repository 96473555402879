import { PlantPlotType, PlotSizeType } from "./PlantPlot.interfaces";

import { BeanGardenMapping } from "./plants/bean_garden/BeanGarden.mapping";
import { BerryBushMapping } from "./plants/berry_bush/BerryBush.mapping";
import { FlowerBedMapping } from "./plants/flower_bed/FlowerBed.mapping";
import { FruitVineMapping } from "./plants/fruit_vine/FruitVine.mapping";
import { GrainFieldMapping } from "./plants/grain_field/GrainField.mapping";

import PlotSize1x1 from "./plots/PlotSize1x1";
import PlotSize2x2 from "./plots/PlotSize2x2";
import PlotSize3x3 from "./plots/PlotSize3x3";
import PlotSize4x4 from "./plots/PlotSize4x4";
import PlotSize5x5 from "./plots/PlotSize5x5";

export const PlantMapping = {
  ...BeanGardenMapping,
  ...BerryBushMapping,
  ...FlowerBedMapping,
  ...FruitVineMapping,
  ...GrainFieldMapping,
};

export const PlantPlotMapping = {
  [PlantPlotType.beanGarden]: BeanGardenMapping,
  [PlantPlotType.berryBush]: BerryBushMapping,
  [PlantPlotType.flowerBed]: FlowerBedMapping,
  [PlantPlotType.fruitVine]: FruitVineMapping,
  [PlantPlotType.grainField]: GrainFieldMapping,
};

export const PlotSizeMapping = {
  [PlotSizeType.size1x1]: PlotSize1x1,
  [PlotSizeType.size2x2]: PlotSize2x2,
  [PlotSizeType.size3x3]: PlotSize3x3,
  [PlotSizeType.size4x4]: PlotSize4x4,
  [PlotSizeType.size5x5]: PlotSize5x5,
};
