import "./BaseTile.style.css";

import tileBase from "../../assets/tiles/base/soil.png";
import tileBaseShadow from "../../assets/tiles/shadow/base-shadow.png";

import { getDimensions } from "../../utils/constants";

interface IBaseTileProp {
  isLifted: boolean;
  height: number;
  width: number;
}
export function BaseTile({
  isLifted,
  height = getDimensions().plotSizeHeight,
  width = getDimensions().plotSizeWidth,
}: IBaseTileProp) {
  return (
    <>
      <img
        alt="Shadow Tile"
        style={{ width: `${width}px`, height: `${height}px` }}
        className={`Tile_shadow ${isLifted ? "Tile_image_shadow_shrink" : ""}`}
        src={tileBaseShadow}
      />
      <img
        alt="Base Tile"
        style={{ width: `${width}px`, height: `${height}px` }}
        className={`Tile_image ${isLifted ? "Tile_image_lifted" : ""}`}
        src={tileBase}
      />
    </>
  );
}

interface ISoilTileProp {
  isLifted: boolean;
  height: number;
  width: number;
}

export function SoilTile({
  isLifted,
  height = getDimensions().plotSizeHeight,
  width = getDimensions().plotSizeWidth,
}: ISoilTileProp) {
  return (
    <div
      className="Tile"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <BaseTile isLifted={isLifted} height={height} width={width} />
    </div>
  );
}

interface IConstructedTileProp {
  imageSource: string;
  isLifted: boolean;
  height: number;
  width: number;
}

export function ConstructedTile({
  imageSource,
  isLifted,
  height = getDimensions().plotSizeHeight,
  width = getDimensions().plotSizeWidth,
}: IConstructedTileProp) {
  return (
    <div
      className="Tile"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <BaseTile isLifted={isLifted} height={height} width={width} />
      <img
        alt="Plant Tile"
        style={{ width: `${width}px`, height: `${height}px` }}
        className={`Tile_image ${isLifted ? "Tile_image_lifted" : ""}`}
        src={imageSource}
      />
    </div>
  );
}
