import iconWheat from "../../../assets/crops/grain_field/wheat.png";

import tileWheatEarly from "../../../assets/tiles/plants/grain_field/tile-wheat-1.png";
import tileWheatFull from "../../../assets/tiles/plants/grain_field/tile-wheat-2.png";
import tileWheatDead from "../../../assets/tiles/plants/grain_field/tile-wheat-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  GrainFieldPlantNameType,
  GrainFieldPlantDisplayNameType,
  GrainFieldPlantBlockchainNameType,
} from "./GrainField.naming";

const PlantWheat: IPlantType = {
  name: GrainFieldPlantNameType.wheat,
  blockchainName: GrainFieldPlantBlockchainNameType.wheat,
  displayName: GrainFieldPlantDisplayNameType.wheat,
  plotType: PlantPlotType.grainField,
  icon: iconWheat,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.yellow,
    [PlantGrowthStage.sprout]: SproutType.grain,
    [PlantGrowthStage.early]: tileWheatEarly,
    [PlantGrowthStage.full]: tileWheatFull,
    [PlantGrowthStage.dead]: tileWheatDead,
  },
};

export default PlantWheat;
