import { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import BigNumber from "bignumber.js";

import "../Modal.style.css";
import "./StorageModal.style.css";

import storageShell from "../../../assets/hud/storage/shell-storage.svg";

import { IAccountRegistrationDetails } from "../../../interfaces/accountRegistration";
import {
  IBlockchainMessageResponse,
  initiateIncreaseAllowance,
  useConnectedAccount,
} from "../../../interfaces/blockchain";
import { IContractSet } from "../../../interfaces/contracts/contracts.interfaces";
import { plantCrops, PlotCropAction } from "../../../interfaces/plot";

import { IPlotsLoadingState } from "../../../reducers/plotsLoading";
import {
  IPlantType,
  IPlotSet,
  PlantPlotType,
} from "../../../utils/PlantPlot.interfaces";
import { PlantPlotMapping } from "../../../utils/PlantPlot.mapping";

import CloseButton from "../../CloseButton";
import Loader from "../../Loader";
import { useToolType } from "../../tooltip/TooltipAlert";

import CropModalTileSection from "../CropModalTileSection";

const enum LoadingActionState {
  ALLOWANCE = "ALLOWANCE",
  PLANT = "PLANT",
}

interface ICompleteStakeActionDetails {
  loadingActionState: LoadingActionState;
  crop: IPlantType;
  cropAmount: number;
}

export interface IStorageModalProp {
  accountDetails: IAccountRegistrationDetails;
  contracts: IContractSet;
  clickedPlot: IPlotSet | null;
  cropDetails: any;
  goldBalance: BigNumber;
  isLoading: boolean;
  open: boolean;
  cropBalances: any;
  poolBalances: any;
  plotsLoadingState: IPlotsLoadingState;
  onClose: () => void;
  handlePlotLoadingAddition: (
    plotId: string,
    actionState: PlotCropAction,
    cropBlockchainName: string,
    cropAmountDelta: number
  ) => void;
  handlePlotLoadingRemoval: (
    plotId: string,
    cropBlockchainName: string,
    cropAmountDelta: number
  ) => void;
  handleOpenShop: (cartItem: IPlantType, amount: number) => void;
  handleUpdatePlotData: (
    actionType: PlotCropAction,
    currentPlot: IPlotSet,
    crop: any | null
  ) => void;
  refreshWalletCropBalances: () => void;
}

function StorageModal({
  accountDetails,
  contracts,
  clickedPlot,
  cropDetails,
  goldBalance,
  isLoading,
  open,
  cropBalances,
  poolBalances,
  plotsLoadingState,
  onClose,
  handleOpenShop,
  handlePlotLoadingAddition,
  handlePlotLoadingRemoval,
  handleUpdatePlotData,
  refreshWalletCropBalances,
}: IStorageModalProp) {
  const { connectedAccount } = useConnectedAccount();
  const [isLoadingClickStake, setIsLoadingClickStake] = useState(false);
  const { invokeAlert, invokeCxyzAlert, invokeMetaMaskAlert } = useToolType();

  const panelTabs: PlantPlotType[] = Object.keys(
    PlantPlotMapping
  ) as PlantPlotType[];
  panelTabs.sort();

  const completeStakeCallback = (
    actionDetails: ICompleteStakeActionDetails,
    message: IBlockchainMessageResponse,
    icon: string | null = null
  ) => {
    if (
      !!clickedPlot &&
      actionDetails.loadingActionState !== LoadingActionState.ALLOWANCE
    ) {
      handlePlotLoadingRemoval(
        clickedPlot.plotId,
        actionDetails.crop.blockchainName,
        0
      );
    } else {
      setIsLoadingClickStake(false);
    }

    if (message.isMetaMask) {
      invokeMetaMaskAlert(message.text);
    } else {
      if (!!icon) {
        invokeAlert(icon, message.text);
      } else {
        invokeCxyzAlert(message.text);
      }
    }
  };

  const handleIncreaseAllowance = async (
    currentSelection: IPlantType,
    refreshWalletFunc: () => void
  ) => {
    if (!!connectedAccount.gold && !isLoadingClickStake) {
      setIsLoadingClickStake(true);

      const { isSuccess, transactionDetails, message } =
        await initiateIncreaseAllowance(
          accountDetails,
          connectedAccount.gold,
          contracts.crops[currentSelection.name]
        );

      if (isSuccess) {
        // Pass transaction to be watched
        refreshWalletFunc();
        completeStakeCallback(
          {
            loadingActionState: LoadingActionState.ALLOWANCE,
            crop: currentSelection,
            cropAmount: 0,
          },
          message
        );
      } else {
        // Show error message
        completeStakeCallback(
          {
            loadingActionState: LoadingActionState.ALLOWANCE,
            crop: currentSelection,
            cropAmount: 0,
          },
          message
        );
      }
    }
  };

  const handleSelectCrop = async (
    currentSelection: IPlantType,
    cropBalance: BigNumber
  ) => {
    const calculatedAmount = !!clickedPlot
      ? clickedPlot.plotDimensions.width *
        clickedPlot.plotDimensions.height *
        clickedPlot.plotDimensions.tileArea
      : 0;
    if (!!connectedAccount.gold && !!clickedPlot) {
      if (cropBalance.lt(calculatedAmount)) {
        // Balance is not enough so need to buy more
        handleOpenShop(
          currentSelection,
          calculatedAmount - cropBalance.toNumber()
        );
      } else if (
        cropBalances.allowances[currentSelection.name].lt(calculatedAmount)
      ) {
        // Allowance is not enough so increase that
        handleIncreaseAllowance(currentSelection, refreshWalletCropBalances);
      } else {
        // Complete staking
        setIsLoadingClickStake(false);
        handlePlotLoadingAddition(
          clickedPlot.plotId,
          PlotCropAction.plant,
          currentSelection.blockchainName,
          -calculatedAmount
        );
        handleClose();

        const { isSuccess, transactionDetails, message } = await plantCrops(
          accountDetails,
          connectedAccount.gold,
          contracts,
          Object.keys(plotsLoadingState.plotIdsUsed).length,
          clickedPlot.plotId,
          cropDetails[currentSelection.name].elementId,
          currentSelection.displayName
        );

        if (isSuccess) {
          // Pass transaction to be watched
          handleUpdatePlotData(
            PlotCropAction.plant,
            clickedPlot,
            cropDetails[currentSelection.name]
          );
          completeStakeCallback(
            {
              loadingActionState: LoadingActionState.PLANT,
              crop: currentSelection,
              cropAmount: calculatedAmount,
            },
            message,
            currentSelection.icon
          );
        } else {
          // Show error message
          completeStakeCallback(
            {
              loadingActionState: LoadingActionState.PLANT,
              crop: currentSelection,
              cropAmount: calculatedAmount,
            },
            message
          );
        }
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={!isLoadingClickStake ? handleClose : () => {}}
      open={open}
      fullWidth={true}
      className="TileModal"
    >
      <Loader isLoading={isLoadingClickStake} />

      <img className="Modal-shell" src={storageShell} alt="Modal shell" />

      <div className="StorageShell-content_container">
        <CropModalTileSection
          clickedPlot={clickedPlot}
          cropBalances={!!cropBalances ? cropBalances : null}
          cropDetails={cropDetails}
          goldBalance={goldBalance}
          isLoading={isLoading}
          isLoadingAfterAction={isLoadingClickStake}
          isSelling={false}
          isStorageModal={true}
          panelTabs={panelTabs}
          panelTitle="Select seeds to plant"
          poolBalances={poolBalances}
          handleSelectCrop={handleSelectCrop}
        />
      </div>

      <div className="StorageShell-close_button">
        <CloseButton
          disabled={isLoadingClickStake}
          onClickHandler={!isLoadingClickStake ? handleClose : () => null}
        />
      </div>
    </Dialog>
  );
}

export default StorageModal;
