import Button from "@mui/material/Button";

import "./CoreButton.style.css";

import closeButton from "../assets/hud/buttons/button-flower_close.svg";
import { ButtonSize } from "./CoreButton";

export interface ICloseButtonProp {
  onClickHandler: () => void;
  disabled?: boolean;
  size?: ButtonSize;
}

function CloseButton({
  onClickHandler,
  disabled = false,
  size = ButtonSize.md,
}: ICloseButtonProp) {
  return (
    <Button
      className={`CloseButton ${size}`}
      onClick={onClickHandler}
      disabled={disabled}
    >
      <img className="CoreButton-image" src={closeButton} alt="Button" />
    </Button>
  );
}

export default CloseButton;
