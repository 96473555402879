import iconTomato from "../../../assets/crops/fruit_vine/tomato.png";

import tileTomatoEarly from "../../../assets/tiles/plants/fruit_vine/tile-tomato-1.png";
import tileTomatoFull from "../../../assets/tiles/plants/fruit_vine/tile-tomato-2.png";
import tileTomatoDead from "../../../assets/tiles/plants/fruit_vine/tile-tomato-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  FruitVinePlantNameType,
  FruitVinePlantDisplayNameType,
  FruitVinePlantBlockchainNameType,
} from "./FruitVine.naming";

const PlantTomato: IPlantType = {
  name: FruitVinePlantNameType.tomato,
  blockchainName: FruitVinePlantBlockchainNameType.tomato,
  displayName: FruitVinePlantDisplayNameType.tomato,
  plotType: PlantPlotType.fruitVine,
  icon: iconTomato,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.darkRed,
    [PlantGrowthStage.sprout]: SproutType.vineStake,
    [PlantGrowthStage.early]: tileTomatoEarly,
    [PlantGrowthStage.full]: tileTomatoFull,
    [PlantGrowthStage.dead]: tileTomatoDead,
  },
};

export default PlantTomato;
