import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import "./ToggleAmountPanel.style.css";

import toggleDecreaseImage from "../assets/hud/shop/button-arrow_decrease.svg";
import toggleIncreaseImage from "../assets/hud/shop/button-arrow_increase.svg";

export interface IToggleAmountPanelProp {
  currentAmount: number;
  isDisabled: boolean;
  isLoading: boolean;
  maxAmount: number;
  minAmount?: number;
  inputPretext?: string;
  inputPosttext?: string;
  setCurrentAmount: (selectedCurrent: number) => void;
}

function ToggleAmountPanel({
  currentAmount,
  isDisabled,
  isLoading,
  maxAmount,
  minAmount = 0,
  inputPretext,
  inputPosttext,
  setCurrentAmount,
}: IToggleAmountPanelProp) {
  return (
    <div className="ToggleAmountPanel-toggle_amount-container">
      <Button
        disabled={isDisabled || currentAmount === minAmount || isLoading}
        className="ToggleAmountPanel-toggle_amount-button"
        onClick={() =>
          currentAmount > minAmount ? setCurrentAmount(currentAmount - 1) : null
        }
      >
        <img
          className="ToggleAmountPanel-toggle_button-image"
          src={toggleDecreaseImage}
          alt="Decrease amount"
        />
      </Button>
      <div className="ToggleAmountPanel-toggle_amount-text">
        {inputPretext}
        <TextField
          onChange={
            !isLoading
              ? (event: any) => {
                  let numberValue = parseInt(event.target.value, 10);

                  if (numberValue < minAmount || !numberValue) {
                    numberValue = minAmount;
                  } else if (numberValue >= maxAmount) {
                    numberValue = maxAmount;
                  }

                  setCurrentAmount(numberValue);
                }
              : () => {}
          }
          value={
            currentAmount > minAmount || !isDisabled ? currentAmount : minAmount
          }
        />
        {inputPosttext}
      </div>
      <Button
        disabled={isDisabled || currentAmount >= maxAmount || isLoading}
        className="ToggleAmountPanel-toggle_amount-button"
        onClick={() => setCurrentAmount(currentAmount + 1)}
      >
        <img
          className="ToggleAmountPanel-toggle_button-image"
          src={toggleIncreaseImage}
          alt="Increase amount"
        />
      </Button>
    </div>
  );
}

export default ToggleAmountPanel;
