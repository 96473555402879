import Button from "@mui/material/Button";
import BigNumber from "bignumber.js";
import pluralize from "pluralize";

import "./CropModalTileRow.style.css";

import grayButton from "../../assets/hud/buttons/button-pill-gray.svg";
import greenButton from "../../assets/hud/buttons/button-pill-green.svg";
import orangeButton from "../../assets/hud/buttons/button-pill-orange.svg";
import redButton from "../../assets/hud/buttons/button-pill-red.svg";
import goldImage from "../../assets/hud/items/item-gold.png";

import { ICropDetailSet } from "../../interfaces/crops";

import {
  IPlantType,
  IPlotSet,
  PlantPlotType,
} from "../../utils/PlantPlot.interfaces";
import { PlantPlotMapping } from "../../utils/PlantPlot.mapping";
import {
  calculateBuyPrice,
  calculateSellPrice,
  convertSecondsToTime,
  goldNumberWithCommas,
  numberWithCommas,
  renderTime,
} from "../../utils/helpers";

export interface ICropModalTileRowProp {
  activeTab: PlantPlotType;
  clickedPlot: IPlotSet | null;
  cropBalances: any;
  cropDetails: any;
  goldBalance: BigNumber;
  isLoading: boolean;
  isLoadingAfterAction: boolean;
  isSelling: boolean;
  isStorageModal: boolean;
  isTop: boolean;
  poolBalances: any;
  handleSelectCrop: (
    currentCropTile: IPlantType,
    cropBalance: BigNumber
  ) => void;
}

function CropModalTileRow({
  activeTab,
  clickedPlot,
  cropBalances,
  cropDetails,
  goldBalance,
  isLoading,
  isLoadingAfterAction,
  isSelling,
  isStorageModal,
  isTop,
  poolBalances,
  handleSelectCrop,
}: ICropModalTileRowProp) {
  if (!cropBalances) {
    return null;
  }

  const rawCropTiles: IPlantType[] = Object.values(
    PlantPlotMapping[activeTab]
  ).filter((currentCropTile) => currentCropTile.name in cropBalances.balances);

  // Pull the ones that have 0 to the back
  const cropTileSetWithValues: IPlantType[] = [];
  const cropTileSetWithoutValues: IPlantType[] = [];

  rawCropTiles.forEach((currentCropTile) => {
    if (
      !!cropBalances &&
      cropBalances.balances[currentCropTile.name] &&
      cropBalances.balances[currentCropTile.name].gt(0)
    ) {
      cropTileSetWithValues.push(currentCropTile);
    } else {
      if (!isSelling) {
        cropTileSetWithoutValues.push(currentCropTile);
      }
    }
  });

  cropTileSetWithValues.sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );
  cropTileSetWithoutValues.sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );

  const cropTiles: IPlantType[] = [
    ...cropTileSetWithValues,
    ...cropTileSetWithoutValues,
  ];

  const currentRowCropTiles = isTop
    ? cropTiles.slice(0, 3)
    : cropTiles.slice(3);

  const classNameString = isTop ? "top" : "bottom";

  const renderStorageTile = (
    additionalAmountOfSeedsNeeded: number,
    calculateTotalAmount: number,
    cropName: string,
    hasEnoughGold: boolean,
    hasEnoughSeeds: boolean
  ) => {
    let plantButtonText = `Plant ${calculateTotalAmount}`;
    if (isLoadingAfterAction) {
      plantButtonText = "Loading...";
    } else if (cropBalances.allowances[cropName].lt(calculateTotalAmount)) {
      plantButtonText = "Begin Plant";
    }

    // Allow plant
    if (hasEnoughSeeds) {
      return (
        <div className="CropTile-preview_button">
          <img
            className="CropTile-preview_button-image"
            src={isLoadingAfterAction ? grayButton : greenButton}
            alt="Plant button"
          />
          <div className="CropTile-preview_button-text_plant">
            {plantButtonText}
          </div>
        </div>
      );
    }

    // Buy more seeds
    return (
      <div className="CropTile-preview_button">
        <img
          className="CropTile-preview_button-image"
          src={hasEnoughGold ? orangeButton : redButton}
          alt="Plant button"
        />
        <div className="CropTile-preview_button-text_plant">
          {hasEnoughGold
            ? `Buy ${additionalAmountOfSeedsNeeded} more`
            : "Too Expensive"}
        </div>
      </div>
    );
  };

  const calculatePrice = isSelling ? calculateSellPrice : calculateBuyPrice;

  return (
    <div className={`Shell-available_${classNameString}`}>
      {currentRowCropTiles.map((currentCropTile, seedTileIndex) => {
        const selectedCrop: ICropDetailSet = cropDetails[currentCropTile.name];

        // Pull the times of the plot
        const [nothingToStart, startToEarly, earlyToMature, matureToExpire] =
          selectedCrop.growthTable;

        const readyDelta = nothingToStart + startToEarly + earlyToMature;

        const cropBalance: BigNumber =
          cropBalances.balances[currentCropTile.name];
        const poolBalance = poolBalances[currentCropTile.name];

        let additionalAmountOfSeedsNeeded = 0;
        let calculateTotalAmount = 0;
        let hasEnoughSeeds = false;
        let hasEnoughGold = false;

        if (isStorageModal && !!clickedPlot) {
          calculateTotalAmount =
            clickedPlot.plotDimensions.width *
            clickedPlot.plotDimensions.height *
            clickedPlot.plotDimensions.tileArea;
          hasEnoughSeeds = cropBalance.gte(calculateTotalAmount);

          if (!hasEnoughSeeds) {
            additionalAmountOfSeedsNeeded =
              calculateTotalAmount - cropBalance.toNumber();
            const costOfAdditionalSeeds = calculateBuyPrice(
              additionalAmountOfSeedsNeeded,
              poolBalance.goldTrackedAmount,
              poolBalance.tokenTrackedAmount
            );
            hasEnoughGold = goldBalance.gt(costOfAdditionalSeeds);
          }
        }

        return (
          <Button
            key={`shop_tile-${seedTileIndex}`}
            className="CropTile"
            onClick={() => handleSelectCrop(currentCropTile, cropBalance)}
            disabled={
              isLoadingAfterAction ||
              (isStorageModal && !hasEnoughSeeds && !hasEnoughGold)
            }
          >
            <div className="CropTile-title">
              {pluralize(currentCropTile.displayName)}
            </div>

            <div className="CropTile-subtitle-time">
              {"Time: "}
              {renderTime(convertSecondsToTime(readyDelta), {
                isCompressed: true,
              })}
            </div>

            <div className="CropTile-subtitle">
              {"You have: "}
              {numberWithCommas(cropBalance)}
            </div>

            <img
              className="CropTile-image"
              src={currentCropTile.icon}
              alt="Tile"
            />

            {isStorageModal ? (
              renderStorageTile(
                additionalAmountOfSeedsNeeded,
                calculateTotalAmount,
                currentCropTile.name,
                hasEnoughGold,
                hasEnoughSeeds
              )
            ) : (
              <div className="CropTile-preview_button">
                <img
                  className="CropTile-preview_button-image"
                  src={isSelling ? redButton : greenButton}
                  alt="Seed button"
                />
                <div className="CropTile-preview_button-text">
                  <img
                    className="CropTile-preview_button-gold_image"
                    src={goldImage}
                    alt="Gold"
                  />
                  {!isLoading ? (
                    <>
                      {goldNumberWithCommas(
                        calculatePrice(
                          1,
                          poolBalance.goldTrackedAmount,
                          poolBalance.tokenTrackedAmount
                        )
                      )}
                      <span className="CropTile-preview_button-bag_text"></span>
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            )}
          </Button>
        );
      })}
    </div>
  );
}

export default CropModalTileRow;
