export enum PlotYield {
  low = "low",
  medium = "medium",
  high = "high",
}

export enum PlotGrowthRate {
  slow = "slow",
  medium = "medium",
  fast = "fast",
}

export const PlotYieldName = {
  [PlotYield.low]: "Low",
  [PlotYield.medium]: "Medium",
  [PlotYield.high]: "High",
};

export const PlotGrowthRateName = {
  [PlotGrowthRate.slow]: "Slow",
  [PlotGrowthRate.medium]: "Medium",
  [PlotGrowthRate.fast]: "Fast",
};

export const getGrowthRate = (baseSpeed: number) => {
  if (baseSpeed > 10) {
    return PlotGrowthRateName[PlotGrowthRate.fast];
  } else if (baseSpeed > 5) {
    return PlotGrowthRateName[PlotGrowthRate.medium];
  }
  return PlotGrowthRateName[PlotGrowthRate.slow];
};

export const getYieldRate = (baseYield: number) => {
  if (baseYield > 10) {
    return PlotYieldName[PlotYield.high];
  } else if (baseYield > 5) {
    return PlotYieldName[PlotYield.medium];
  }
  return PlotYieldName[PlotYield.low];
};
