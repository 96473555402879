import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";

import "./App.style.css";

import Header from "./components/Header";
import PlotMapContainer from "./components/PlotMapContainer";
import ButtonContainer from "./components/ButtonContainer";

import AlertModal, { AlertType } from "./components/modals/alert/AlertModal";
import { ButtonColor } from "./components/CoreButton";
import DetailsModal from "./components/modals/details/DetailsModal";
import ShopModal, { ShopPanelState } from "./components/modals/shop/ShopModal";
import { IShopModalCartItem } from "./components/modals/shop/ShopModalCheckoutSection";
import StorageModal from "./components/modals/storage/StorageModal";

import { IAccountRegistrationDetails } from "./interfaces/accountRegistration";
import { useConnectedAccount, getGoldContracts } from "./interfaces/blockchain";
import { useWalletCropBalances } from "./interfaces/crops";
import { useCropPrices } from "./interfaces/cropPrices";
import { PlotCropAction } from "./interfaces/plot";
import {
  usePlotDetails,
  usePlotsOwnedStatus,
  useWalletGoldBalance,
} from "./interfaces/wallet";

import {
  usePlotsLoadingReducer,
  PlotsLoadingActionType,
} from "./reducers/plotsLoading";
import { IPlantType, IPlotSet } from "./utils/PlantPlot.interfaces";

function App({
  accountDetails,
  handleOpenPacks,
}: {
  accountDetails: IAccountRegistrationDetails;
  handleOpenPacks: () => void;
}) {
  const { addressToCropNameMap, contracts } = getGoldContracts();

  const { plotsLoadingState, dispatchPlotsLoadingAction } =
    usePlotsLoadingReducer();

  const { authActions, connectedAccount } = useConnectedAccount();
  const { onchainId } = connectedAccount;

  const [isSelling, setIsSelling] = useState(false);
  const [currentPlotPage, setCurrentPlotPage] = useState<number>(1);

  const { isLoadingPoolDetails, poolBalances, refreshPoolData } =
    useCropPrices(addressToCropNameMap);

  const {
    isLoadingWalletCropBalances,
    walletCropBalances,
    refreshWalletCropBalances,
  } = useWalletCropBalances(accountDetails);

  const {
    isLoadingWalletGoldBalance,
    goldAllowance,
    walletGoldBalance,
    refreshWalletGoldState,
  } = useWalletGoldBalance(accountDetails);

  const {
    isLoadingPlotSet,
    plotSet: fullPlotSet,
    refreshUserOwnedPlots,
  } = usePlotsOwnedStatus(accountDetails);

  // Get the first 9 plots for the current plot page
  const plotSet = fullPlotSet.slice(
    (currentPlotPage - 1) * 9,
    (currentPlotPage - 1) * 9 + 9
  );
  const maxPlotPage = Math.ceil(fullPlotSet.length / 9);

  const {
    isLoadingPlotDetails,
    cropDetails,
    plotDetails,
    forcedUpdatePlotData,
  } = usePlotDetails(addressToCropNameMap, plotSet);

  const [detailsPlot, setDetailsPlot] = useState<IPlotSet | null>(null);

  const [alertConfirmButtonColor] = useState<ButtonColor>(ButtonColor.green);
  const [alertMessage] = useState("");

  const [currentOnchainId, setCurrentOnchainId] = useState<string | null>(null);

  const [openAlert, setOpenAlert] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openShop, setOpenShop] = useState(false);
  const [openStorage, setOpenStorage] = useState(false);

  const [selectedCropAmount, setSelectedCropAmount] = useState(0);
  const [selectedCrop, setSelectedCrop] = useState<null | IPlantType>(null);

  const [cartItemsShop, setCartItemsShop] = useState<IShopModalCartItem[]>([]);
  const [shopPanel, setShopPanel] = useState(ShopPanelState.TILES);

  useEffect(() => {
    if (currentOnchainId !== onchainId) {
      setCurrentOnchainId(onchainId);

      if (openAlert) {
        setOpenAlert(false);
      }
      if (openDetails) {
        setOpenDetails(false);
      }
      if (openShop) {
        setOpenShop(false);
      }
      if (openStorage) {
        setOpenStorage(false);
      }
    }
  }, [onchainId]);

  const handleClickPlot = (plotSet: IPlotSet) => {
    refreshWalletCropBalances();

    setIsSelling(true);
    setDetailsPlot(plotSet);
    setOpenDetails(true);
  };

  const handleOpenShop = () => {
    refreshWalletCropBalances();
    refreshPoolData();

    setIsSelling(false);
    setOpenShop(true);
  };

  const handleOpenShopAfterStorage = (cartItem: IPlantType, amount: number) => {
    setCartItemsShop([
      {
        amount,
        cartItem,
      },
    ]);

    setShopPanel(ShopPanelState.CART);

    setOpenStorage(false);
    handleOpenShop();
  };

  const handleOpenMyStore = async () => {
    refreshWalletCropBalances();
    refreshPoolData();

    setIsSelling(true);
    setOpenShop(true);
  };

  const handleCloseShop = () => {
    setOpenShop(false);
  };

  const handleCloseStorage = () => {
    setOpenStorage(false);
  };

  const handleCloseDenyAlert = () => {
    setOpenAlert(false);
  };

  const handleCloseConfirmationAlert = () => {
    setOpenAlert(false);
  };

  const handlePlotLoadingAddition = (
    plotId: string,
    plotAction: PlotCropAction,
    cropBlockchainName: string,
    cropAmountDelta: number
  ) => {
    dispatchPlotsLoadingAction({
      type: PlotsLoadingActionType.ADD_PLOT,
      plotId,
      plotAction,
      cropBlockchainName,
      cropAmountDelta,
    });
  };

  const handlePlotLoadingRemoval = (
    plotId: string,
    cropBlockchainName: string,
    cropAmountDelta: number
  ) => {
    dispatchPlotsLoadingAction({
      type: PlotsLoadingActionType.REMOVE_PLOT,
      plotId,
      cropBlockchainName,
      cropAmountDelta,
    });
  };

  return (
    <>
      <Header
        walletGoldBalance={walletGoldBalance}
        isLoadingWalletGoldBalance={isLoadingWalletGoldBalance}
      />
      <PlotMapContainer
        isLoading={isLoadingPlotSet || isLoadingPlotDetails}
        plotsLoadingState={plotsLoadingState}
        showPlotData={!!connectedAccount.gold}
        plotSet={plotSet}
        plotDetails={plotDetails}
        cropDetails={cropDetails}
        currentPlotPage={currentPlotPage}
        maxPlotPage={maxPlotPage}
        setCurrentPlotPage={setCurrentPlotPage}
        handleClickPlot={handleClickPlot}
      />

      <ButtonContainer
        handleOpenMyStore={handleOpenMyStore}
        handleOpenShop={handleOpenShop}
        handleOpenPacks={handleOpenPacks}
      />

      <DetailsModal
        accountDetails={accountDetails}
        contracts={contracts}
        cropDetails={cropDetails}
        currentPlot={detailsPlot}
        open={openDetails}
        plotsLoadingState={plotsLoadingState}
        handleClickOutCloseHandler={() => setOpenDetails(false)}
        handleOpenStorage={() => setOpenStorage(true)}
        handlePlotLoadingAddition={handlePlotLoadingAddition}
        handlePlotLoadingRemoval={handlePlotLoadingRemoval}
        handleUpdatePlotData={forcedUpdatePlotData}
      />

      <ShopModal
        accountDetails={accountDetails}
        contracts={contracts}
        cropDetails={cropDetails}
        cropBalances={walletCropBalances}
        open={openShop}
        poolBalances={poolBalances}
        goldAllowance={!!goldAllowance ? goldAllowance : BigNumber(0)}
        goldBalance={!!walletGoldBalance ? walletGoldBalance : BigNumber(0)}
        isLoading={
          isLoadingPlotDetails ||
          isLoadingPoolDetails ||
          isLoadingWalletCropBalances
        }
        isSelling={isSelling}
        cartItems={cartItemsShop}
        selectedCrop={selectedCrop}
        selectedCropAmount={selectedCropAmount}
        shopPanel={shopPanel}
        onClose={handleCloseShop}
        refreshWalletCropBalances={refreshWalletCropBalances}
        refreshWalletGoldState={refreshWalletGoldState}
        setCartItems={setCartItemsShop}
        setSelectedCrop={setSelectedCrop}
        setSelectedCropAmount={setSelectedCropAmount}
        setShopPanel={setShopPanel}
      />

      <StorageModal
        accountDetails={accountDetails}
        contracts={contracts}
        clickedPlot={detailsPlot}
        cropDetails={cropDetails}
        goldBalance={!!walletGoldBalance ? walletGoldBalance : BigNumber(0)}
        isLoading={
          isLoadingPlotDetails ||
          isLoadingPoolDetails ||
          isLoadingWalletCropBalances
        }
        open={openStorage}
        cropBalances={walletCropBalances}
        poolBalances={poolBalances}
        onClose={handleCloseStorage}
        plotsLoadingState={plotsLoadingState}
        handleOpenShop={handleOpenShopAfterStorage}
        handlePlotLoadingAddition={handlePlotLoadingAddition}
        handlePlotLoadingRemoval={handlePlotLoadingRemoval}
        handleUpdatePlotData={forcedUpdatePlotData}
        refreshWalletCropBalances={refreshWalletCropBalances}
      />

      <AlertModal
        showAlternativeButton
        open={openAlert}
        confirmButtonColor={alertConfirmButtonColor}
        confirmButtonText={"Yes"}
        alternativeButtonText={"No"}
        type={AlertType.alert}
        onAlternativeHandler={handleCloseDenyAlert}
        onClickOutCloseHandler={() => setOpenAlert(false)}
        onConfirmHandler={handleCloseConfirmationAlert}
      >
        <div className="AlertShell-body_text">{alertMessage}</div>
      </AlertModal>
    </>
  );
}

export default App;
