import { Oval as LoadingOval } from "react-loader-spinner";

import "./Loader.style.css";

export interface ILoadingContainerProp {
  isLoading: boolean;
  isBlock?: boolean;
  isDark?: boolean;
  size?: string;
  additionalStyling?: any;
}

function LoadingContainer({
  isLoading,
  isBlock = true,
  isDark = false,
  size = "med",
  additionalStyling = {},
}: ILoadingContainerProp) {
  let sizeValue = 150;
  let barValue = 2;

  if (size === "xxs") {
    sizeValue = 34;
    barValue = 8;
  } else if (size === "xs") {
    sizeValue = 50;
  } else if (size === "sm") {
    sizeValue = 100;
  }

  return isLoading ? (
    <div
      className={`LoadingContainer ${
        isBlock ? "LoadingBlock" : "LoadingSingle"
      }`}
      style={additionalStyling}
    >
      <div className="LoadingContent">
        <LoadingOval
          color={isDark ? "#603c21aa" : "#f9dab6aa"}
          height={sizeValue}
          width={sizeValue}
          strokeWidth={barValue}
        />
      </div>
    </div>
  ) : null;
}

export default LoadingContainer;
