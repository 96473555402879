import moment from "moment";

import { IPlotSet } from "./PlantPlot.interfaces";
import { SeedColor } from "./Seed";
import { SproutType } from "./Sprout";

export enum PlantGrowthStage {
  seed = "seed",
  sprout = "sprout",
  early = "early",
  full = "full",
  dead = "dead",
}

export const PlantGrowthStageDisplayName = {
  [PlantGrowthStage.seed]: "Seeds Growing",
  [PlantGrowthStage.sprout]: "First Sprout",
  [PlantGrowthStage.early]: "Early Growth",
  [PlantGrowthStage.full]: "Fully Grown",
  [PlantGrowthStage.dead]: "Dead",
};

export const PlantGrowthStagePrefex = {
  [PlantGrowthStage.seed]: "Harvest ready in ",
  [PlantGrowthStage.sprout]: "Harvest ready in ",
  [PlantGrowthStage.early]: "Harvest ready in ",
  [PlantGrowthStage.full]: "Crops die in ",
  [PlantGrowthStage.dead]: "Dead",
};

export const PlantGrowthStageOrder = [
  PlantGrowthStage.seed,
  PlantGrowthStage.sprout,
  PlantGrowthStage.early,
  PlantGrowthStage.full,
  PlantGrowthStage.dead,
];

export interface IPlantGrowthStages {
  [PlantGrowthStage.seed]: SeedColor;
  [PlantGrowthStage.sprout]: SproutType;
  [PlantGrowthStage.early]: string;
  [PlantGrowthStage.full]: string;
  [PlantGrowthStage.dead]: string;
}

export const getPlantStage = (plotDetails: IPlotSet | null) => {
  let plantStage = null;

  if (!!plotDetails && !!plotDetails.plantType) {
    plantStage = PlantGrowthStage.seed;

    const startedTime = plotDetails.timeSet.started;
    const currentTime = Math.floor(moment.now() / 1000);

    let toPresentDelta = currentTime - startedTime;

    plotDetails.timeSet.breakdown.forEach(
      (currentTimeSlot: number, timeSlotIndex: number) => {
        const timeSlotInMilli = currentTimeSlot;

        if (toPresentDelta > 0 && toPresentDelta < timeSlotInMilli) {
          plantStage = PlantGrowthStageOrder[timeSlotIndex];
        }
        toPresentDelta -= timeSlotInMilli;
      }
    );

    // If the time is still positive then the plants are dead it has been too long
    if (toPresentDelta > 0) {
      plantStage = PlantGrowthStage.dead;
    }
  }
  return plantStage;
};
