import iconRose from "../../../assets/crops/flower_bed/rose.png";

import tileRoseEarly from "../../../assets/tiles/plants/flower_bed/tile-rose-1.png";
import tileRoseFull from "../../../assets/tiles/plants/flower_bed/tile-rose-2.png";
import tileRoseDead from "../../../assets/tiles/plants/flower_bed/tile-rose-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  FlowerBedPlantNameType,
  FlowerBedPlantDisplayNameType,
  FlowerBedPlantBlockchainNameType,
} from "./FlowerBed.naming";

const PlantRose: IPlantType = {
  name: FlowerBedPlantNameType.rose,
  blockchainName: FlowerBedPlantBlockchainNameType.rose,
  displayName: FlowerBedPlantDisplayNameType.rose,
  plotType: PlantPlotType.flowerBed,
  icon: iconRose,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.red,
    [PlantGrowthStage.sprout]: SproutType.leaf,
    [PlantGrowthStage.early]: tileRoseEarly,
    [PlantGrowthStage.full]: tileRoseFull,
    [PlantGrowthStage.dead]: tileRoseDead,
  },
};

export default PlantRose;
