import { useState } from "react";
import pluralize from "pluralize";
import Countdown from "react-countdown";

import "./PlotTileSet.style.css";

import { PlotCropAction } from "../../interfaces/plot";
import {
  getPlantStage,
  PlantGrowthStage,
  PlantGrowthStagePrefex,
} from "../../utils/PlantGrowthStage";
import {
  calculatePlotWidth,
  IPlantType,
  IPlotDimensions,
  IPlotSet,
  PlantPlotType,
} from "../../utils/PlantPlot.interfaces";
import { getDimensions } from "../../utils/constants";
import { renderTime } from "../../utils/helpers";

import CoreButton, { ButtonColor } from "../CoreButton";
import Loader from "../Loader";

import PlotTile from "./PlotTile";

export interface IPlotTileSetProp {
  currentPlot: IPlotSet;
  readyTimestamp: any;
  plotType: PlantPlotType;
  plantType: IPlantType | null;
  plotIndex: number;
  plotDimensions: IPlotDimensions;
  maxPlotDimensions: IPlotDimensions;
  adjustmentLeft: number;
  adjustmentTop: number;
  adjustmentPadding: number;
  heightArray: any[];
  widthArray: any[];
  zIndexPadding: number;
  loadingPlotAction: PlotCropAction | null;
  handleClickPlot: (plotIndex: number) => void;
}

export const getPlotHarvestDetails = (currentPlot?: IPlotSet | null) => {
  // Clearing the plot
  let buttonText: string = "Clear";
  let buttonColor: ButtonColor = ButtonColor.red;
  let confirmButtonColor: ButtonColor = ButtonColor.red;
  let harvestState: PlotCropAction = PlotCropAction.clearGrowing;
  let messageText: string = currentPlot
    ? `Are you sure you want to clear this plot (${currentPlot.plotId})?`
    : "";

  if (!currentPlot) {
    // Clearing the plot
    return {
      buttonText,
      messageText,
      buttonColor,
      confirmButtonColor,
      harvestState,
    };
  } else {
    let plantStage = getPlantStage(currentPlot);

    if (!currentPlot.plantType) {
      // Planting in the plot
      buttonText = "Plant";
      buttonColor = ButtonColor.green;
      confirmButtonColor = ButtonColor.green;
      harvestState = PlotCropAction.plant;
    } else if (plantStage === PlantGrowthStage.full && currentPlot.plantType) {
      // Harvesting the plot
      buttonText = "Harvest";
      messageText = `Do you want to harvest ${pluralize(
        currentPlot.plantType.displayName
      )} from this plot?`;
      buttonColor = ButtonColor.green;
      confirmButtonColor = ButtonColor.green;
      harvestState = PlotCropAction.harvest;
    } else if (plantStage === PlantGrowthStage.dead && currentPlot.plantType) {
      // Clear the dead crops from the plot
      buttonText = "Clear Dead";
      messageText = `Do you want to clear the dead ${pluralize(
        currentPlot.plantType.displayName
      )} from this plot?`;
      buttonColor = ButtonColor.green;
      confirmButtonColor = ButtonColor.green;
      harvestState = PlotCropAction.clearDead;
    }
  }

  return {
    buttonText,
    messageText,
    buttonColor,
    confirmButtonColor,
    harvestState,
  };
};

function PlotTileSet({
  currentPlot,
  readyTimestamp,
  plotType,
  plantType,
  plotIndex,

  plotDimensions,
  maxPlotDimensions,

  adjustmentLeft,
  adjustmentTop,
  adjustmentPadding,
  zIndexPadding,

  heightArray,
  widthArray,

  loadingPlotAction,

  handleClickPlot,
}: IPlotTileSetProp) {
  const { height, width } = plotDimensions;
  const plotTileHeight = calculatePlotWidth(plotDimensions);

  const [isElementHovering, setIsElementHovering] = useState(
    [...Array(height)].map(() => false)
  );

  const [isDescriptionHovering, setIsDescriptionHovering] = useState(false);
  const [isDescriptionTitleHovering, setIsDescriptionTitleHovering] =
    useState(false);

  const updateHovering = (y_index: number, updatingValue: boolean) => {
    const updatedIsHovering = [...isElementHovering];
    updatedIsHovering[y_index] = updatingValue;
    setIsElementHovering(updatedIsHovering);
  };

  const isAggregatedHover =
    !loadingPlotAction &&
    (isDescriptionHovering ||
      isDescriptionTitleHovering ||
      isElementHovering.reduce((prev, current) => prev || !!current, false));

  const calculatePlotTileRowY = (y_index: number) =>
    (widthArray.length - 1) * getDimensions().displaceTop - y_index * 90;

  const plantStage = getPlantStage(currentPlot);

  const renderSlideOutButton = () => {
    const { buttonText, buttonColor } = getPlotHarvestDetails(currentPlot);

    return (
      <CoreButton
        onClickHandler={
          !loadingPlotAction ? () => handleClickPlot(plotIndex) : () => {}
        }
        buttonText={buttonText}
        color={buttonColor}
      />
    );
  };

  let growingText = "";

  if (plantStage) {
    growingText = PlantGrowthStagePrefex[plantStage];
  } else {
    growingText = "Ready to Plant";
  }

  return (
    <div
      className="PlotTileSet"
      style={{
        width: `${plotTileHeight}px`,
        height: `${plotTileHeight}px`,
        top: `${adjustmentTop ? adjustmentTop : 0}px`,
        left: `${adjustmentLeft ? adjustmentLeft : 0}px`,
        padding: `${adjustmentPadding}px`,
      }}
    >
      {!!loadingPlotAction && (
        <Loader
          isLoading={true}
          size="sm"
          additionalStyling={{
            zIndex: zIndexPadding + width * 2,
            top: `${
              calculatePlotTileRowY((heightArray.length - 1) / 2) +
              (((height - 1) / 2) *
                getDimensions().verticalCenterLineAdjustment) /
                2
            }px`,
          }}
        />
      )}

      {heightArray.map((_, y_index) => (
        <div
          key={`row-${y_index}`}
          className="PlotTileRow"
          style={{
            top: `${calculatePlotTileRowY(y_index)}px`,
            left: `${y_index * getDimensions().displaceLeft}px`,
          }}
        >
          {widthArray.map((_, x_index) => (
            <div
              key={`tile-${x_index}_${y_index}`}
              onClick={
                !loadingPlotAction ? () => handleClickPlot(plotIndex) : () => {}
              }
              onMouseOver={
                !loadingPlotAction
                  ? () => updateHovering(y_index, true)
                  : () => {}
              }
              onMouseOut={
                !loadingPlotAction
                  ? () => updateHovering(y_index, false)
                  : () => {}
              }
              style={{
                position: "relative",
                zIndex: zIndexPadding + width - x_index,
                top: `${x_index * -getDimensions().displaceTop}px`,
                left: `${x_index * -getDimensions().displaceLeft * 2}px`,
              }}
            >
              <PlotTile
                isLifted={isAggregatedHover}
                loadingPlotAction={loadingPlotAction}
                plantStage={plantStage}
                plantType={plantType}
                height={getDimensions().plotSizeHeight}
                width={getDimensions().plotSizeWidth}
              />
            </div>
          ))}
        </div>
      ))}

      <div
        onMouseOver={
          !loadingPlotAction ? () => setIsDescriptionHovering(true) : () => {}
        }
        onMouseOut={
          !loadingPlotAction ? () => setIsDescriptionHovering(false) : () => {}
        }
        style={{
          position: "absolute",
          top: `${
            plotTileHeight +
            calculatePlotTileRowY(height - 1) + // Shift it vertically down
            getDimensions().plotSizeHeight +
            getDimensions().paddingExterior +
            (getDimensions().paddingInterior / 2) *
              (maxPlotDimensions.height - plotDimensions.height)
          }px`,
          left: `${
            -(
              ((height - 1) / 2) *
              getDimensions().verticalCenterLineAdjustment
            ) / 2
          }px`,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          className={`PlotTitle ${isAggregatedHover ? "PlotTitle-hover" : ""}`}
          onMouseOver={
            !loadingPlotAction
              ? () => setIsDescriptionTitleHovering(true)
              : () => {}
          }
          onMouseOut={
            !loadingPlotAction
              ? () => setIsDescriptionTitleHovering(false)
              : () => {}
          }
        >
          <div className="PlotTitle-name">
            {plantType ? pluralize(plantType.displayName) : "Fresh Soil"}
          </div>
          <div className="PlotTitle-details">
            {growingText}
            {!!readyTimestamp && (
              <Countdown date={readyTimestamp} renderer={renderTime} />
            )}
          </div>
          <div className="PlotTitle-button_container">
            {renderSlideOutButton()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlotTileSet;
