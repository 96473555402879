import { styled } from "@mui/system";

import { COLORS } from "../utils/constants";

export const LogoImage = styled("img")(
  ({ theme }) => `
    width: 200px;
    margin-top: 20px;
  `
);

export const LogoIcon = styled("img")(
  ({ theme }) => `
    height: 110px;
  `
);

export const WalletDetailsTitle = styled("span")(
  ({ theme }) => `
    marginRight: ${theme.spacing(1)},
  `
);

const classes = {
  root: (theme) => ({
    display: "flex",
    position: "fixed",
    flexGrow: 1,
    color: "#17161b",
    height: "100%",
    width: "100%",
    justifyContent: "center",
  }),
  appBar: (theme) => ({
    border: "transparent",
    backgroundColor: "transparent",

    position: "fixed",
    width: "100%",
    boxShadow: "none",
    color: "rgb(37,38,61)",
    zIndex: 100,
  }),
  content: (theme) => ({
    height: "100%",
    margin: "auto",
  }),
  contentHalf: (theme) => ({
    display: "flex",
    position: "relative",
    justifyContent: "center",
  }),
  shadowImage: (theme) => ({
    position: "absolute",
    width: "calc(100%)",
  }),
  logoContainer: (theme) => ({
    flexDirection: "column",
    justifyContent: "center",
  }),
  socialContainer: (theme) => ({
    flexDirection: "row",
    justifyContent: "center",
  }),
  logoIcon: (theme) => ({
    width: "60px",
  }),
  socialImage: (theme) => ({
    // 603c21
    width: "30px",
    padding: "5px",
  }),
  loading: (theme) => ({
    marginRight: theme.spacing(1),
  }),
  mintButtonContainer: (theme) => ({
    textAlign: "center",
    marginTop: theme.spacing(3),
  }),
  mintButton: (theme) => ({
    fontFamily: "Righteous",
    color: COLORS.darkBlue,
    backgroundColor: "#17161b",
    borderRadius: "30px",
    textTransform: "none",
  }),
  walletButton: (theme) => ({
    fontFamily: "Righteous",
    color: "#17161b",
    marginRight: theme.spacing(1),
    textTransform: "none",
  }),
  headerTitle: (theme) => ({
    fontFamily: "Righteous",
    color: "#17161b",
  }),
  title: (theme) => ({
    fontFamily: "Righteous",
    color: "#17161b",
  }),
  bodyTextContainer: (theme) => ({
    margin: `${theme.spacing(3)}px 0`,
  }),
  bodyText: (theme) => ({
    fontFamily: "Righteous",
    color: "white",
    marginTop: theme.spacing(3),
  }),
  walletBalanceContainer: (theme) => ({
    display: "flex",
    justifyContent: "center",
    position: "relative",
    color: "white",
    wordBreak: "break-word",
  }),
  iconContainer: (theme) => ({
    display: "flex",
    position: "absolute",
    left: "-35px",
    top: "-35px",
  }),
  walletInternalDetailsHolder: (theme) => ({
    border: `2px solid ${COLORS.brown.base}`,
    padding: "10px 20px",
    borderRadius: "15px 0",
    color: "white",
    backgroundColor: COLORS.brown.opacity,
  }),
  walletInternalDetailsTitle: (theme) => ({
    alignContent: "center",
  }),
  walletDisconnectButton: (theme) => ({
    paddingLeft: "10px",
  }),
  walletInternalDetailsNumbers: (theme) => ({
    padding: "10px 20px 10px 90px",
    position: "relative",
    margin: `0 ${theme.spacing(1)}px`,
    textAlign: "right",
  }),
};

export default classes;
