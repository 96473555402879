import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";

import closeImage from "../../assets/hud/general/button-close.svg";
import cropxyzIcon from "../../assets/logo/icon.png";
import metaMaskIcon from "../../assets/hud/items/metamask-fox.svg";

import Loader from "../Loader";

import classesTooltip, {
  IconAlertImage,
  IconDismissImage,
} from "./TooltipAlert.styles";

export interface ITransactionAlertProp {
  isTransaction?: boolean;
}

export interface ITransactionAlertReturnProp {
  invokeAlert: (
    alertIcon: string,
    alertContentText: string,
    additionalIconStyling?: any,
    additionalOptions?: ITransactionAlertProp
  ) => void;
  invokeCxyzAlert: (
    message: string,
    additionalOptions?: ITransactionAlertProp
  ) => void;
  invokeMetaMaskAlert: (
    message: string,
    additionalOptions?: ITransactionAlertProp
  ) => void;
}

export const useToolType = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const invokeAlert = (
    alertIcon: string,
    alertContentText: string,
    additionalIconStyling: any = {},
    additionalOptions?: ITransactionAlertProp
  ) => {
    return enqueueSnackbar("", {
      persist: additionalOptions?.isTransaction,
      content: (key: any) => (
        <Box sx={classesTooltip.shell}>
          <Box sx={classesTooltip.coreContent}>
            <IconAlertImage
              src={alertIcon}
              alt="Alert"
              sx={additionalIconStyling}
            />
            <Box sx={classesTooltip.alertText}>{alertContentText}</Box>
          </Box>
          {additionalOptions?.isTransaction ? (
            <>
              <Loader isLoading={true} size="xxs" isBlock={false} />
            </>
          ) : (
            <Button
              sx={classesTooltip.alertDismissButton}
              size="small"
              onClick={() => closeSnackbar(key)}
            >
              <IconDismissImage src={closeImage} alt="Close" />
            </Button>
          )}
        </Box>
      ),
    });
  };

  const logoStyles = {
    width: "35px",
    paddingRight: "5px",
  };

  const invokeCxyzAlert = (
    message: string,
    additionalOptions?: ITransactionAlertProp
  ) => {
    return invokeAlert(cropxyzIcon, message, logoStyles, additionalOptions);
  };

  const invokeMetaMaskAlert = (
    message: string,
    additionalOptions?: ITransactionAlertProp
  ) => {
    return invokeAlert(metaMaskIcon, message, logoStyles, additionalOptions);
  };

  return { invokeAlert, invokeCxyzAlert, invokeMetaMaskAlert };
};
