const abi: any[] = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "target",
        type: "address",
      },
    ],
    name: "AddressEmptyCode",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "implementation",
        type: "address",
      },
    ],
    name: "ERC1967InvalidImplementation",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC1967NonPayable",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC721EnumerableForbiddenBatchMint",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "ERC721IncorrectOwner",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ERC721InsufficientApproval",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "approver",
        type: "address",
      },
    ],
    name: "ERC721InvalidApprover",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "ERC721InvalidOperator",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "ERC721InvalidOwner",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
    ],
    name: "ERC721InvalidReceiver",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "ERC721InvalidSender",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ERC721NonexistentToken",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "ERC721OutOfBoundsIndex",
    type: "error",
  },
  {
    inputs: [],
    name: "EnforcedPause",
    type: "error",
  },
  {
    inputs: [],
    name: "ExpectedPause",
    type: "error",
  },
  {
    inputs: [],
    name: "FailedInnerCall",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidInitialization",
    type: "error",
  },
  {
    inputs: [],
    name: "NotInitializing",
    type: "error",
  },
  {
    inputs: [],
    name: "ReentrancyGuardReentrantCall",
    type: "error",
  },
  {
    inputs: [],
    name: "UUPSUnauthorizedCallContext",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "slot",
        type: "bytes32",
      },
    ],
    name: "UUPSUnsupportedProxiableUUID",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "oldAddressStore",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newAddressStore",
        type: "address",
      },
    ],
    name: "AddressStoreChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint64",
        name: "version",
        type: "uint64",
      },
    ],
    name: "Initialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "minter",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "season",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "string",
        name: "plotTypeName",
        type: "string",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "plotTypeId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "width",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "height",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "tileArea",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "baseGrowthSpeed",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "baseYield",
        type: "uint8",
      },
    ],
    name: "MintedPlotAdmin",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "minter",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "season",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "string",
        name: "plotTypeName",
        type: "string",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "plotTypeId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "width",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "height",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "tileArea",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "baseGrowthSpeed",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "baseYield",
        type: "uint8",
      },
    ],
    name: "MintedPlotPackActivate",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Unpaused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: true,
        internalType: "string",
        name: "oldTokenURIHash",
        type: "string",
      },
      {
        indexed: true,
        internalType: "string",
        name: "newTokenURIHash",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "oldTokenURI",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "newTokenURI",
        type: "string",
      },
    ],
    name: "UpdatedBaseTokenURI",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: true,
        internalType: "string",
        name: "oldOverallDescriptionHash",
        type: "string",
      },
      {
        indexed: true,
        internalType: "string",
        name: "newOverallDescriptionHash",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "oldOverallDescription",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "newOverallDescription",
        type: "string",
      },
    ],
    name: "UpdatedOverallDescription",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "implementation",
        type: "address",
      },
    ],
    name: "Upgraded",
    type: "event",
  },
  {
    inputs: [],
    name: "APP_KEY_SKYONEER",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "APP_KEY_SUGARCANE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "APP_KEY_SYSTEM",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_DAPP_ACCOUNT_REGISTRY_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_ENGINE_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_FRAME_CONTROLLER_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_GOLD_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_PLOT_ACTIONS_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_PLOT_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_PLOT_METADATA_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_PLOT_TYPE_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_POOL_CORE_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_POOL_DETAILS_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_ROLE_REGISTRY_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_STARTER_PACK_ACTIVATION_DEST_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_STARTER_PACK_ACTIVATION_SRC_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_STARTER_PACK_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_SYSTEM_STATUS_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_TEAM_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_TIME_BREAKDOWN_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_TREASURY_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "AS_YIELD_TABLE_ADDRESS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "RR_DAPP_ADMIN_ROLE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "RR_DAPP_PAUSER_ROLE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "RR_DAPP_UPGRADER_ROLE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "RR_GAME_MINTER_ROLE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "RR_GAME_ROLE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "UPGRADE_INTERFACE_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "accountRegistry",
    outputs: [
      {
        internalType: "contract ISugarcaneAccountRegistryV1",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "addressStore",
    outputs: [
      {
        internalType: "contract IAddressStore",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "baseTokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "burn",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "gameEngine",
    outputs: [
      {
        internalType: "contract IGameEngine",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getApproved",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "goldAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "addressStoreAddress_",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "plotTypeName_",
        type: "string",
      },
      {
        internalType: "address",
        name: "to_",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "season_",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "width_",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "height_",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "tileArea_",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "baseGrowthSpeed_",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "baseYield_",
        type: "uint8",
      },
    ],
    name: "mintAdmin",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "plotType",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "element",
                type: "uint256",
              },
              {
                internalType: "uint16",
                name: "amount",
                type: "uint16",
              },
              {
                components: [
                  {
                    internalType: "uint64",
                    name: "start",
                    type: "uint64",
                  },
                  {
                    internalType: "uint64",
                    name: "readyDelta",
                    type: "uint64",
                  },
                  {
                    internalType: "uint64",
                    name: "expiredDelta",
                    type: "uint64",
                  },
                ],
                internalType: "struct PlotLib.StakedTimes",
                name: "times",
                type: "tuple",
              },
              {
                components: [
                  {
                    internalType: "uint24",
                    name: "clears",
                    type: "uint24",
                  },
                  {
                    internalType: "uint24",
                    name: "deaths",
                    type: "uint24",
                  },
                  {
                    internalType: "uint24",
                    name: "harvests",
                    type: "uint24",
                  },
                ],
                internalType: "struct PlotLib.StakedCounts",
                name: "counts",
                type: "tuple",
              },
            ],
            internalType: "struct PlotLib.StakedTraits",
            name: "staked",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8",
                name: "tileArea",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "width",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "height",
                type: "uint8",
              },
            ],
            internalType: "struct PlotLib.PlotDimensions",
            name: "dimensions",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8",
                name: "speed",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "yield",
                type: "uint8",
              },
            ],
            internalType: "struct PlotLib.BaseStats",
            name: "stats",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8",
                name: "season",
                type: "uint8",
              },
              {
                internalType: "bool",
                name: "disabled",
                type: "bool",
              },
            ],
            internalType: "struct PlotLib.BaseTraits",
            name: "base",
            type: "tuple",
          },
        ],
        internalType: "struct PlotLib.PlotTraits",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "plotTypeName_",
        type: "string",
      },
      {
        internalType: "address",
        name: "to_",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "season_",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "width_",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "height_",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "tileArea_",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "baseGrowthSpeed_",
        type: "uint8",
      },
      {
        internalType: "uint8",
        name: "baseYield_",
        type: "uint8",
      },
    ],
    name: "mintPackActivation",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "plotType",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "element",
                type: "uint256",
              },
              {
                internalType: "uint16",
                name: "amount",
                type: "uint16",
              },
              {
                components: [
                  {
                    internalType: "uint64",
                    name: "start",
                    type: "uint64",
                  },
                  {
                    internalType: "uint64",
                    name: "readyDelta",
                    type: "uint64",
                  },
                  {
                    internalType: "uint64",
                    name: "expiredDelta",
                    type: "uint64",
                  },
                ],
                internalType: "struct PlotLib.StakedTimes",
                name: "times",
                type: "tuple",
              },
              {
                components: [
                  {
                    internalType: "uint24",
                    name: "clears",
                    type: "uint24",
                  },
                  {
                    internalType: "uint24",
                    name: "deaths",
                    type: "uint24",
                  },
                  {
                    internalType: "uint24",
                    name: "harvests",
                    type: "uint24",
                  },
                ],
                internalType: "struct PlotLib.StakedCounts",
                name: "counts",
                type: "tuple",
              },
            ],
            internalType: "struct PlotLib.StakedTraits",
            name: "staked",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8",
                name: "tileArea",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "width",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "height",
                type: "uint8",
              },
            ],
            internalType: "struct PlotLib.PlotDimensions",
            name: "dimensions",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8",
                name: "speed",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "yield",
                type: "uint8",
              },
            ],
            internalType: "struct PlotLib.BaseStats",
            name: "stats",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8",
                name: "season",
                type: "uint8",
              },
              {
                internalType: "bool",
                name: "disabled",
                type: "bool",
              },
            ],
            internalType: "struct PlotLib.BaseTraits",
            name: "base",
            type: "tuple",
          },
        ],
        internalType: "struct PlotLib.PlotTraits",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "overallDescription",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ownerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pausedGame",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "plotType",
    outputs: [
      {
        internalType: "contract IPlotType",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "poolAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "proxiableUUID",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "roleRegistry",
    outputs: [
      {
        internalType: "contract IRoleRegistry",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "newBaseTokenURI",
        type: "string",
      },
    ],
    name: "setBaseTokenURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "newOverallDescriptionText",
        type: "string",
      },
    ],
    name: "setOverallDescription",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "systemStatus",
    outputs: [
      {
        internalType: "contract ISystemStatus",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "timeBreakdown",
    outputs: [
      {
        internalType: "contract ITimeBreakdown",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "tokenByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "tokenOfOwnerByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalMint",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "plotId",
        type: "uint256",
      },
    ],
    name: "traits",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "plotType",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "element",
                type: "uint256",
              },
              {
                internalType: "uint16",
                name: "amount",
                type: "uint16",
              },
              {
                components: [
                  {
                    internalType: "uint64",
                    name: "start",
                    type: "uint64",
                  },
                  {
                    internalType: "uint64",
                    name: "readyDelta",
                    type: "uint64",
                  },
                  {
                    internalType: "uint64",
                    name: "expiredDelta",
                    type: "uint64",
                  },
                ],
                internalType: "struct PlotLib.StakedTimes",
                name: "times",
                type: "tuple",
              },
              {
                components: [
                  {
                    internalType: "uint24",
                    name: "clears",
                    type: "uint24",
                  },
                  {
                    internalType: "uint24",
                    name: "deaths",
                    type: "uint24",
                  },
                  {
                    internalType: "uint24",
                    name: "harvests",
                    type: "uint24",
                  },
                ],
                internalType: "struct PlotLib.StakedCounts",
                name: "counts",
                type: "tuple",
              },
            ],
            internalType: "struct PlotLib.StakedTraits",
            name: "staked",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8",
                name: "tileArea",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "width",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "height",
                type: "uint8",
              },
            ],
            internalType: "struct PlotLib.PlotDimensions",
            name: "dimensions",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8",
                name: "speed",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "yield",
                type: "uint8",
              },
            ],
            internalType: "struct PlotLib.BaseStats",
            name: "stats",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint8",
                name: "season",
                type: "uint8",
              },
              {
                internalType: "bool",
                name: "disabled",
                type: "bool",
              },
            ],
            internalType: "struct PlotLib.BaseTraits",
            name: "base",
            type: "tuple",
          },
        ],
        internalType: "struct PlotLib.PlotTraits",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newAddressStore",
        type: "address",
      },
    ],
    name: "transferAddressStore",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "treasuryAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "plotId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "element",
            type: "uint256",
          },
          {
            internalType: "uint16",
            name: "amount",
            type: "uint16",
          },
          {
            components: [
              {
                internalType: "uint64",
                name: "start",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "readyDelta",
                type: "uint64",
              },
              {
                internalType: "uint64",
                name: "expiredDelta",
                type: "uint64",
              },
            ],
            internalType: "struct PlotLib.StakedTimes",
            name: "times",
            type: "tuple",
          },
          {
            components: [
              {
                internalType: "uint24",
                name: "clears",
                type: "uint24",
              },
              {
                internalType: "uint24",
                name: "deaths",
                type: "uint24",
              },
              {
                internalType: "uint24",
                name: "harvests",
                type: "uint24",
              },
            ],
            internalType: "struct PlotLib.StakedCounts",
            name: "counts",
            type: "tuple",
          },
        ],
        internalType: "struct PlotLib.StakedTraits",
        name: "updatedPlotStakedTraits",
        type: "tuple",
      },
    ],
    name: "updateStakedDetails",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newImplementation",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "upgradeToAndCall",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "version",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "versionGameAsset",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "versionGameStatus",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "versionGameUtil",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "versionSystemPointers",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
    ],
    name: "walletOfOwner",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "yieldTable",
    outputs: [
      {
        internalType: "contract IYieldTable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export default abi;
