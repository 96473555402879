import { useEffect, useState } from "react";
import { SnackbarProvider } from "notistack";

import "./App.style.css";
import "./components/PlotMapContainer.style.css";

import App from "./App";

import ButtonContainerNotLoggedIn from "./components/ButtonContainerNotLoggedIn";
import GetStartedButton from "./components/GetStartedButton";
import MapBackgroundWrapper from "./components/MapBackgroundWrapper";
import PlotMapNoPlots from "./components/PlotMapNoPlots";
import PacksModal from "./components/modals/packs/PacksModal";

import { useAccountRegistration } from "./interfaces/accountRegistration";
import { useConnectedAccount } from "./interfaces/blockchain";
import { usePacksOwnedStatus } from "./interfaces/wallet";

function AppAccountWrapper() {
  const [openPacks, setOpenPacks] = useState(false);
  const [currentOnchainId, setCurrentOnchainId] = useState<string | null>(null);
  const { authActions, authState, connectedAccount } = useConnectedAccount();
  const { onchainId } = connectedAccount;

  const { accountDetails, isLoadingAccountDetails } =
    useAccountRegistration(onchainId);
  const { isLoadingPackSet, packsListing, refreshUserOwnedPacks } =
    usePacksOwnedStatus();

  const isLoading =
    !authState.ready ||
    isLoadingAccountDetails ||
    (!isLoadingAccountDetails && authState.authenticated && !accountDetails);

  useEffect(() => {
    if (
      !isLoadingPackSet &&
      !isLoading &&
      !accountDetails?.isRegistered &&
      !!connectedAccount.onchainId
    ) {
      setOpenPacks(true);
    }
  }, [isLoading, isLoadingPackSet]);

  useEffect(() => {
    if (currentOnchainId !== onchainId) {
      setCurrentOnchainId(onchainId);
      if (openPacks) {
        setOpenPacks(false);
      }
    }
  }, [onchainId]);

  const handleOpenPacks = () => {
    refreshUserOwnedPacks();

    setOpenPacks(true);
  };

  const handleClosePacks = () => {
    setOpenPacks(false);
  };

  const handleRefreshScreen = () => {
    window.location.reload();
  };

  return (
    <SnackbarProvider
      maxSnack={10}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {authState.ready && !isLoading ? (
        !authState.authenticated ? (
          <MapBackgroundWrapper>
            <GetStartedButton />
          </MapBackgroundWrapper>
        ) : accountDetails?.isRegistered ? (
          <App
            accountDetails={accountDetails}
            handleOpenPacks={handleOpenPacks}
          />
        ) : (
          <MapBackgroundWrapper>
            <>
              <PlotMapNoPlots isLoading={false} />

              <ButtonContainerNotLoggedIn handleOpenPacks={handleOpenPacks} />
            </>
          </MapBackgroundWrapper>
        )
      ) : (
        <MapBackgroundWrapper>
          {isLoading ? (
            <div className="NoPlots-text">Loading...</div>
          ) : (
            <div className="NoPlots-text">Error...</div>
          )}
        </MapBackgroundWrapper>
      )}

      <PacksModal
        accountDetails={accountDetails}
        open={openPacks}
        isLoading={isLoadingPackSet || isLoadingAccountDetails}
        packsListing={packsListing}
        onClose={handleClosePacks}
        refreshUserOwnedPlots={handleRefreshScreen}
        refreshWalletGoldState={handleRefreshScreen}
      />
    </SnackbarProvider>
  );
}

export default AppAccountWrapper;
