import iconBlackberry from "../../../assets/crops/berry_bush/blackberry.png";

import tileBlackberryEarly from "../../../assets/tiles/plants/berry_bush/tile-blackberry-1.png";
import tileBlackberryFull from "../../../assets/tiles/plants/berry_bush/tile-blackberry-2.png";
import tileBlackberryDead from "../../../assets/tiles/plants/berry_bush/tile-blackberry-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  BerryBushPlantNameType,
  BerryBushPlantDisplayNameType,
  BerryBushPlantBlockchainNameType,
} from "./BerryBush.naming";

const PlantBlackberry: IPlantType = {
  name: BerryBushPlantNameType.blackberry,
  blockchainName: BerryBushPlantBlockchainNameType.blackberry,
  displayName: BerryBushPlantDisplayNameType.blackberry,
  plotType: PlantPlotType.berryBush,
  icon: iconBlackberry,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.black,
    [PlantGrowthStage.sprout]: SproutType.bush,
    [PlantGrowthStage.early]: tileBlackberryEarly,
    [PlantGrowthStage.full]: tileBlackberryFull,
    [PlantGrowthStage.dead]: tileBlackberryDead,
  },
};

export default PlantBlackberry;
