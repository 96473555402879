import iconHop from "../../../assets/crops/flower_bed/hop.png";

import tileHopEarly from "../../../assets/tiles/plants/flower_bed/tile-hop-1.png";
import tileHopFull from "../../../assets/tiles/plants/flower_bed/tile-hop-2.png";
import tileHopDead from "../../../assets/tiles/plants/flower_bed/tile-hop-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  FlowerBedPlantNameType,
  FlowerBedPlantDisplayNameType,
  FlowerBedPlantBlockchainNameType,
} from "./FlowerBed.naming";

const PlantHop: IPlantType = {
  name: FlowerBedPlantNameType.hop,
  blockchainName: FlowerBedPlantBlockchainNameType.hop,
  displayName: FlowerBedPlantDisplayNameType.hop,
  plotType: PlantPlotType.flowerBed,
  icon: iconHop,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.green,
    [PlantGrowthStage.sprout]: SproutType.vineStake,
    [PlantGrowthStage.early]: tileHopEarly,
    [PlantGrowthStage.full]: tileHopFull,
    [PlantGrowthStage.dead]: tileHopDead,
  },
};

export default PlantHop;
