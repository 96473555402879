import plot_4_4 from "../../assets/hud/packs/plots/filled/plot-filled-4x4.svg";

import { IPlotSizeDetails, PlotSizeType } from "../PlantPlot.interfaces";

const PlotSize4x4: IPlotSizeDetails = {
  name: PlotSizeType.size4x4,
  image: plot_4_4,
};

export default PlotSize4x4;
