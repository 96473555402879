import { useState } from "react";
import BigNumber from "bignumber.js";

import "./CropModalTileSection.style.css";

import {
  IPlantType,
  IPlotSet,
  PlantPlotShortName,
  PlantPlotType,
} from "../../utils/PlantPlot.interfaces";

import CropModalTileRow from "./CropModalTileRow";

export interface ICropModalTileSectionProp {
  clickedPlot?: IPlotSet | null;
  cropBalances: any;
  cropDetails: any;
  goldBalance: BigNumber;
  isLoading: boolean;
  isLoadingAfterAction: boolean;
  isSelling: boolean;
  isStorageModal: boolean;
  panelTabs: PlantPlotType[];
  panelTitle: string;
  poolBalances: any;
  handleSelectCrop: (
    currentCropTile: IPlantType,
    cropBalance: BigNumber
  ) => void;
}

function CropModalTileSection({
  clickedPlot = null,
  cropDetails,
  cropBalances,
  goldBalance,
  isLoading,
  isLoadingAfterAction,
  isSelling,
  isStorageModal,
  panelTabs,
  panelTitle,
  poolBalances,
  handleSelectCrop,
}: ICropModalTileSectionProp) {
  const [activeTab, setActiveTab] = useState(panelTabs[0]);

  return (
    <div className={`Shell-available${isStorageModal ? " Shell-storage" : ""}`}>
      <div className="Shell-available_title">{panelTitle}</div>
      <div className="Shell-available_tabs">
        {panelTabs.map((currentTabTitle: PlantPlotType) => (
          <div
            key={`store_tab-${currentTabTitle}`}
            onClick={
              !isLoadingAfterAction
                ? () => setActiveTab(currentTabTitle)
                : () => {}
            }
            className={`CropTypeTab${
              activeTab === currentTabTitle ? " CropTypeTab-active" : ""
            }
            ${isLoadingAfterAction ? "CropTypeTab-disabled" : ""}`}
          >
            <div className="CropTypeTab-text">
              {PlantPlotShortName[currentTabTitle]}
            </div>
          </div>
        ))}
      </div>

      <CropModalTileRow
        activeTab={activeTab}
        clickedPlot={clickedPlot}
        cropBalances={cropBalances}
        cropDetails={cropDetails}
        goldBalance={goldBalance}
        isLoading={isLoading}
        isLoadingAfterAction={isLoadingAfterAction}
        isStorageModal={isStorageModal}
        isSelling={isSelling}
        isTop={true}
        poolBalances={poolBalances}
        handleSelectCrop={handleSelectCrop}
      />

      <CropModalTileRow
        activeTab={activeTab}
        clickedPlot={clickedPlot}
        cropBalances={cropBalances}
        cropDetails={cropDetails}
        goldBalance={goldBalance}
        isLoading={isLoading}
        isLoadingAfterAction={isLoadingAfterAction}
        isStorageModal={isStorageModal}
        isSelling={isSelling}
        isTop={false}
        poolBalances={poolBalances}
        handleSelectCrop={handleSelectCrop}
      />
    </div>
  );
}

export default CropModalTileSection;
