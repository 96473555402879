import Button from "@mui/material/Button";

import "./ButtonContainer.style.css";

import iconBackgroundImage from "../assets/hud/buttons/button-background.svg";
import packsIconImage from "../assets/hud/buttons/button-packs.png";

export interface IButtonContainerNotLoggedInProp {
  handleOpenPacks: () => void;
}

function ButtonContainerNotLoggedIn({
  handleOpenPacks,
}: IButtonContainerNotLoggedInProp) {
  return (
    <div className="SidebarButtonContainer">
      <div className="SidebarButtonContainer-shell">
        <Button className="SidebarButton" onClick={handleOpenPacks}>
          <img
            className="SidebarButton-background_image"
            src={iconBackgroundImage}
            alt="Packs"
          />
          <img
            className="SidebarButton-image SidebarButton-packs_image"
            src={packsIconImage}
            alt="Packs"
          />
        </Button>
        <div className="SidebarButton-text">Packs</div>
      </div>
    </div>
  );
}

export default ButtonContainerNotLoggedIn;
