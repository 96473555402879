export enum BeanGardenPlantNameType {
  blackBean = "blackBean",
  chickpea = "chickpea",
  greenPea = "greenPea",
  kidneyBean = "kidneyBean",
  pintoBean = "pintoBean",
}

export enum BeanGardenPlantDisplayNameType {
  blackBean = "Black bean",
  chickpea = "Chickpea",
  greenPea = "Green pea",
  kidneyBean = "Kidney bean",
  pintoBean = "Pinto bean",
}

export enum BeanGardenPlantBlockchainNameType {
  blackBean = "zBLACKBEAN",
  chickpea = "zCHICKPEA",
  greenPea = "zGREENPEA",
  kidneyBean = "zKIDNEYBEAN",
  pintoBean = "zPINTOBEAN",
}
