import iconPintoBean from "../../../assets/crops/bean_garden/pinto_bean.png";

import tilePintoBeanEarly from "../../../assets/tiles/plants/bean_garden/tile-pinto_bean-1.png";
import tilePintoBeanFull from "../../../assets/tiles/plants/bean_garden/tile-pinto_bean-2.png";
import tilePintoBeanDead from "../../../assets/tiles/plants/bean_garden/tile-pinto_bean-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  BeanGardenPlantNameType,
  BeanGardenPlantDisplayNameType,
  BeanGardenPlantBlockchainNameType,
} from "./BeanGarden.naming";

const PlantPintoBean: IPlantType = {
  name: BeanGardenPlantNameType.pintoBean,
  blockchainName: BeanGardenPlantBlockchainNameType.pintoBean,
  displayName: BeanGardenPlantDisplayNameType.pintoBean,
  plotType: PlantPlotType.beanGarden,
  icon: iconPintoBean,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.tan,
    [PlantGrowthStage.sprout]: SproutType.leaf,
    [PlantGrowthStage.early]: tilePintoBeanEarly,
    [PlantGrowthStage.full]: tilePintoBeanFull,
    [PlantGrowthStage.dead]: tilePintoBeanDead,
  },
};

export default PlantPintoBean;
