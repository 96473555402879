import Button from "@mui/material/Button";
import { Scrollbars } from "react-custom-scrollbars";

import "./PacksModalTileSection.style.css";

import { MINT_PACK_LINK, OPENSEA_PACK_LINK } from "../../../env";
import { IAccountRegistrationDetails } from "../../../interfaces/accountRegistration";
import { useConnectedAccount } from "../../../interfaces/blockchain";
import { IPackDetails } from "../../../utils/PlantPack.interfaces";

import CoreButton, { ButtonColor, ButtonSize } from "../../CoreButton";

export interface IPacksModalTileSectionProp {
  accountDetails: IAccountRegistrationDetails | null;
  isLoading: boolean;
  isLoadingAfterAction: boolean;
  packsListing: IPackDetails[];
  selectedPacks: Record<string, IPackDetails>;
  handleSelectAll: () => void;
  handleSelectPack: (currentSelection: IPackDetails) => void;
}

function PacksModalTileSection({
  // accountDetails,
  isLoading,
  isLoadingAfterAction,
  packsListing,
  selectedPacks,
  handleSelectAll,
  handleSelectPack,
}: IPacksModalTileSectionProp) {
  const { authActions, connectedAccount } = useConnectedAccount();

  const renderTiles = (startIndex: number) => {
    const endIndex = Math.min(startIndex + 3, packsListing.length);
    const tiles = packsListing.slice(startIndex, endIndex);

    return tiles.map((currentPackTile, seedTileIndex) => {
      return (
        <Button
          key={`shop_tile-${startIndex + seedTileIndex}`}
          className={`PackTile${
            currentPackTile.packId in selectedPacks ? " PackTile-selected" : ""
          }`}
          onClick={() => handleSelectPack(currentPackTile)}
          disabled={isLoadingAfterAction || isLoading}
        >
          <div className="PackTile-title">{currentPackTile.name}</div>

          <img
            className="PackTile-image"
            src={currentPackTile.image}
            alt="Tile"
          />
        </Button>
      );
    });
  };

  const renderTileSections = () => {
    const tileSections = [];
    for (let i = 0; i < packsListing.length; i += 3) {
      tileSections.push(
        <div key={`tile_section-${i}`} className="PackShell-pack_tile_holder">
          {renderTiles(i)}
        </div>
      );
    }

    return tileSections;
  };

  const handleConnectWallet = async () => {
    await authActions.loginBaseWallet();
  };

  const handleDisconnectWallet = async () => {
    await authActions.logoutBaseWallet();
  };

  const handleOpenMintPack = () => {
    const win: any = window.open(MINT_PACK_LINK, "_blank");
    win.focus();
  };

  const handleOpenseaPack = () => {
    const win: any = window.open(OPENSEA_PACK_LINK, "_blank");
    win.focus();
  };

  return (
    <div className="PackShell-available">
      {packsListing.length > 0 && (
        <div className="PackShell-available_title">Available Packs</div>
      )}

      <div className="PackShell-packs_listing">
        {!connectedAccount.base ? (
          <>
            <div className="NoPacks-container">
              <div className="NoPlots-text">Connect Wallet</div>
              <div className="NoPlots-subtext">
                If you have a pack that you would like to convert into plots and
                farming resources, connect the wallet with the pack in it then
                activate the pack.
              </div>
            </div>

            <div className="PackShell-buy_pack_button">
              <CoreButton
                disabled={isLoadingAfterAction || isLoading}
                onClickHandler={handleConnectWallet}
                buttonText="Connect Wallet"
                color={ButtonColor.green}
                size={ButtonSize.lg}
                textSize={ButtonSize.lg}
              />
              <CoreButton
                disabled={isLoadingAfterAction || isLoading}
                onClickHandler={handleOpenMintPack}
                buttonText="Buy Pack"
                color={ButtonColor.green}
                size={ButtonSize.lg}
                textSize={ButtonSize.lg}
              />
            </div>
          </>
        ) : packsListing.length > 0 ? (
          <Scrollbars>{renderTileSections()}</Scrollbars>
        ) : (
          <>
            <div className="NoPacks-container">
              <div className="NoPlots-text">No Packs in this Wallet</div>
              <div className="NoPlots-subtext">
                If you recently purchased a pack NFT and it does not show up
                here, send us a message on Farcaster or X/Twitter @Skyoneer and
                we can help.
              </div>
            </div>

            <div className="PackShell-buy_pack_button">
              <CoreButton
                disabled={isLoadingAfterAction || isLoading}
                onClickHandler={handleOpenMintPack}
                buttonText="Buy Pack"
                color={ButtonColor.green}
                size={ButtonSize.lg}
                textSize={ButtonSize.lg}
              />
            </div>
          </>
        )}
      </div>

      <div className="PackShell-select_all_button">
        {packsListing.length > 0 && (
          <CoreButton
            disabled={
              isLoadingAfterAction || isLoading || packsListing.length === 0
            }
            onClickHandler={handleSelectAll}
            buttonText={
              Object.keys(selectedPacks).length !== packsListing.length ||
              packsListing.length === 0
                ? "Select All"
                : "Deselect All"
            }
            color={
              packsListing.length > 0 ? ButtonColor.green : ButtonColor.gray
            }
            size={ButtonSize.md}
            textSize={ButtonSize.md}
          />
        )}
        {!!connectedAccount.base && (
          <CoreButton
            disabled={isLoadingAfterAction || isLoading}
            onClickHandler={handleDisconnectWallet}
            buttonText="Disconnect"
            color={ButtonColor.red}
            size={ButtonSize.md}
            textSize={ButtonSize.md}
          />
        )}
      </div>
    </div>
  );
}

export default PacksModalTileSection;
