import iconBlueberry from "../../../assets/crops/berry_bush/blueberry.png";

import tileBlueberryEarly from "../../../assets/tiles/plants/berry_bush/tile-blueberry-1.png";
import tileBlueberryFull from "../../../assets/tiles/plants/berry_bush/tile-blueberry-2.png";
import tileBlueberryDead from "../../../assets/tiles/plants/berry_bush/tile-blueberry-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  BerryBushPlantNameType,
  BerryBushPlantDisplayNameType,
  BerryBushPlantBlockchainNameType,
} from "./BerryBush.naming";

const PlantBlueberry: IPlantType = {
  name: BerryBushPlantNameType.blueberry,
  blockchainName: BerryBushPlantBlockchainNameType.blueberry,
  displayName: BerryBushPlantDisplayNameType.blueberry,
  plotType: PlantPlotType.berryBush,
  icon: iconBlueberry,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.green,
    [PlantGrowthStage.sprout]: SproutType.bush,
    [PlantGrowthStage.early]: tileBlueberryEarly,
    [PlantGrowthStage.full]: tileBlueberryFull,
    [PlantGrowthStage.dead]: tileBlueberryDead,
  },
};

export default PlantBlueberry;
