import iconLily from "../../../assets/crops/flower_bed/lily.png";

import tileLilyEarly from "../../../assets/tiles/plants/flower_bed/tile-lily-1.png";
import tileLilyFull from "../../../assets/tiles/plants/flower_bed/tile-lily-2.png";
import tileLilyDead from "../../../assets/tiles/plants/flower_bed/tile-lily-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  FlowerBedPlantNameType,
  FlowerBedPlantDisplayNameType,
  FlowerBedPlantBlockchainNameType,
} from "./FlowerBed.naming";

const PlantLily: IPlantType = {
  name: FlowerBedPlantNameType.lily,
  blockchainName: FlowerBedPlantBlockchainNameType.lily,
  displayName: FlowerBedPlantDisplayNameType.lily,
  plotType: PlantPlotType.flowerBed,
  icon: iconLily,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.tan,
    [PlantGrowthStage.sprout]: SproutType.leaf,
    [PlantGrowthStage.early]: tileLilyEarly,
    [PlantGrowthStage.full]: tileLilyFull,
    [PlantGrowthStage.dead]: tileLilyDead,
  },
};

export default PlantLily;
