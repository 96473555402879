import { getGoldSmartAccount } from "../modules/aa.module";

import { getBlockchainErrorMessage } from "./blockchain";
import {
  IConnectedBaseAccount,
  IConnectedGoldAccount,
} from "./contracts/contracts.interfaces";
import { getContractSet } from "./contracts";

// Activate Farmer Starter Packs
export const activateFarmerStarterPacks = async (
  connectedBaseAccount: IConnectedBaseAccount,
  connectedGoldAccount: IConnectedGoldAccount,
  isActivateAll: boolean,
  packIds: string[]
) => {
  const { smartAccountAddress } = await getGoldSmartAccount({
    connectedGoldAccount,
  });

  // const signature = await getSignature({
  //   connectedGoldAccount,
  //   signTypedDataV4Payload: contractCall.functionDetails.dataPayload,
  // });

  const signer = await connectedBaseAccount.wallet.provider.getSigner();
  const { starterPack } = getContractSet(signer);
  const addressListing: string[] = [
    connectedGoldAccount.address,
    smartAccountAddress,
  ];

  try {
    const transactionDetails =
      packIds.length === 1
        ? await starterPack.contract.activateOne(addressListing, packIds[0])
        : isActivateAll
        ? await starterPack.contract.activateAll(addressListing)
        : await starterPack.contract.activateList(addressListing, packIds);

    await transactionDetails.wait();

    return {
      isSuccess: true,
      message: {
        text: "Successfully activated packs.",
        isMetaMask: false,
      },
      transactionDetails,
    };
  } catch (error: any) {
    return {
      isSuccess: false,
      message: getBlockchainErrorMessage(error),
      transactionDetails: null,
    };
  }
};
