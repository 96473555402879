import { FlowerBedPlantNameType } from "./FlowerBed.naming";

import PlantDaisy from "./PlantDaisy";
import PlantHop from "./PlantHop";
import PlantLily from "./PlantLily";
import PlantRose from "./PlantRose";
import PlantSunflower from "./PlantSunflower";

export const FlowerBedMapping = {
  [FlowerBedPlantNameType.daisy]: PlantDaisy,
  [FlowerBedPlantNameType.hop]: PlantHop,
  [FlowerBedPlantNameType.lily]: PlantLily,
  [FlowerBedPlantNameType.rose]: PlantRose,
  [FlowerBedPlantNameType.sunflower]: PlantSunflower,
};
