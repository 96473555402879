import {
  IPlantType,
  IPlotDimensions,
  PlantPlotType,
  PlotSizeType,
} from "./PlantPlot.interfaces";
import { PlantPlotMapping } from "./PlantPlot.mapping";

export enum FertilizerType {
  "10k" = "10k",
  "20k" = "20k",
}

export enum PlotSpeedType {
  SLOW = "SLOW",
  MEDIUM = "MEDIUM",
  FAST = "FAST",
}

export enum PlotYieldType {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

// // // // // // // // // // // // // // // // // // // //
// TRANSLATIONS
// // // // // // // // // // // // // // // // // // // //

export const goldBracketsList: Record<number, number[]> = {
  1: [
    // 1x1
    202816,
    // 2x2
    540842,
    // 3x3
    912670,
    // 4x4
    1081683,
    // 5x5
    1352104,
  ],
  2: [
    // 1x1
    304200,
    // 2x2
    811200,
    // 3x3
    1369000,
    // 4x4
    1622500,
    // 5x5
    2028200,
  ],
};

export const plotSizeList: PlotSizeType[] = [
  // 1x1
  PlotSizeType.size1x1,
  // 2x2
  PlotSizeType.size2x2,
  // 3x3
  PlotSizeType.size3x3,
  // 4x4
  PlotSizeType.size4x4,
  // 5x5
  PlotSizeType.size5x5,
];

export const plotTypeTranslation: Record<string, PlantPlotType> = {
  "Grain Field": PlantPlotType.grainField,
  "Bean Garden": PlantPlotType.beanGarden,
  "Fruit Vine": PlantPlotType.fruitVine,
  "Berry Bush": PlantPlotType.berryBush,
  "Flower Bed": PlantPlotType.flowerBed,
};

export const fertilizerTranslation: Record<string, FertilizerType | null> = {
  "Grow 10k": FertilizerType["10k"],
  "Grow 20k": FertilizerType["20k"],
  None: null,
};

export const speedTranslation: Record<string, PlotSpeedType> = {
  Slow: PlotSpeedType.SLOW,
  Medium: PlotSpeedType.MEDIUM,
  Fast: PlotSpeedType.FAST,
};

export const yieldTranslation: Record<string, PlotYieldType> = {
  Low: PlotYieldType.LOW,
  Medium: PlotYieldType.MEDIUM,
  High: PlotYieldType.HIGH,
};

export const plantTypeTranslation: Record<string, IPlantType> = {
  // GRAIN FIELD
  Corn: PlantPlotMapping.grainField.corn,
  Oats: PlantPlotMapping.grainField.oat,
  Wheat: PlantPlotMapping.grainField.wheat,
  "Wild Rice": PlantPlotMapping.grainField.wildRice,

  // BEAN GARDEN
  "Black beans": PlantPlotMapping.beanGarden.blackBean,
  Chickpeas: PlantPlotMapping.beanGarden.chickpea,
  "Green peas": PlantPlotMapping.beanGarden.greenPea,
  "Kidney beans": PlantPlotMapping.beanGarden.kidneyBean,
  "Pinto beans": PlantPlotMapping.beanGarden.pintoBean,

  // FRUIT VINE
  Cantaloupe: PlantPlotMapping.fruitVine.cantaloupe,
  Grapes: PlantPlotMapping.fruitVine.grape,
  Pumpkins: PlantPlotMapping.fruitVine.pumpkin,
  Tomatoes: PlantPlotMapping.fruitVine.tomato,
  Watermelon: PlantPlotMapping.fruitVine.watermelon,

  // BERRY BUSH
  Blackberries: PlantPlotMapping.berryBush.blackberry,
  Blueberries: PlantPlotMapping.berryBush.blueberry,
  "Goji berries": PlantPlotMapping.berryBush.gojiBerry,
  Raspberries: PlantPlotMapping.berryBush.raspberry,
  Strawberries: PlantPlotMapping.berryBush.strawberry,

  // FLOWER BED
  Daisies: PlantPlotMapping.flowerBed.daisy,
  Hops: PlantPlotMapping.flowerBed.hop,
  Lilies: PlantPlotMapping.flowerBed.lily,
  Roses: PlantPlotMapping.flowerBed.rose,
  Sunflowers: PlantPlotMapping.flowerBed.sunflower,
};

// // // // // // // // // // // // // // // // // // // //
// INTERFACES
// // // // // // // // // // // // // // // // // // // //
export interface IPackDetails {
  packId: string;
  name: string;
  image: string;
  fertilizer: FertilizerType | null;
  goldCount: number;
  plotDetails: {
    baseSpeed: PlotSpeedType;
    baseYield: PlotYieldType;
    plotCount: number;
    plotDimensions: IPlotDimensions;
    plotSizeType: PlotSizeType;
    plotType: PlantPlotType;
  };
  plantDetails: {
    plantType: IPlantType;
    plantCount: number;
  } | null;
}

export interface IPackRawAttribute {
  trait_type: string;
  value: string;
}

export interface IPackRaw {
  name: string;
  timeLastUpdated: string;
  tokenId: string;
  raw: {
    metadata: {
      name: string;
      description: string;
      image: string;
      attributes: IPackRawAttribute[];
    };
    tokenUri: string;
  };
}
