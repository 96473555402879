import iconGrape from "../../../assets/crops/fruit_vine/grape.png";

import tileGrapeEarly from "../../../assets/tiles/plants/fruit_vine/tile-grape-1.png";
import tileGrapeFull from "../../../assets/tiles/plants/fruit_vine/tile-grape-2.png";
import tileGrapeDead from "../../../assets/tiles/plants/fruit_vine/tile-grape-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  FruitVinePlantNameType,
  FruitVinePlantDisplayNameType,
  FruitVinePlantBlockchainNameType,
} from "./FruitVine.naming";

const PlantGrape: IPlantType = {
  name: FruitVinePlantNameType.grape,
  blockchainName: FruitVinePlantBlockchainNameType.grape,
  displayName: FruitVinePlantDisplayNameType.grape,
  plotType: PlantPlotType.fruitVine,
  icon: iconGrape,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.purple,
    [PlantGrowthStage.sprout]: SproutType.vineStake,
    [PlantGrowthStage.early]: tileGrapeEarly,
    [PlantGrowthStage.full]: tileGrapeFull,
    [PlantGrowthStage.dead]: tileGrapeDead,
  },
};

export default PlantGrape;
