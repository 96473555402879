import "./PlotMapContainer.style.css";

import PlotMap from "./PlotMap";

import { MINIMUM_PLOT_TRANSITION_SIZE } from "../utils/constants";

import {
  calculatePlotWidth,
  IPlotDimensions,
  IPlotSet,
} from "../utils/PlantPlot.interfaces";

export interface IPlotMapContainerProp {
  isLoading: boolean;
  showPlotData: boolean;

  plotsLoadingState: any;
  plotSet: string[];

  cropDetails: any;
  plotDetails: any;

  currentPlotPage: number;
  maxPlotPage: number;
  setCurrentPlotPage: (updatedCurrentPlotPage: number) => void;

  handleClickPlot: (plotSet: IPlotSet) => void;
}

function PlotMapContainer({
  isLoading,
  showPlotData,

  plotsLoadingState,
  plotSet,

  cropDetails,
  plotDetails,

  currentPlotPage,
  maxPlotPage,
  setCurrentPlotPage,

  handleClickPlot,
}: IPlotMapContainerProp) {
  const filledPlotSet: any[] = [];
  plotSet.forEach((currentPlotId) => {
    if (currentPlotId in plotDetails && !!plotDetails[currentPlotId]) {
      filledPlotSet.push(plotDetails[currentPlotId]);
    }
  });

  const rowSeparatedPlots: IPlotSet[][] = [];
  const firstElementPlots: IPlotSet[] = [];

  // At a certain size drop down from 3 plots in a row to 2
  const rowLength = filledPlotSet.length > MINIMUM_PLOT_TRANSITION_SIZE ? 3 : 2;

  let maxPlotWidth: number = -Infinity;
  let maxPlotDimensions: IPlotDimensions = {
    height: 0,
    width: 0,
    tileArea: 9,
  };

  for (let plotIndex = 0; plotIndex < 3; plotIndex++) {
    const currentPlotsIndex = rowLength * plotIndex;
    const currentPlotRow: IPlotSet[] = [];
    let currentRowWidth = 0;
    for (
      let specificPlotIndex = 0;
      specificPlotIndex < rowLength;
      specificPlotIndex++
    ) {
      // Calculate the current index
      const adjustedPlotIndex = currentPlotsIndex + specificPlotIndex;
      if (adjustedPlotIndex < filledPlotSet.length) {
        const currentPlotSet = filledPlotSet[adjustedPlotIndex];
        currentPlotRow.push(currentPlotSet);

        // Add onto the overall row width
        const currentPlotWidth = calculatePlotWidth(
          currentPlotSet.plotDimensions
        );
        currentRowWidth += currentPlotWidth;

        if (currentPlotWidth > maxPlotWidth) {
          // Update the width of the largest middle element
          maxPlotWidth = currentPlotWidth;
          maxPlotDimensions = currentPlotSet.plotDimensions;
        }
      }
    }

    // Pull out the first plot and store it in the array
    if (plotIndex >= firstElementPlots.length) {
      firstElementPlots.push(filledPlotSet[currentPlotsIndex]);
    }

    if (currentRowWidth > 0) {
      rowSeparatedPlots.push(currentPlotRow);
    }
  }

  const mapStyle: any = {};

  if (plotSet.length === 0) {
    mapStyle.overflowY = "hidden";
  }

  return (
    <div id="map" className="Map" style={mapStyle}>
      <PlotMap
        showPlotData={showPlotData}
        isLoading={isLoading}
        cropDetails={cropDetails}
        plotsLoadingState={plotsLoadingState}
        plotSet={filledPlotSet}
        rowSeparatedPlots={rowSeparatedPlots}
        rowLength={rowLength}
        maxPlotDimensions={maxPlotDimensions}
        maxPlotWidth={maxPlotWidth}
        currentPlotPage={currentPlotPage}
        maxPlotPage={maxPlotPage}
        setCurrentPlotPage={setCurrentPlotPage}
        handleClickPlot={handleClickPlot}
      />
    </div>
  );
}

export default PlotMapContainer;
