import { useState } from "react";
import BigNumber from "bignumber.js";

import Dialog from "@mui/material/Dialog";

import "../Modal.style.css";
import "./ShopModal.style.css";

import { IAccountRegistrationDetails } from "../../../interfaces/accountRegistration";
import {
  IBlockchainMessageResponse,
  initiateIncreaseAllowance,
  useConnectedAccount,
} from "../../../interfaces/blockchain";
import {
  IContractSet,
  ITokenContract,
} from "../../../interfaces/contracts/contracts.interfaces";
import {
  processSell,
  processSetPurchase,
} from "../../../interfaces/gameEngine";

import { IPlantType, PlantPlotType } from "../../../utils/PlantPlot.interfaces";
import { PlantPlotMapping } from "../../../utils/PlantPlot.mapping";
import {
  calculateBuyPrice,
  calculateSellPrice,
  getPoolObj,
} from "../../../utils/helpers";

import BackButton from "../../BackButton";
import CloseButton from "../../CloseButton";
import Loader from "../../Loader";
import { useToolType } from "../../tooltip/TooltipAlert";

import CropModalTileSection from "../CropModalTileSection";
import ShopModalSelectedCropSection from "./ShopModalSelectedCropSection";
import ShopModalCheckoutSection, {
  IShopModalCartItem,
} from "./ShopModalCheckoutSection";

export enum ShopPanelState {
  TILES = "tiles",
  AMOUNT = "amount",
  CART = "cart",
}

export interface IShopModalProp {
  accountDetails: IAccountRegistrationDetails;
  contracts: IContractSet;
  open: boolean;
  cropDetails: any;
  cropBalances: any;
  poolBalances: any;
  goldAllowance: BigNumber;
  goldBalance: BigNumber;
  isLoading: boolean;
  isSelling: boolean;
  cartItems: IShopModalCartItem[];
  selectedCrop: IPlantType | null;
  selectedCropAmount: number;
  shopPanel: ShopPanelState;
  onClose: () => void;
  refreshWalletCropBalances: () => void;
  refreshWalletGoldState: () => void;
  setCartItems: (element: IShopModalCartItem[]) => void;
  setSelectedCrop: (element: IPlantType | null) => void;
  setSelectedCropAmount: (element: number) => void;
  setShopPanel: (element: ShopPanelState) => void;
}

function ShopModal({
  accountDetails,
  contracts,
  open,
  cropBalances,
  cropDetails,
  goldAllowance,
  goldBalance,
  poolBalances,
  isLoading,
  isSelling,
  cartItems,
  selectedCrop,
  selectedCropAmount,
  shopPanel,
  onClose,
  refreshWalletCropBalances,
  refreshWalletGoldState,
  setCartItems,
  setSelectedCrop,
  setSelectedCropAmount,
  setShopPanel,
}: IShopModalProp) {
  const { connectedAccount } = useConnectedAccount();
  const { invokeCxyzAlert, invokeMetaMaskAlert } = useToolType();

  const [isLoadingPurchase, setIsLoadingPurchase] = useState(false);

  const panelTabs: PlantPlotType[] = Object.keys(
    PlantPlotMapping
  ) as PlantPlotType[];
  panelTabs.sort();

  const handleSelectCrop = (
    currentSelection: IPlantType,
    cropBalance: BigNumber
  ) => {
    setSelectedCrop(currentSelection);
    setSelectedCropAmount(
      !isSelling ||
        (!!cropBalances && cropBalances.balances[currentSelection.name].gt(0))
        ? 1
        : 0
    );

    setShopPanel(ShopPanelState.AMOUNT);
  };

  const completeBuyOrSellCallback = (message: IBlockchainMessageResponse) => {
    if (message.isMetaMask) {
      invokeMetaMaskAlert(message.text);
    } else {
      invokeCxyzAlert(message.text);
    }
    setIsLoadingPurchase(false);
  };

  const handleIncreaseAllowance = async (
    contract: ITokenContract,
    refreshWalletFunc: () => void
  ) => {
    if (
      !!connectedAccount.gold &&
      !isLoadingPurchase &&
      (!isSelling || !!selectedCrop)
    ) {
      setIsLoadingPurchase(true);

      const { isSuccess, transactionDetails, message } =
        await initiateIncreaseAllowance(
          accountDetails,
          connectedAccount.gold,
          contract
        );

      if (isSuccess) {
        // Pass transaction to be watched
        refreshWalletFunc();
        completeBuyOrSellCallback(message);
      } else {
        // Show error message
        completeBuyOrSellCallback(message);
      }
    }
  };

  const handleSellSeeds = async () => {
    setIsLoadingPurchase(true);

    if (!!connectedAccount.gold && !!selectedCrop && !isLoadingPurchase) {
      const tokenTrackedAmount = BigNumber(
        poolBalances[selectedCrop.name].tokenTrackedAmount
      );
      const tokenReserveAmount = BigNumber(
        poolBalances[selectedCrop.name].tokenReserveAmount
      );
      const isMoreAllowanceNeeded =
        cropBalances.allowances[selectedCrop.name].lt(selectedCropAmount);
      const isRequestOverBalance =
        tokenTrackedAmount.lt(tokenReserveAmount) ||
        tokenTrackedAmount.minus(tokenReserveAmount).lt(selectedCropAmount);

      if (!isRequestOverBalance && !isMoreAllowanceNeeded) {
        const currentPrice: BigNumber = calculateSellPrice(
          selectedCropAmount,
          getPoolObj(poolBalances, selectedCrop).goldTrackedAmount,
          getPoolObj(poolBalances, selectedCrop).tokenTrackedAmount
        );

        const { isSuccess, transactionDetails, message } = await processSell(
          accountDetails,
          connectedAccount.gold,
          contracts,
          cropDetails[selectedCrop.name].elementId,
          BigNumber(selectedCropAmount).toString(),
          currentPrice.toString()
        );

        if (isSuccess) {
          // Pass transaction to be watched
          refreshWalletGoldState();
          handleClose();
          completeBuyOrSellCallback(message);
        } else {
          // Show error message
          completeBuyOrSellCallback(message);
        }
      }
    }
  };

  const handleAddToCart = () => {
    if (!!selectedCrop) {
      const currentCartItems = [...cartItems];

      const indexOfElement = currentCartItems.reduce(
        (previousValue, currentValue, currentIndex) =>
          currentValue.cartItem.name === selectedCrop.name
            ? currentIndex
            : previousValue,
        -1
      );

      if (indexOfElement > -1) {
        currentCartItems[indexOfElement].amount += selectedCropAmount;
      } else {
        currentCartItems.push({
          amount: selectedCropAmount,
          cartItem: selectedCrop,
        });
      }
      setCartItems(currentCartItems);
      setSelectedCrop(null);
      setSelectedCropAmount(0);
      setShopPanel(ShopPanelState.CART);
    }
  };

  const handleRemoveFromCart = (elementIndex: number) => {
    const currentCartItems = [...cartItems];

    if (elementIndex > -1) {
      currentCartItems.splice(elementIndex, 1);
    }

    if (currentCartItems.length === 0) {
      setShopPanel(ShopPanelState.TILES);
    }

    setCartItems(currentCartItems);
  };

  const handleClose = () => {
    onClose();
    setSelectedCrop(null);
    setSelectedCropAmount(0);
    setShopPanel(ShopPanelState.TILES);
    setCartItems([]);
  };

  const handleCheckoutPurchase = async () => {
    if (!!connectedAccount.gold) {
      setIsLoadingPurchase(true);

      const listOfElementIds: string[] = [];
      const listOfTokenAmounts: string[] = [];
      const listOfPriceMaxLimits: string[] = [];

      cartItems.forEach(({ amount, cartItem }: IShopModalCartItem) => {
        const currentPrice: string = calculateBuyPrice(
          amount,
          getPoolObj(poolBalances, cartItem).goldTrackedAmount,
          getPoolObj(poolBalances, cartItem).tokenTrackedAmount
        ).toString();

        listOfElementIds.push(
          BigNumber(cropDetails[cartItem.name].elementId).toString()
        );
        listOfTokenAmounts.push(BigNumber(amount).toString());
        listOfPriceMaxLimits.push(currentPrice);
      });

      const { isSuccess, transactionDetails, message } =
        await processSetPurchase(
          accountDetails,
          connectedAccount.gold,
          contracts,
          listOfElementIds,
          listOfTokenAmounts,
          listOfPriceMaxLimits
        );

      if (isSuccess) {
        // Pass transaction to be watched
        refreshWalletGoldState();
        handleClose();
        completeBuyOrSellCallback(message);
      } else {
        // Show error message
        completeBuyOrSellCallback(message);
      }
    }
  };

  let selectedCropMaxAmount = 0;

  if (!!selectedCrop) {
    if (isSelling) {
      selectedCropMaxAmount = cropBalances.balances[selectedCrop.name];
    } else {
      selectedCropMaxAmount =
        poolBalances[selectedCrop.name].tokenTrackedAmount -
        poolBalances[selectedCrop.name].tokenReserveAmount;
    }
  }

  let shellStatus = "Shop-base";

  if (isSelling) {
    shellStatus = "Shop-selling";
  } else if (cartItems.length > 0) {
    shellStatus = "Shop-has_cart";
  }

  if (shopPanel === ShopPanelState.AMOUNT) {
    shellStatus = `${shellStatus} Shop-add_amount`;
  } else if (shopPanel === ShopPanelState.CART) {
    shellStatus = `${shellStatus} Shop-view_cart`;
  }

  return (
    <Dialog
      onClose={!isLoadingPurchase ? handleClose : () => {}}
      open={open}
      fullWidth={true}
      className={`TileModal ${shellStatus}`}
    >
      <Loader isLoading={isLoadingPurchase} />

      <img className="Modal-shell" alt="Modal shell" />

      <div
        className={`Shell-content_container${
          cartItems.length > 0 ? " Shell-cart_active" : ""
        }`}
      >
        <CropModalTileSection
          cropBalances={cropBalances}
          cropDetails={cropDetails}
          goldBalance={goldBalance}
          isLoading={isLoading}
          isLoadingAfterAction={isLoadingPurchase}
          isSelling={isSelling}
          isStorageModal={false}
          panelTabs={panelTabs}
          panelTitle="Available Seeds"
          poolBalances={poolBalances}
          handleSelectCrop={handleSelectCrop}
        />

        <ShopModalSelectedCropSection
          poolBalances={poolBalances}
          cropWalletAllowances={!!cropBalances ? cropBalances.allowances : null}
          isLoading={isLoading}
          isLoadingAfterAction={isLoadingPurchase}
          isSelling={isSelling}
          selectedCrop={selectedCrop}
          selectedCropAmount={selectedCropAmount}
          selectedCropMaxAmount={selectedCropMaxAmount}
          handleAddToCart={handleAddToCart}
          handleIncreaseCropAllowance={
            !!selectedCrop
              ? () =>
                  handleIncreaseAllowance(
                    contracts.crops[selectedCrop.name],
                    refreshWalletCropBalances
                  )
              : () => {}
          }
          handleSellSeeds={handleSellSeeds}
          setSelectedCropAmount={setSelectedCropAmount}
        />

        {!isSelling && cartItems.length > 0 && (
          <ShopModalCheckoutSection
            cartItems={cartItems}
            poolBalances={poolBalances}
            isLoading={isLoading}
            isLoadingAfterAction={isLoadingPurchase}
            goldAllowance={goldAllowance}
            goldBalance={goldBalance}
            handleCheckoutPurchase={handleCheckoutPurchase}
            handleIncreaseGoldAllowance={() =>
              handleIncreaseAllowance(
                contracts.core.gold,
                refreshWalletGoldState
              )
            }
            handleRemoveFromCart={handleRemoveFromCart}
          />
        )}
      </div>

      <div
        className={`Shell-close_button${
          cartItems.length > 0 ? " Shell-close_button_cart" : ""
        }`}
      >
        <CloseButton
          disabled={isLoadingPurchase}
          onClickHandler={!isLoadingPurchase ? handleClose : () => {}}
        />
      </div>

      {(shopPanel === ShopPanelState.AMOUNT ||
        shopPanel === ShopPanelState.CART) && (
        <div className="Shell-back_button">
          <BackButton
            disabled={isLoadingPurchase}
            onClickHandler={
              !isLoadingPurchase
                ? () => {
                    setShopPanel(ShopPanelState.TILES);
                  }
                : () => {}
            }
          />
        </div>
      )}
    </Dialog>
  );
}

export default ShopModal;
