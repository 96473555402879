import { PlotCropAction } from "../../interfaces/plot";
import { getDimensions } from "../../utils/constants";
import { PlantGrowthStage } from "../../utils/PlantGrowthStage";
import { IPlantType } from "../../utils/PlantPlot.interfaces";

import { SoilTile, ConstructedTile } from "./BaseTile";
import SeedTile from "./SeedTile";
import SproutTile from "./SproutTile";

interface IPlotTileProp {
  isLifted: boolean;
  loadingPlotAction: PlotCropAction | null;
  plantStage: PlantGrowthStage | null;
  plantType: IPlantType | null;
  height: number;
  width: number;
}

function PlotTile({
  isLifted,
  loadingPlotAction,
  plantType,
  plantStage,
  height = getDimensions().plotSizeHeight,
  width = getDimensions().plotSizeWidth,
}: IPlotTileProp) {
  if (!plantType || !plantStage) {
    return <SoilTile isLifted={isLifted} height={height} width={width} />;
  }

  if (plantStage === PlantGrowthStage.seed) {
    return (
      <SeedTile
        isLifted={isLifted}
        isLoading={loadingPlotAction === PlotCropAction.plant}
        seedColor={plantType.growthStages[PlantGrowthStage.seed]}
        height={height}
        width={width}
      />
    );
  } else if (plantStage === PlantGrowthStage.sprout) {
    return (
      <SproutTile
        isLifted={isLifted}
        sproutType={plantType.growthStages[PlantGrowthStage.sprout]}
        height={height}
        width={width}
      />
    );
  }
  return (
    <ConstructedTile
      imageSource={plantType.growthStages[plantStage]}
      isLifted={isLifted}
      height={height}
      width={width}
    />
  );
}

export default PlotTile;
