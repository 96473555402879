import { useEffect, useState } from "react";

import { IAccountRegistrationDetails } from "./accountRegistration";
import { fetchWalletCropBalances, useConnectedAccount } from "./blockchain";

export interface ICropDetailSet {
  plantName: string;
  elementId: string;
  growthTable: number[];
}

/**
 * A hook that pulls in the balance of crops in a specific wallet
 */
export const useWalletCropBalances = (
  accountDetails: IAccountRegistrationDetails
) => {
  const { connectedAccount } = useConnectedAccount();
  const [currentOnchainId, setCurrentOnchainId] = useState<string | null>(null);
  const [isLoadingWalletCropBalances, setIsLoadingWalletCropBalances] =
    useState(true);
  const [walletCropBalances, setWalletCropBalances] = useState<any>(null);
  const { onchainId } = connectedAccount;

  const refreshWalletCropBalances = async (isForce = true) => {
    setIsLoadingWalletCropBalances(true);
    if (!!onchainId && (isForce || onchainId !== currentOnchainId)) {
      const cropDetails = await fetchWalletCropBalances(accountDetails);

      setCurrentOnchainId(onchainId);
      setWalletCropBalances(cropDetails);
    } else {
      setCurrentOnchainId(null);
      setWalletCropBalances(null);
    }

    setIsLoadingWalletCropBalances(false);
  };

  useEffect(() => {
    refreshWalletCropBalances(false);
  }, [onchainId]);

  return {
    isLoadingWalletCropBalances,
    walletCropBalances,
    refreshWalletCropBalances,
  };
};
