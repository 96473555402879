import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";

import Dialog from "@mui/material/Dialog";

import "../Modal.style.css";
import "./PacksModal.style.css";

import {
  IBlockchainMessageResponse,
  useConnectedAccount,
} from "../../../interfaces/blockchain";
import { IAccountRegistrationDetails } from "../../../interfaces/accountRegistration";
import { activateFarmerStarterPacks } from "../../../interfaces/packs";
import { IPackDetails } from "../../../utils/PlantPack.interfaces";

import CloseButton from "../../CloseButton";
import Loader from "../../Loader";
import { useToolType } from "../../tooltip/TooltipAlert";

import PacksModalTileSection from "./PacksModalTileSection";
import PacksModalCheckoutSection, {
  IPacksModalCropCartItem,
  IPacksModalPlotCartItem,
} from "./PacksModalCheckoutSection";

export enum PacksPanelState {
  TILES = "tiles",
  CART = "cart",
}

export interface IPacksModalProp {
  accountDetails: IAccountRegistrationDetails | null;
  open: boolean;
  isLoading: boolean;
  packsListing: IPackDetails[];
  onClose: () => void;
  refreshUserOwnedPlots: () => void;
  refreshWalletGoldState: () => void;
}

function PacksModal({
  accountDetails,
  open,
  isLoading,
  packsListing,
  onClose,
  refreshUserOwnedPlots,
  refreshWalletGoldState,
}: IPacksModalProp) {
  const [currentBaseAddress, setCurrentBaseAddress] = useState<string | null>(
    null
  );
  const [selectedPacks, setSelectedPacks] = useState<
    Record<string, IPackDetails>
  >({});
  const { connectedAccount } = useConnectedAccount();
  const [isLoadingPurchase, setIsLoadingPurchase] = useState(false);

  const { invokeCxyzAlert, invokeMetaMaskAlert } = useToolType();

  const { base } = connectedAccount;
  let baseAddress = base?.address || null;

  useEffect(() => {
    if (baseAddress !== currentBaseAddress) {
      setCurrentBaseAddress(baseAddress);
      setSelectedPacks({});
    }
  }, [baseAddress]);

  const handleSelectAll = () => {
    const currentSelectedPacks: Record<string, IPackDetails> = {};

    if (Object.keys(selectedPacks).length !== packsListing.length) {
      packsListing.forEach((pack) => {
        currentSelectedPacks[pack.packId] = pack;
      });
    }

    setSelectedPacks(currentSelectedPacks);
  };

  const handleSelectPack = (currentSelection: IPackDetails) => {
    if (!!currentSelection) {
      const currentSelectedPacks = { ...selectedPacks };
      if (currentSelection.packId in selectedPacks) {
        delete currentSelectedPacks[currentSelection.packId];
      } else {
        currentSelectedPacks[currentSelection.packId] = currentSelection;
      }
      setSelectedPacks(currentSelectedPacks);
    }
  };

  const completeActivateCallback = (message: IBlockchainMessageResponse) => {
    if (message.isMetaMask) {
      invokeMetaMaskAlert(message.text);
    } else {
      invokeCxyzAlert(message.text);
    }
    setIsLoadingPurchase(false);
  };

  const handleClose = () => {
    onClose();
    setSelectedPacks({});
  };

  const handleActivateClick = async () => {
    if (!!connectedAccount.base && !!connectedAccount.gold) {
      setIsLoadingPurchase(true);

      const { isSuccess, transactionDetails, message } =
        await activateFarmerStarterPacks(
          connectedAccount.base,
          connectedAccount.gold,
          Object.keys(selectedPacks).length === packsListing.length,
          Object.keys(selectedPacks)
        );

      if (isSuccess) {
        // Pass transaction to be watched
        refreshUserOwnedPlots();
        refreshWalletGoldState();
        handleClose();
        completeActivateCallback(message);
      } else {
        // Show error message
        completeActivateCallback(message);
      }
    }
  };

  let shellStatus = "Packs-base";

  const selectedPacksListing = Object.keys(selectedPacks);

  if (selectedPacksListing.length > 0) {
    shellStatus = "Packs-has_cart Packs-view_cart";
  }

  let totalGold = BigNumber(0);

  const cartPlotSet: Record<string, IPacksModalPlotCartItem> = {};

  const cartSeedSet: Record<string, IPacksModalCropCartItem> = {};

  selectedPacksListing.forEach((packId) => {
    const currentSelectedPack = selectedPacks[packId];

    totalGold = totalGold.plus(currentSelectedPack.goldCount);

    if (currentSelectedPack.plotDetails.plotSizeType in cartPlotSet) {
      cartPlotSet[currentSelectedPack.plotDetails.plotSizeType].amount +=
        currentSelectedPack.plotDetails.plotCount;
    } else {
      cartPlotSet[currentSelectedPack.plotDetails.plotSizeType] = {
        amount: currentSelectedPack.plotDetails.plotCount,
        cartItem: currentSelectedPack.plotDetails.plotSizeType,
      };
    }

    if (currentSelectedPack.plantDetails) {
      if (currentSelectedPack.plantDetails.plantType.name in cartSeedSet) {
        cartSeedSet[currentSelectedPack.plantDetails.plantType.name].amount +=
          currentSelectedPack.plantDetails.plantCount;
      } else {
        cartSeedSet[currentSelectedPack.plantDetails.plantType.name] = {
          amount: currentSelectedPack.plantDetails.plantCount,
          cartItem: currentSelectedPack.plantDetails.plantType,
        };
      }
    }
  });

  const cartPlotItems: IPacksModalPlotCartItem[] = Object.values(cartPlotSet);
  cartPlotItems.sort((a, b) => a.cartItem.localeCompare(b.cartItem));

  const cartSeedItems: IPacksModalCropCartItem[] = Object.values(cartSeedSet);
  cartSeedItems.sort((a, b) => a.cartItem.name.localeCompare(b.cartItem.name));

  return (
    <Dialog
      onClose={!isLoadingPurchase ? handleClose : () => {}}
      open={open}
      fullWidth={true}
      className={`TileModal ${shellStatus}`}
    >
      <Loader isLoading={isLoadingPurchase || isLoading} />

      <img className="Modal-shell" alt="Modal shell" />

      <div
        className={`PackShell-content_container${
          selectedPacksListing.length > 0 ? " PackShell-cart_active" : ""
        }`}
      >
        <PacksModalTileSection
          accountDetails={accountDetails}
          isLoading={isLoading}
          isLoadingAfterAction={isLoadingPurchase}
          packsListing={packsListing}
          selectedPacks={selectedPacks}
          handleSelectAll={handleSelectAll}
          handleSelectPack={handleSelectPack}
        />

        {selectedPacksListing.length > 0 && (
          <PacksModalCheckoutSection
            cartSeedItems={cartSeedItems}
            cartPlotItems={cartPlotItems}
            isLoading={isLoading}
            isLoadingAfterAction={isLoadingPurchase}
            totalGold={totalGold}
            handleActivateClick={handleActivateClick}
          />
        )}
      </div>

      <div
        className={`PackShell-close_button${
          selectedPacksListing.length > 0 ? " PackShell-close_button_cart" : ""
        }`}
      >
        <CloseButton
          disabled={isLoadingPurchase}
          onClickHandler={!isLoadingPurchase ? handleClose : () => {}}
        />
      </div>

      {/* {selectedPacksListing.length > 0 && (
        <div className="PackShell-back_button">
          <BackButton
            disabled={isLoadingPurchase}
            onClickHandler={
              !isLoadingPurchase
                ? () => {
                    setPacksPanel(PacksPanelState.TILES);
                  }
                : () => {}
            }
          />
        </div>
      )} */}
    </Dialog>
  );
}

export default PacksModal;
