import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { PrivyProvider } from "@privy-io/react-auth";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { defineChain } from "viem";

import {
  AIRSTACK_PROJECT_ID,
  BASE_RPC_URL,
  GOLD_RPC_URL,
  PRIVY_API_KEY,
  SUBGRAPH_URL,
  WALLET_CONNECT_PROJECT_ID,
} from "./env";
import AppAccountWrapper from "./AppAccountWrapper";

const base = {
  chainId: 8453,
  name: "Base",
  currency: "ETH",
  explorerUrl: "https://basescan.org",
  rpcUrl: BASE_RPC_URL,
};

const metadata = {
  name: "Skyoneer",
  description:
    "Join fellow farm owners as you grow, harvest, and trade. Skyoneer is one of the first NFT games that allows you to generate and reap benefits from your owned in-game assets with your friends.",
  url: "https://play.skyoneer.com",
  icons: [
    "https://uploads-ssl.webflow.com/664de3a520bab3e520c7afc2/66840baa9974b756de1dce83_skyoneer-mini-logo.png",
  ],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [base],
  defaultChain: base,
  projectId: WALLET_CONNECT_PROJECT_ID,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function AppGraphQLWrapper() {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: SUBGRAPH_URL,
  });

  const goldChain = defineChain({
    id: 4653,
    name: "Gold",
    network: "gold",
    nativeCurrency: {
      decimals: 18,
      name: "Ether",
      symbol: "ETH",
    },
    rpcUrls: {
      default: {
        http: [GOLD_RPC_URL],
        // webSocket: ['wss://my-custom-chain-websocket-rpc'],
      },
    },
    blockExplorers: {
      default: { name: "Goldscan", url: "https://explorer.gold.dev" },
    },
  });

  return (
    <PrivyProvider
      appId={PRIVY_API_KEY}
      config={{
        // Customize Privy's appearance in your app
        appearance: {
          // theme: "#ffa216",
          theme: "light",
          accentColor: "#00DA83",
          // accentColor: "#a3ea22",
          logo: "https://uploads-ssl.webflow.com/664de3a520bab3e520c7afc2/66840baa9974b756de1dce83_skyoneer-mini-logo.png",
        },
        // Create embedded wallets for users who don't have a wallet
        embeddedWallets: {
          createOnLogin: "all-users",
          // createOnLogin: 'users-without-wallets',
          noPromptOnSignature: true,
        },
        defaultChain: goldChain,
        supportedChains: [goldChain],
      }}
    >
      <ApolloProvider client={client}>
        <AppAccountWrapper />
      </ApolloProvider>
    </PrivyProvider>
  );
}

export default AppGraphQLWrapper;
