import iconWildRice from "../../../assets/crops/grain_field/wild_rice.png";

import tileWildRiceEarly from "../../../assets/tiles/plants/grain_field/tile-wild_rice-1.png";
import tileWildRiceFull from "../../../assets/tiles/plants/grain_field/tile-wild_rice-2.png";
import tileWildRiceDead from "../../../assets/tiles/plants/grain_field/tile-wild_rice-3.png";

import { PlantGrowthStage } from "../../PlantGrowthStage";
import { PlantPlotType, IPlantType } from "../../PlantPlot.interfaces";
import { SeedColor } from "../../Seed";
import { SproutType } from "../../Sprout";

import {
  GrainFieldPlantNameType,
  GrainFieldPlantDisplayNameType,
  GrainFieldPlantBlockchainNameType,
} from "./GrainField.naming";

const PlantWildRice: IPlantType = {
  name: GrainFieldPlantNameType.wildRice,
  blockchainName: GrainFieldPlantBlockchainNameType.wildRice,
  displayName: GrainFieldPlantDisplayNameType.wildRice,
  plotType: PlantPlotType.grainField,
  icon: iconWildRice,
  growthStages: {
    [PlantGrowthStage.seed]: SeedColor.black,
    [PlantGrowthStage.sprout]: SproutType.grain,
    [PlantGrowthStage.early]: tileWildRiceEarly,
    [PlantGrowthStage.full]: tileWildRiceFull,
    [PlantGrowthStage.dead]: tileWildRiceDead,
  },
};

export default PlantWildRice;
